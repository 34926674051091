import React, { useState } from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import api from "../../helper/api";
import { getServerURL } from "../../helper/envConfig";
import { toast } from "react-toastify";

function ReportAnIssue({ trailStep, questionId, courseId= null }) {
    const serverURL = getServerURL();
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);

    const [comment, setComment] = useState("");
    const [selectedIssue, setSelectedIssue] = useState("");
    const issueTypes = [
        { key: "Wrong", value: "wrong" },
        { key: "Confusing", value: "confusing" },
        { key: "Inadequate", value: "inadequate" },
        { key: "Other", value: "other" },
    ];

    const handleClose = () => {
        setComment("");
        setSelectedIssue("");
        setShow(false);
    };

    const handleIssueChange = (event) => {
        setSelectedIssue(event.target.value);
    };

    const submitReportIssue = async () => {
        try {
            if (!selectedIssue) {
                toast.error("Please select issue type");
                return;
            }
            const res = await api.postWithToken(
                `${serverURL}trails/report-issue/`,
                {
                    issue_type: selectedIssue,
                    trail_step: trailStep,
                    comment: comment.trim(),
                    question_id: questionId,
                    course_id: courseId,
                }
            );
            toast.success(res.data.message);
            setComment("");
            setSelectedIssue("");
            handleClose();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id="tooltip-top">
                        Here you can flag if you feel the answers or the
                        question is wrong or inadequate or confusing
                    </Tooltip>
                }
            >
                <img
                    onClick={handleShow}
                    src="/new-business/flag_img.webp"
                    alt=""
                     className="cursor-pointer"
                />
            </OverlayTrigger>

            <Modal
                centered
                size="md"
                className="feedback_modal report_issue_modal"
                show={show}
                onHide={handleClose}
            >
                <Modal.Header
                    className="border-0 pb-0 adjust-modal"
                    closeButton
                />
                <Modal.Body className="pt-0">
                    <div className="feedback-step-1">
                        <h3>Report an Issue</h3>

                        <div className="report-radio-group d-block my-3 my-md-3">
                            {issueTypes.map((issue, index) => (
                                <div key={index}>
                                    <label key={issue.key}>
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            value={issue.value}
                                            checked={
                                                selectedIssue === issue.value
                                            }
                                            onChange={handleIssueChange}
                                        />
                                        {issue.value}
                                    </label>
                                </div>
                            ))}
                        </div>

                        <h6>Additional Comments (Optional)</h6>
                        <textarea
                            className="form-control pt-3 bg-secondary fw-400"
                            rows={6}
                            placeholder="Type your feedback here..."
                            id="floatingTextarea2"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button onClick={submitReportIssue} variant="primary">
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ReportAnIssue;
