import { useState, useRef, useEffect } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import {
    Card,
    Col,
    Overlay,
    OverlayTrigger,
    Popover,
    Row,
    Tooltip,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { decryptFromStoreLoc } from "../../helper/localStore";
import { toast } from "react-toastify";
import api from "../../helper/api";
import { getServerURL } from "../../helper/envConfig";

function BusinessUserPlans() {
    const navigate = useNavigate();
    const serverURL = getServerURL()
    const [user, setUser] = useState({});
    const [employeeCount, setEmployeeCount] = useState(0);
    const [subScriptionAmount, setSubscriptionAmount] = useState(0);
    const [perEmployeePrice, setPerEmployeePrice] = useState(0);

    const trailsData = [
        {
            id: 1,
            title: "Data Privacy",
            description: "How to keep personal data safe",
            imageSrc: "/png/trails-1.png",
        },
        {
            id: 2,
            title: "Information Security",
            description: "How to keep company information safe",
            imageSrc: "/png/trails-2.png",
        },
        {
            id: 3,
            title: "Compliance",
            description: "How to stay compliant as a company",
            imageSrc: "/png/trails-3.png",
        },
        {
            id: 4,
            title: "Sustainability",
            description: "How to stay sustainable as a company",
            imageSrc: "/png/trails-4.png",
        },
        {
            id: 5,
            title: "What should we do",
            description: "What should we do",
            imageSrc: "/png/trails-5.png",
        },
        {
            id: 6,
            title: "Mental health",
            description: "How to stay sane",
            imageSrc: "/png/trails-6.png",
        },
        {
            id: 7,
            title: "Diversity",
            description: "How to have a diverse work force",
            imageSrc: "/png/trails-7.png",
        },
        {
            id: 8,
            title: "Communication",
            description: "How to communicate well",
            imageSrc: "/png/trails-8.png",
        },
        {
            id: 9,
            title: "Happiness",
            description: "How to be happy",
            imageSrc: "/png/trails-9.png",
        },
        {
            id: 10,
            title: "Health",
            description: "How to live a healthy life",
            imageSrc: "/png/trails-10.png",
        },
        {
            id: 11,
            title: "Time Management",
            description: "How to work effectively",
            imageSrc: "/png/trails-11.png",
        },
        {
            id: 12,
            title: "Wealth",
            description: "How to be wealthy",
            imageSrc: "/png/trails-12.png",
        },
        {
            id: 13,
            title: "Career",
            description: "How to progress in your job",
            imageSrc: "/png/trails-13.png",
        },
        {
            id: 14,
            title: "Confidence",
            description: "How to be confident",
            imageSrc: "/png/trails-14.png",
        },
        {
            id: 15,
            title: "Food",
            description: "How to have healthy eating habits",
            imageSrc: "/png/trails-15.png",
        },
        {
            id: 16,
            title: "Social Media",
            description: "How to master social media safely",
            imageSrc: "/png/trails-16.png",
        },
        // Add more items as needed
    ];

    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    const handleMouseEnter = (event) => {
        setShow(true);
        setTarget(event.target);
    };

    const handleMouseLeave = () => {
        setShow(false);
        setTarget(null);
    };

    const handleChangeEmployee = (e, plan) => {
        setEmployeeCount(e.target.value);
        if (plan === "two") {
            setPerEmployeePrice(12);
            const price = e.target.value * 12;
            setSubscriptionAmount(price);
        }
        if (plan === "three") {
            const price = getPriceByEmployeeCount(e.target.value);
            setSubscriptionAmount(price);
        }
    };

    const employeesData = [
        { range: "Less than 50", price: "€72" },
        { range: "51–100", price: "€69" },
        { range: "101–500", price: "€66" },
        { range: "501–1000", price: "€63" },
        { range: "1001–2000", price: "€60" },
        { range: "2001–3000", price: "€56" },
        { range: "3001–5000", price: "€52" },
        { range: "More than 5001", price: "€48" },
    ];

    const employeesPricing = [
        { min: 0, max: 50, price: 72 },
        { min: 51, max: 100, price: 69 },
        { min: 101, max: 500, price: 66 },
        { min: 501, max: 1000, price: 63 },
        { min: 1001, max: 2000, price: 60 },
        { min: 2001, max: 3000, price: 56 },
        { min: 3001, max: 5000, price: 52 },
        { min: 5001, max: Infinity, price: 48 },
    ];

    function getPriceByEmployeeCount(employeeCount) {
        for (const data of employeesPricing) {
            // Check if the employee count falls within the min and max range
            if (employeeCount >= data.min && employeeCount <= data.max) {
                setPerEmployeePrice(data.price);
                return data.price * employeeCount; // Return price as a number
            }
        }
        return null; // Return null if no matching range is found
    }

    useEffect(() => {
        const decryptedInfoResult = decryptFromStoreLoc("user");
        setUser(decryptedInfoResult);
    }, []);


    const handleNavigate = async (data) => {
        try {
            if(data === "one") {
                const response = await api.postWithToken(`${serverURL}payments/is-free-plan-used/`, {});
                if(response.data.is_free_plan_used) {
                    toast.error(response.data.message);
                    return;
                }
            }
        } catch (error) {
            console.log(error)
        }

        if (user?.role === 12) {
            if (data === "one") {
                navigate("/course-trails", {
                    state: {
                        name: "one",
                        plan: "Tier One- One trail",
                        userPlan: {
                            employee: 0,
                            perEmployeePrice: 0,
                            subscriptionAmount: 0,
                        },
                    },
                });
            } else if (data === "two") {

                if(employeeCount == 0 || !employeeCount) {
                    toast.error("Please enter a valid number of employees.");
                    return;
                }

                navigate("/course-trails", {
                    state: {
                        name: "two",
                        plan: "Tier Two- Two Trails",
                        userPlan: {
                            employee: employeeCount,
                            perEmployeePrice: perEmployeePrice,
                            subscriptionAmount: subScriptionAmount,
                        },
                    },
                });
            } else {
                if(employeeCount == 0 || !employeeCount) {
                    toast.error("Please enter a valid number of employees.");
                    return;
                }

                navigate("/business-support-plans", {
                    state: {
                        name: "three",
                        plan: "Tier Three-Gold - All trails",
                        userPlan: {
                            employee: employeeCount,
                            perEmployeePrice: perEmployeePrice,
                            subscriptionAmount: subScriptionAmount,
                        },
                    },
                });
            }
        }
        if (user?.role === 2) {
            if (data === "one") {
                navigate("/course-trails", {
                    state: {
                        name: "one",
                        plan: "Tier One- One trail",
                        userPlan: {
                            subscriptionAmount: 0,
                        },
                    },
                });
            } else if (data === "two") {
                navigate("/course-trails", {
                    state: {
                        name: "two",
                        plan: "Tier Two- Two Trails",
                        userPlan: {
                            subscriptionAmount: 12,
                        },
                    },
                });
            } else {
                navigate("/individual-payment-details", {
                    state: {
                        name: "three",
                        plan: "Tier Three-Gold - All trails",
                        userPlan: {
                            subscriptionAmount: 120,
                        },
                    },
                });
            }
        }
    };

    return (
        <>
            <Header />
            <section className="payment-flow-section">
                <div className="container">
                    <h3 className="welcome-title max-width-100">
                        Choose Your Subscription Plan
                    </h3>
                    {user?.role === 12 && (
                        <p className="welcome-description plan-description">
                            Select a plan that fits your business needs. Start
                            with a free trial, then choose a subscription that
                            scales with your team. You will be billed yearly for
                            your selected plan.
                        </p>
                    )}
                    {user?.role === 2 && (
                        <p className="welcome-description plan-description">
                            Select a plan that fits your needs. You will be
                            billed yearly for your selected plan.
                        </p>
                    )}
                    <div className="row plans-card g-4">
                        <div className="col-md-6 col-lg-6 col-xl-4">
                            <div className="card border-0 h-100">
                                <div className="card-body p-0">
                                    <h3>Tier One- One trail</h3>
                                    <p>
                                        Single
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id="tooltip-top">
                                                    Trails are life long
                                                    educational courses with
                                                    fun. A trail is a
                                                    combination of bronze
                                                    courses and case studies for
                                                    one topic. It will give you
                                                    an overview about one topic
                                                    (e.g. data privacy) and then
                                                    you will receive further
                                                    courses on this topic for
                                                    you whole life time until
                                                    you de-register from
                                                    unicorso. It is life long
                                                    education with fun.
                                                </Tooltip>
                                            }
                                        >
                                            <span className="tooltip-hover px-1 fw-600">
                                                trail
                                            </span>
                                        </OverlayTrigger>
                                        with the basic Data Privacy and
                                        Compliance courses
                                    </p>
                                    <h2>Free</h2>
                                </div>
                                <div className="card-footer bg-transparent border-0 p-0 d-flex justify-content-center">
                                    <button
                                        className="w-auto text-decoration-none"
                                        onClick={() => handleNavigate("one")}
                                    >
                                        Get Started
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-4">
                            <div className="card border-0 h-100">
                                <div className="card-body p-0">
                                    <h3>Tier Two- Two Trails</h3>
                                    <p>
                                        Several
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id="tooltip-top">
                                                    Trails are life long
                                                    educational courses with
                                                    fun. A trail is a
                                                    combination of bronze
                                                    courses and case studies for
                                                    one topic. It will give you
                                                    an overview about one topic
                                                    (e.g. data privacy) and then
                                                    you will receive further
                                                    courses on this topic for
                                                    you whole life time until
                                                    you de-register from
                                                    unicorso. It is life long
                                                    education with fun.
                                                </Tooltip>
                                            }
                                        >
                                            <span className="tooltip-hover px-1 fw-600">
                                                trails
                                            </span>
                                        </OverlayTrigger>
                                        with basic courses on Compliance, Data
                                        Privacy and Information Security
                                    </p>
                                    {user?.role === 2 && <h2>€12</h2>}
                                    {user?.role === 12 && <h2>€12</h2>}
                                    <p className="employee mb-0 h-auto">
                                        per year
                                    </p>
                                </div>
                                <div className="card-footer bg-transparent border-0 p-0">
                                    {user?.role === 12 && (
                                        <div className="d-flex justify-content-between align-items-center mb-5 shared-courses-cards px-lg-5">
                                            <div>
                                                <h6 className="mb-0 fw-500 fs-6 text-white">
                                                    Number of Employees
                                                </h6>
                                            </div>
                                            <input
                                                type="number"
                                                className="input-box mt-3 mt-lg-0"
                                                onChange={(e) =>
                                                    handleChangeEmployee(
                                                        e,
                                                        "two"
                                                    )
                                                }
                                            />
                                        </div>
                                    )}
                                    <div className="card-footer bg-transparent border-0 p-0 d-flex justify-content-center">
                                        <button
                                            className="w-auto text-decoration-none"
                                            onClick={() =>
                                                handleNavigate("two")
                                            }
                                        >
                                            Get Started
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-4">
                            <div className="card border-0 h-100">
                                <div className="card-body p-0">
                                    <h3 className="max-width-100">
                                        Tier Three-Gold - All trails
                                    </h3>
                                    <p>
                                        All the courses with a lot more courses
                                        on Compliance, Data Privacy, Information
                                        Security and also on skills and fun
                                        topics
                                    </p>
                                    {user?.role === 2 && (
                                        <>
                                            <h2>€120</h2>
                                            <p className="employee mb-0 h-auto">
                                                per year
                                            </p>
                                        </>
                                    )}
                                    {user?.role === 12 && (
                                        <>
                                            <h2 className="fs-2">
                                                Starting at €120
                                            </h2>
                                            <p className="employee mb-0 h-auto">
                                                per employee per year
                                            </p>
                                            <p className="fs-6 fw-700 h-auto">
                                                See Full Price Table
                                                <span ref={ref}>
                                                    <span
                                                        onMouseEnter={
                                                            handleMouseEnter
                                                        }
                                                        onMouseLeave={
                                                            handleMouseLeave
                                                        }
                                                    >
                                                        <img
                                                            src="../png/price-table.png"
                                                            className="px-2"
                                                            alt=""
                                                        />
                                                    </span>

                                                    <Overlay
                                                        show={show}
                                                        target={target}
                                                        placement="top"
                                                        container={ref}
                                                        containerPadding={20}
                                                    >
                                                        <Popover id="popover-contained">
                                                            <Popover.Body className="p-4">
                                                                <table className="tooltip-table">
                                                                    <div className="row border-bottom">
                                                                        <div className="col-6 border-end">
                                                                            Number
                                                                            of
                                                                            Employees
                                                                        </div>
                                                                        <div className="col-6">
                                                                            Price
                                                                            per
                                                                            Employee/Year
                                                                        </div>
                                                                    </div>
                                                                    {employeesData.map(
                                                                        (
                                                                            employee,
                                                                            index
                                                                        ) => (
                                                                            <div
                                                                                className="row border-bottom"
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                <div className="col-6 border-end">
                                                                                    {
                                                                                        employee.range
                                                                                    }
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    {
                                                                                        employee.price
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </table>
                                                            </Popover.Body>
                                                        </Popover>
                                                    </Overlay>
                                                </span>
                                            </p>
                                        </>
                                    )}
                                </div>
                                <div className="card-footer bg-transparent border-0 p-0">
                                    {user?.role === 12 && (
                                        <div className="d-flex justify-content-between align-items-center mb-5 shared-courses-cards px-lg-5">
                                            <div>
                                                <h6 className="mb-0 fw-500 fs-6 text-white">
                                                    Number of Employees
                                                </h6>
                                            </div>
                                            <input
                                                type="number"
                                                className="input-box mt-3 mt-lg-0"
                                                onChange={(e) =>
                                                    handleChangeEmployee(
                                                        e,
                                                        "three"
                                                    )
                                                }
                                            />
                                        </div>
                                    )}
                                    <div className="d-flex justify-content-center">
                                        <button
                                            className="w-auto text-decoration-none"
                                            onClick={() =>
                                                handleNavigate("all")
                                            }
                                        >
                                            Get Started
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="available-trails pb-md-5">
                        <h3 className="welcome-title max-width-100">
                            Available trails
                        </h3>
                        <p className="welcome-description plan-description">
                            After purchasing a subscription, you will receive
                            the following trails:
                        </p>

                        <Row className="available-trails-cards g-3">
                            {trailsData.map((trail) => (
                                <Col key={trail.id} md={6} lg={6} xl={4}>
                                    <Card>
                                        <Card.Body>
                                            <div className="d-flex align-items-center gap-3">
                                                <img
                                                    width={100}
                                                    height={100}
                                                    src={trail.imageSrc}
                                                    alt={trail.title}
                                                />
                                                <div>
                                                    <h5>{trail.title}</h5>
                                                    <p>{trail.description}</p>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default BusinessUserPlans;
