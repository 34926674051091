import React, { useContext } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CourseContext } from '../../context/CourseContext';
import { useEffect } from 'react';

function PointsHeader({ to }) {
    const { pointsData } = useContext(CourseContext);
    return (
        <>
            <div className='business_data_privacy_trail'>
                <div className='points_header bg-white'>
                    <div className="container">
                        <div className='d-flex justify-content-end align-items-start align-items-md-center gap-2 gap-md-1 gap-lg-2 flex-column flex-md-row'>
                            <span>
                                <span className='badge text-warning p-0'>10 points</span>
                                {""} points will be deducted  if you do not log on once per month
                            </span>
                            <div className='d-flex justify-content-end align-items-center gap-2 gap-md-1 gap-lg-'>
                                {/* <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id="tooltip-bottom">
                                            <p className='tooltip_bottom_text'>Points increase with each learning step and decrease by 0,27% points daily if you’re not active. 7 points will be deducted  in 12 hours. <span className='fw-700'>Stay active!</span></p>
                                        </Tooltip>
                                    }
                                >
                                    <img src="/new-business/question-mark.webp" width={20} alt="" />
                                </OverlayTrigger> */}

                                <p className='mb-0 points_earned'>Points earned:</p>
                                <Link to={to} className='award_btn fw-700 border-0 d-flex align-items-center gap-2'>
                                    <img src="/new-business/award-icons.webp" width={24} alt="" />
                                    {pointsData?.total_point_earned}
                                </Link>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PointsHeader
