import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';

const CountdownTimer = forwardRef(({ handleSubmit} , ref) => {
    // Try to get the saved timeLeft from localStorage, otherwise initialize with 59 seconds
    const savedTimeLeft = localStorage.getItem('timeLeft');
    const initialTimeLeft = savedTimeLeft ? parseInt(savedTimeLeft) : 59;
    const [timeLeft, setTimeLeft] = useState(initialTimeLeft); // Initialize with saved or default value
    const [isRunning, setIsRunning] = useState(true); // Timer starts running

    // Set the radius of the circle
    const radius = 35;
    const strokeWidth = 6;
    const circumference = 2 * Math.PI * radius;

    // Calculate stroke-dasharray and stroke-dashoffset to animate the SVG circle
    const strokeDasharray = circumference;
    const strokeDashoffset = circumference - (timeLeft / 59) * circumference;

    useImperativeHandle(ref, () => ({
        setTimeLeft(newState) {
            setTimeLeft(newState);
        }
    }));

    // Determine stroke color based on time left
    const getStrokeColor = () => {
        if (timeLeft <= 15) return '#E54C29';  // Last 15 seconds
        if (timeLeft <= 30) return '#E7931E';  // Last 30 seconds
        return '#002FA7';  // Default color
    };

    // Determine text color based on time left
    const getTextColor = () => {
        if (timeLeft <= 15) return '#E54C29';  // Last 15 seconds (red)
        if (timeLeft <= 30) return '#E7931E';  // Last 30 seconds (orange)
        return '#002FA7';  // Default color (blue)
    };

    // Function to submit when the timer reaches zero
    const submit = () => {
        handleSubmit(true)
        // alert('Time is up! Submitting...');
        // Reset the timer to 60 seconds
        setTimeLeft(60);
        // Reset the timer to start running from 60 seconds again
        setIsRunning(true);
    };

    // Use effect to handle the countdown and persist timeLeft in localStorage
    useEffect(() => {
        if (!isRunning) return;

        // Start an interval to update the timer every second
        const interval = setInterval(() => {
            setTimeLeft((prev) => {
                if (prev === 0) {
                    clearInterval(interval);
                    submit(); // Call submit when the timer hits zero
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        // Save timeLeft to localStorage every second
        const saveTimeLeft = () => {
            localStorage.setItem('timeLeft', timeLeft);
        };

        // Add an event listener to save timeLeft before the page unloads (refresh or close)
        window.addEventListener('beforeunload', saveTimeLeft);

        // Clean up the interval and event listener on unmount
        return () => {
            clearInterval(interval);
            window.removeEventListener('beforeunload', saveTimeLeft);
        };
    }, [timeLeft, isRunning]); // Re-run when timeLeft changes

    return (
        <div className='countdown_timer'>
            <svg width="80" height="80" viewBox="0 0 80 80">
                <circle
                    cx="40"
                    cy="40"
                    r={radius}
                    strokeWidth={strokeWidth}
                    fill="transparent"
                />
                <circle
                    cx="40"
                    cy="40"
                    r={radius}
                    stroke={getStrokeColor()}
                    strokeWidth={strokeWidth}
                    fill="transparent"
                    strokeDasharray={strokeDasharray}
                    strokeDashoffset={strokeDashoffset}
                    transform="rotate(-90 40 40)" // Start from top
                    style={{ transition: 'stroke-dashoffset 1s linear, stroke 1s linear' }}
                />
            </svg>
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    color: getTextColor(),
                }}
            >
                {timeLeft}s
            </div>
        </div>
    );
});

export default CountdownTimer;
