import React, { useContext, useEffect, useState } from 'react'
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import "../../styles/newbusiness.css"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ProgressBar } from 'react-bootstrap';
import PointsHeader from '../../components/NewBusiness/PointsHeader';
import { getServerURL } from '../../helper/envConfig';
import api from '../../helper/api';
import { CourseContext } from '../../context/CourseContext';


const BusinessPublicSpaces = () => {
    const location =  useLocation()
    const { user } = useContext(CourseContext);
    const navigate = useNavigate()
    const serverURL = getServerURL()
    const [caseStudyData, setCaseStudyData ] = useState()
    const [selectedCaseStudy, setSelectedCaseStudy] = useState({})
    const [currentStudyIndex, setCurrentStudyIndex] = useState(0)

    const getCaseStudyData = async () => {
        try {
            const type = ( user.main_role === 1 || user.role === 2) ? "business" : "kid";
            const response = await api.getWithToken(`${serverURL}trails/case-studies/?type=${type}`);
            setCaseStudyData(response.data.data)
            if (location?.state?.case_study_id) {
                const index = response.data.data.findIndex((caseStudy) => caseStudy.case_study_id === location.state.case_study_id)
                setCurrentStudyIndex(index + 1)
                setSelectedCaseStudy(response.data.data.find((caseStudy) => caseStudy.case_study_id === location.state.case_study_id))
            }  else {
                setSelectedCaseStudy(response.data.data[0])
                setCurrentStudyIndex(1)
            }
        } catch (error) {
             console.log(error)
        }
    }

     useEffect(() => {
        getCaseStudyData()
     },[])



       const handleContinue = () => {
        navigate(`/data-privacy/case-study-question`, {
            state: {
                caseStudy: selectedCaseStudy,
                caseStudyList: caseStudyData
            }
        })

       }

    return (
        <>
            <Header />
            <main className='business_public_spaces'>
                <section>
                    <PointsHeader to="/data-privacy/points" />
                </section>
                <section className='business_case_study'>
                    <div className="container-fluid">
                        <div className="row w-100 w-sm-100">
                            <div className="col-12 col-md-6 left_side_images">
                                <img src="/new-business/case-stud.webp" className='m-auto d-flex' width={400} alt="" />
                            </div>
                            <div className="col-12 col-md-6 d-flex justify-content-start align-items-center">
                                <div className='right_side_information'>
                                    <span>Case Study</span>
                                    <h4>{selectedCaseStudy?.heading}</h4>
                                    <h5>{selectedCaseStudy?.title} </h5>
                                    <p>{selectedCaseStudy?.description}</p>
                                    <div className='d-flex align-items-center gap-3'>
                                        <button className='d-none'>Back</button>
                                        <button className='d-flex justify-content-between align-items-center gap-3 w-auto m-auto' onClick={handleContinue}>
                                            Continue
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={22}
                                                height={14}
                                                viewBox="0 0 22 14"
                                                fill="none"
                                            >
                                                <path
                                                    d="M1 6.99854H21L16 1"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M21 6.99805L16 12.9995"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                />
                                            </svg></button>
                                    </div>
                                    <div className='progressbar_custom'>
                                        <h6>{currentStudyIndex} of {caseStudyData?.length}</h6>
                                        <ProgressBar now={(currentStudyIndex / caseStudyData?.length) * 100} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}

export default BusinessPublicSpaces
