import React, { useContext, useEffect, useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import "../../styles/newbusiness.css";
import { Link, useNavigate } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";
import PointsHeader from "../../components/NewBusiness/PointsHeader";
import { getServerURL } from "../../helper/envConfig";
import api from "../../helper/api";
import { CourseContext } from "../../context/CourseContext";

const BusinessDataPrivacyTrail = () => {
    const serverURL = getServerURL();
    const navigate = useNavigate()
    const { user, getTrailsTotalPoint } = useContext(CourseContext);
    // const caseStudies = [
    //     {
    //         title: 'Case Study',
    //         description: 'Explore real-world privacy scenarios with guidance from our virtual experts, Ada and Ommo. Choose the best answers to test your understanding.',
    //         awardCount: 300,
    //         imageSrc: '/new-business/case-study.webp',
    //     },
    //     {
    //         title: 'Course',
    //         description: 'Finish up with 3 engaging courses in data privacy to round out your training.',
    //         awardCount: 1000,
    //         imageSrc: '/new-business/course.webp',
    //     },
    //     {
    //         title: 'Puzzle',
    //         description: 'Complete the puzzle to reinforce your understanding of privacy fundamentals.',
    //         imageSrc: '/new-business/puzzle.webp',
    //     },
    //     {
    //         title: 'Gamified Quiz',
    //         description: 'Ready for a challenge? Test your knowledge in a quiz game! Answer 7 out of 10 questions correctly to progress and earn points.',
    //         awardCount: 1023,
    //         imageSrc: '/new-business/gamified.webp',
    //     },
    // ];
    const [caseStudies, setCaseStudies] = useState([]);
    const [progressData, setProgressData] = useState({});

    const getSteps = async () => {
        try {
            const response = await api.getWithToken(
                `${serverURL}trails/steps/`
            );
            setCaseStudies(response.data.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getSteps();
        getTrailsTotalPoint()
    }, []);

    const getProgressStatus = async () => {
        try {
            const response = await api.getWithToken(
                `${serverURL}trails/step-status/`
            );
            setProgressData(response.data.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getProgressStatus();
    }, []);

    const handleNavigate = () => {
        if(progressData.in_progress_step === 0) {
            navigate("/data-privacy/trail-results")
        }
        if(progressData.in_progress_step === 1) {
            navigate("/data-privacy/case-study")
        }
        if( progressData.in_progress_step === 2) {
            navigate("/data-privacy/course")
        }
        if( progressData.in_progress_step === 3) {
            navigate("/data-privacy/puzzle")
        }
        if( progressData.in_progress_step === 4) {
            navigate("/data-privacy/gamified-quiz")
        }
    }

    return (
        <>
            <Header />
            <main className="business_data_privacy_trail">
                <section>
                    <PointsHeader to="/data-privacy/points" />
                    <div className="trail_progress">
                        <div className="container">
                            <div className="row align-items-center g-4">
                                <div className="col-12 col-md-7 order-2 order-md-1">
                                    <span className="badge text-capitalize">
                                        Hi {user?.username},
                                    </span>
                                    {(user.main_role === 1 || user.role === 2) ?
                                        <>
                                            <p>
                                                Welcome to the Data Privacy Trail. Get
                                                started with case studies and hands-on
                                                activities.
                                            </p>
                                            <h3>
                                                Data Privacy Trail - Interactive
                                                Learning
                                            </h3>
                                        </> :
                                        <>
                                            <p>
                                                Welcome! Let’s Start Learning about Online Safety
                                            </p>
                                            <h3>
                                                What Kids Should Know Before Using Smartphones and Social Media
                                            </h3>
                                        </>
                                    }
                                    <button
                                        className="btn btn-primary d-inline-flex align-items-center gap-3 link_button"
                                        onClick={handleNavigate}
                                    >
                                        Begin Trail
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={22}
                                            height={14}
                                            viewBox="0 0 22 14"
                                            fill="none"
                                        >
                                            <path
                                                d="M1 6.99854H21L16 1"
                                                stroke="#fff"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M21 6.99854L16 13"
                                                stroke="#fff"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                            />
                                        </svg>
                                    </button>
                                    <h4>Trail Progress</h4>
                                    <h5>
                                        {progressData?.total_complete_step}/
                                        {progressData?.total_step} Completed
                                    </h5>
                                    <ProgressBar
                                        className="me-md-4 me-lg-5"
                                        now={
                                            (progressData?.total_complete_step /
                                                progressData?.total_step) *
                                            100
                                        }
                                    />
                                </div>
                                <div className="col-12 col-md-5 d-flex justify-content-end order-1 order-md-2">
                                    <img
                                        src="/new-business/discussion-cuate.webp"
                                        className="pt-md-5"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="data_privacy_overview py-5">
                    <div className="container">
                        <div className="d-md-flex justify-content-between align-items-center pb-4 pb-lg-5">
                            <h2>Data Privacy Trail Overview </h2>
                            <button
                                onClick={handleNavigate}
                                className="btn-primary d-inline-flex align-items-center gap-3 mt-3 mt-md-0 rounded-3"
                            >
                                <span className="text-white">Start</span>
                                <svg
                                    className="ms-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={22}
                                    height={14}
                                    viewBox="0 0 22 14"
                                    fill="none"
                                >
                                    <path
                                        d="M1 6.99854H21L16 1"
                                        stroke="#fff"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M21 6.99854L16 13"
                                        stroke="#fff"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="row g-4">
                            {caseStudies?.map((caseStudy, index) => (
                                <div className="col-12" key={index}>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex align-items-start align-content-md-center gap-4 flex-column flex-md-row">
                                                <img
                                                    src={caseStudy.icon}
                                                    alt=""
                                                />
                                                <div>
                                                    <h4>{caseStudy.name}</h4>
                                                    <p className="mb-2">
                                                        {caseStudy.description}
                                                    </p>
                                                    <div className="d-flex align-items-center gap-3">
                                                        <p className="mb-0 text_light_200 fs-14 fw-600">
                                                            You will earn:
                                                        </p>
                                                        <div className="business_data_privacy_trail">
                                                            {caseStudy.title !==
                                                                "Puzzle" && (
                                                                <button className="award_btn fw-700 border-0 d-flex align-items-center gap-2">
                                                                    <img
                                                                        src="/new-business/award-icons.webp"
                                                                        width={
                                                                            24
                                                                        }
                                                                        alt=""
                                                                    />
                                                                    {/* {caseStudy.awardCount} */}
                                                                    {
                                                                        caseStudy?.points
                                                                    }
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
};

export default BusinessDataPrivacyTrail;
