import React, { useRef, useState, useEffect, useContext } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import { Link } from "react-router-dom";
import { decryptFromStore, encryptAndStore } from "../../helper/sessionStore";
import api from "../../helper/api";
import { getServerURL } from "../../helper/envConfig";
import { useNavigate } from "react-router-dom"; // Import useHistory for redirection
import { CourseContext } from "../../context/CourseContext";
import { QuizCardSkeleton } from "../../components/SkeletonLayouts";
import CryptoJS from "crypto-js";
import { toast } from "react-toastify";
import ReportAnIssue from "../../components/NewBusiness/ReportAnIssue";
import PointsHeader from "../../components/NewBusiness/PointsHeader";

const BusinessCourseQuiz = () => {
    const key = process.env.REACT_APP_CRYPTO_PY_KEY;
    const { loder, setLoder, getTrailsTotalPoint } = useContext(CourseContext);
    const [cardStatus, setCardStatus] = useState(false);
    const [activeStartIndex, setActiveStartIndex] = useState(1);
    const [ansCount, setAnsCount] = useState([]);
    const storedAnswers = decryptFromStore("answers") || [];
    const [isFetchingQuestion, setIsFetchingQuestion] = useState(false);
    const navigate = useNavigate();
    const history = useNavigate(); // Get the history object for redirection
    const serverURL = getServerURL();
    const [Quize, setQuize] = useState({});
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [QuestionNo, setQuestionNo] = useState(1); // Store the remaining time in seconds
    const [NewQuestion, setNewQuestion] = useState(1); // Store the remaining time in seconds
    const [aiAnswer, setAiAnswer] = useState("");
    const [showAnswerPage, setShowAnswerPage] = useState(false);
    const [horseColor, setHorseColor] = useState();
    const [showHorseColor, setShowHorseColor] = useState("");

    const TIME_LIMIT = 360; // 6 minutes in seconds
    const [timeLeft, setTimeLeft] = useState(TIME_LIMIT);
    const intervalRef = useRef(null);

    const [isTrue, setIsTrue] = useState(() => {
        const submitStatus = decryptFromStore("isTrue"); // Assuming 'isSubmit' is the key used to store the submission status
        return submitStatus ? submitStatus : false;
    });

    const [true_id, setTrue_id] = useState(() => {
        const submitStatus = decryptFromStore("true_id"); // Assuming 'isSubmit' is the key used to store the submission status
        return submitStatus ? submitStatus : null;
    });

    const [isSubmit, setIsSubmit] = useState(() => {
        const submitStatus = decryptFromStore("isSubmit"); // Assuming 'isSubmit' is the key used to store the submission status
        return submitStatus ? submitStatus : false;
    });

    useEffect(() => {
        encryptAndStore("isSubmit", isSubmit);
    }, [isSubmit]);

    useEffect(() => {
        // Store isTrue whenever it changes
        encryptAndStore("isTrue", isTrue);
    }, [isTrue]);

    useEffect(() => {
        // Store true_id whenever it changes
        encryptAndStore("true_id", true_id);
    }, [true_id]);

    // useEffect(() => {
    //     // Decrypt and retrieve isTrue from storage
    //     sessionStorage.removeItem("chatGptResult");
    //     const storedIsTrue = decryptFromStore("isTrue");
    //     if (storedIsTrue !== undefined) {
    //         setIsTrue(storedIsTrue === "true"); // Assuming the storage might save it as a string
    //     }

    //     // Decrypt and retrieve true_id from storage
    //     const storedTrueId = decryptFromStore("true_id");
    //     if (storedTrueId !== undefined) {
    //         setTrue_id(storedTrueId);
    //     }
    // }, []); // Empty dependency array to run only on mount

    useEffect(() => {
        const startAnimation = () => {
            const topCard = document.querySelector(".card-1");
            if (topCard) {
                // Check if topCard is not null
                topCard.addEventListener(
                    "animationend",
                    reorderCardsWhenAnimationFinished
                );
            }
        };

        const reorderCardsWhenAnimationFinished = (e) => {
            if (e.animationName === "move-left") {
                const allCards = document.querySelectorAll(".cardslide");
                moveFirstCardToBack(allCards);
            }
        };

        const finishAnimation = () => {
            // No need to remove the event listener from document in this context
            const allCards = document.querySelectorAll(".cardslide");
            Array.from(allCards).forEach((card) =>
                card.classList.remove("shuffle")
            );
        };

        const moveFirstCardToBack = (cards) => {
            if (cardStatus) {
                Array.from(cards).forEach((card, index) => {
                    const cardClass = Array.from(card.classList).find(
                        (className) =>
                            ![
                                "mb-lg-5",
                                "mb-md-4",
                                "mb-0",
                                "border-0",
                                "overflow-hidden",
                                "cardslide",
                                "shuffle",
                                "card",
                                "featured-card",
                            ].includes(className)
                    );
                    if (cardClass) {
                        const cardNumber = parseInt(
                            cardClass.split("-")[1],
                            10
                        );
                        const nextCardNumber =
                            cardNumber === 1 ? cards.length : cardNumber - 1;
                        card.classList.remove(cardClass);
                        card.classList.add(`card-${nextCardNumber}`);
                    }
                });
                finishAnimation();
                setCardStatus(false);
            }
        };

        startAnimation();

        return () => {
            const topCard = document.querySelector(".card-1");
            if (topCard) {
                // Check if topCard is not null before removing the event listener
                topCard.removeEventListener(
                    "animationend",
                    reorderCardsWhenAnimationFinished
                );
            }
        };
    }, [cardStatus]); // Assuming cardStatus is a state or prop that controls when the animation starts

    const handleSubmitButtonClick = async () => {
        if (selectedAnswer !== undefined && selectedAnswer) {
            setShowHorseColor(horseColor);
            setIsSubmit(true);
            setCardStatus(true);
            setAnsCount((prevAnsCount) => {
                const updated = [...prevAnsCount, isTrue];
                encryptAndStore("userAnswers", updated);
                return updated;
            });
            encryptAndStore("answers_count", [...ansCount, isTrue]);
            setTimeout(function () {
                setShowHorseColor("");
            }, 1200);
        } else {
            toast.error("Please select at least one option.");
        }
    };

    const handleStudentAnswerSubmit = () => {
        if (!aiAnswer) {
            toast.error("Please enter answer to continue.");
            return;
        }
        SubmitQuiz();
    };

    const handleNextButtonClick = async () => {
        // Increment the activeStartIndex first
        let newIndex = activeStartIndex + 1;
        if (newIndex - 1 >= Quize?.quiz?.quiz_question?.length) {
            getResult();
            return; // Stop further execution if we're submitting the quiz
        }
        setActiveStartIndex(newIndex);

        setIsSubmit(false);

        // Wait for the state to update and DOM to re-render
        setTimeout(() => {
            // Now, the card that should be animated is the new .card-1
            const cardToAnimate = document.querySelector(".card-1");
            if (cardToAnimate) {
                // Ensure to clear previous animations if needed
                cardToAnimate.classList.add("shuffle");

                // Optionally, remove the 'shuffle' class after the animation completes
                cardToAnimate.addEventListener(
                    "animationend",
                    () => {
                        cardToAnimate.classList.remove("shuffle");
                    },
                    { once: true }
                );
            }
        }, 0); // Timeout set to 0 to allow the state update to render

        // Store the updated answers
        encryptAndStore("answers_index", newIndex);
        setSelectedAnswer(null);
    };

    const getCardClassName = (index, totalQuestions, activeStartIndex) => {
        // Calculate position of the card relative to the active card
        let position = index - activeStartIndex + 1;
        if (position < 1) {
            position += totalQuestions; // Wrap around if before the active card
        }
        return `card cardslide card-${position} featured-card overflow-hidden border-0 mb-0 mb-md-4 mb-lg-5`;
    };

    const handleAnswerClick = (answer, id) => {
        let keydata = CryptoJS.enc.Utf8.parse(key);
        var decrypted = CryptoJS.AES.decrypt(id, keydata, {
            mode: CryptoJS.mode.ECB,
        });
        const myid = decrypted.toString(CryptoJS.enc.Utf8);

        setTrue_id(myid);
        encryptAndStore(true_id, myid);
        const color = myid == answer.id ? "green" : "red";
        setHorseColor(color);
        setIsTrue(myid == answer.id);
        // Set the selected answer in the state
        // setSelectedAnswer(answer);
        // Retrieve the existing answers from the sessionStorage
        let existingAnswers = storedAnswers;

        // Ensure existingAnswers is an array
        if (!Array.isArray(existingAnswers)) {
            existingAnswers = [];
        }

        // Current question ID

        const currentQuestionId =
            Quize.quiz?.quiz_question[activeStartIndex - 1]?.id;

        // Check if an answer for the current question already exists
        const answerIndex = existingAnswers.findIndex(
            (a) => a.question_id === currentQuestionId
        );

        if (answerIndex !== -1) {
            // Update the existing answer for this question
            existingAnswers[answerIndex] = {
                ...answer,
                question_id: currentQuestionId,
            };
        } else {
            // Add a new answer for this question
            existingAnswers.push({ ...answer, question_id: currentQuestionId });
        }

        // Store the answer along with whether it was correct
        const answerData = {
            answer_id: answer.id,
            question_id: id,
            is_correct: myid !== answer.id,
        };

        // Store the updated answers
        encryptAndStore("answers", existingAnswers);

        // Set state as needed to update the UI immediately
        setSelectedAnswer(answerData);
        // Add any other state updates here
    };

    // Function to fetch quiz questions
    const getQuizes = async () => {
        const decryptedInfo = decryptFromStore("businessCourse");

        if (!decryptedInfo) {
            // history("/");
            return;
        }

        if (isFetchingQuestion) {
            return;
        }

        setIsFetchingQuestion(true);
        setLoder(true);

        try {
            setQuize({
                quiz: {
                    quiz_question: decryptedInfo.quizQuestions,
                    max_allowed_time:
                        decryptedInfo.courseDetails.max_allowed_time,
                },
                course_details: decryptedInfo.courseDetails,
            });

            const answers = decryptFromStore("userAnswers") || []
            if(answers.length > 0) {
                setQuestionNo(answers.length + 1)
                setNewQuestion(answers.length + 1)
            } else {
                setQuestionNo(1);
                setNewQuestion(1);
            }
            setLoder(false);
            setIsFetchingQuestion(false);
        } catch (error) {
            console.error(error);
            setLoder(false);
            setIsFetchingQuestion(false);
        }
    };

    // Effect for fetching quizzes
    useEffect(() => {
        getQuizes();
    }, []);

    useEffect(() => {
        // Function to parse the time string into total seconds
        const parseTimeToSeconds = (timeString) => {
            const [hours, minutes, seconds] = timeString.split(":").map(Number);
            return hours * 3600 + minutes * 60 + seconds;
        };

        let remainingTimeInSeconds;

        // Check if there's a saved time in sessionStorage
        const savedTime = decryptFromStore("remainingTime");
        if (savedTime) {
            remainingTimeInSeconds = parseInt(savedTime, 10);
        } else if (Quize?.quiz && Quize?.quiz?.max_allowed_time) {
            // If no saved time, initialize the timer based on the quiz's max time
            remainingTimeInSeconds = parseTimeToSeconds(
                Quize?.quiz?.max_allowed_time
            );
        }

        // Update the time left state
        setTimeLeft(remainingTimeInSeconds);

        const interval = setInterval(() => {
            if (!loder) {
                // Check if the loader is off
                setTimeLeft((prevTime) => {
                    const updatedTime = prevTime - 1;
                    encryptAndStore("remainingTime", updatedTime); // Update the time in localStorage
                    if (updatedTime <= 0) {
                        clearInterval(interval);
                        getResult();
                        return 0;
                    }
                    return updatedTime;
                });
            }
        }, 1000);

        // Clear interval and localStorage on unmount
        return () => {
            clearInterval(interval);
            // Optionally keep the remaining time in localStorage if you want to resume even after closing the browser
            // localStorage.removeItem('remainingTime');
        };
    }, [Quize, isFetchingQuestion, loder]); // Add 'loder' to the dependency array


    // Calculate minutes and seconds from remaining seconds
    // Calculate hours, minutes, and seconds from remaining seconds
    const hours = Math.floor(timeLeft / 3600);
    const minutes = Math.floor((timeLeft % 3600) / 60);
    const seconds = timeLeft % 60;
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

    const parseTimeToSeconds = (timeString) => {
        const [hours, minutes, seconds] = timeString?.split(":").map(Number);
        return hours * 3600 + minutes * 60 + seconds;
    };

    const SubmitQuiz = (points, percentageScore) => {

        // Parse the max allowed time to seconds
        const initialTimeInSeconds = parseTimeToSeconds(
            Quize?.quiz?.max_allowed_time
        );
        // Retrieve the remaining time in seconds from localStorage
        const remainingTimeInSeconds = parseInt(
            decryptFromStore("remainingTime") || "0",
            10
        );

        // Calculate the time taken in seconds
        const timeTakenInSeconds =
            initialTimeInSeconds - remainingTimeInSeconds;
        const hoursTaken = Math.floor(timeTakenInSeconds / 3600);
        const minutesTaken = Math.floor((timeTakenInSeconds % 3600) / 60);
        const secondsTaken = timeTakenInSeconds % 60;
        const formattedTimeTaken = `${hoursTaken
            .toString()
            .padStart(2, "0")}:${minutesTaken
            .toString()
            .padStart(2, "0")}:${secondsTaken.toString().padStart(2, "0")}`;

        sessionStorage.removeItem("result_api_data");
        sessionStorage.removeItem("resultId");
        sessionStorage.removeItem("chatGptResult");
        const obj = {
            score: percentageScore,
            taken_time: formattedTimeTaken,
            points: points,
            courseName: Quize?.course_details?.name
        };

        // Rest of your submit logic
        sessionStorage.removeItem("endTime");
        sessionStorage.removeItem("remainingTime"); // Clear the timer state from localStorage
        sessionStorage.removeItem("quiz");
        sessionStorage.removeItem("QuestionNo");
        sessionStorage.removeItem("answers_index");
        sessionStorage.removeItem("answers_count");
        sessionStorage.removeItem("isSubmit");
        sessionStorage.removeItem("isTrue");
        sessionStorage.removeItem("true_id");
        sessionStorage.removeItem("answers");
        sessionStorage.removeItem("userAnswers");
        sessionStorage.removeItem("remainingPathColor");
        sessionStorage.clear();
        localStorage.removeItem("remainingPathColor");
        localStorage.removeItem("remainingTime");

        encryptAndStore("businessQuizAnswer", obj);
        // window.location.href = "/business/course-result";
        navigate("/data-privacy/course-result");
    };

    const getResult = async () => {
        try {
            const userAnswer = decryptFromStore("userAnswers") || [];
            const questionLength = Quize?.quiz?.quiz_question?.length;
            const trueCount = userAnswer.filter(
                (value) => value === true
            ).length;

            const percentageScore = Math.round(
                (trueCount / questionLength) * 100
            );
            const points = trueCount;

            const submitRes = await api.postWithToken(
                `${serverURL}trails/step-progress/`,
                {
                    trail_step: 2,
                    status: "completed",
                    points_earned: points,
                    score: percentageScore,
                }
            );
            if(points !== 0 ) {
                const submitPoint = await api.postWithToken(
                    `${serverURL}trails/point-history/`,
                    {
                        activity_type: "course-completed",
                        activity: "Course Completed",
                        point_gained: points,
                        point_lost: 0,
                    }
                );
            }
            getTrailsTotalPoint()
            if (submitRes?.data?.success) {
                SubmitQuiz(points, percentageScore);
            }
        } catch (error) {
            console.log(error);
        }
    };

    // select selected ans on next or previous question
    useEffect(() => {
        // Load stored answers from session storage
        const storedAnswers = decryptFromStore("answers") || [];
        const storedAnswerCount = decryptFromStore("answers_count") || [];
        const storedAnswer_index = decryptFromStore("answers_index")
        const answers = decryptFromStore("userAnswers") || []


        // Find the stored answer for the current question
        const currentQuestionId =
            Quize.quiz?.quiz_question[activeStartIndex - 1]?.id;
        const currentStoredAnswer = storedAnswers?.find(
            (a) => a.question_id === currentQuestionId
        );

        // Set the selected answer state
        // setSelectedAnswer(currentStoredAnswer);
        setActiveStartIndex(answers.length + 1);
        setAnsCount(answers);
    }, [activeStartIndex]); // Dependency array ensures this runs when the current question changes


    // ----------- timer circle color manage -------------

    const FULL_DASH_ARRAY = 283;
    const WARNING_THRESHOLD = 180; // 3 minutes
    const ALERT_THRESHOLD = 120; // 2 minutes

    const COLOR_CODES = {
        info: { color: "green" },
        warning: { color: "orange", threshold: WARNING_THRESHOLD },
        alert: { color: "red", threshold: ALERT_THRESHOLD },
    };

    const [remainingPathColor, setRemainingPathColor] = useState(
        COLOR_CODES.info.color
    );

    // Function to calculate the time fraction based on the remaining time
    const calculateTimeFraction = (time) => {
        return time / TIME_LIMIT;
    };

    // Function to determine the color based on remaining time
    const getRemainingPathColor = (time) => {
        const { alert, warning } = COLOR_CODES;
        if (time <= alert.threshold) {
            return alert.color;
        } else if (time <= warning.threshold) {
            return warning.color;
        } else {
            return COLOR_CODES.info.color;
        }
    };

    // Effect to start the timer and handle countdown
    useEffect(() => {
        const timerTick = () => {
            setTimeLeft((prevTimeLeft) => {
                const newTimeLeft = prevTimeLeft - 1;

                // Determine and set the color based on the new time left
                const newColor = getRemainingPathColor(newTimeLeft);
                setRemainingPathColor(newColor);

                // Update the circle dasharray
                const circleDasharray = `${(
                    calculateTimeFraction(newTimeLeft) * FULL_DASH_ARRAY
                ).toFixed(0)} 283`;
                // document.getElementById('base-timer-path-remaining').setAttribute('stroke-dasharray', circleDasharray);

                const pathRemaining = document.getElementById(
                    "base-timer-path-remaining"
                );
                if (pathRemaining) {
                    pathRemaining.setAttribute(
                        "stroke-dasharray",
                        circleDasharray
                    );
                }

                // Store the updated time and color
                localStorage.setItem("remainingTime", newTimeLeft.toString());
                localStorage.setItem("remainingPathColor", newColor);

                return newTimeLeft;
            });
        };

        intervalRef.current = setInterval(timerTick, 1000);

        return () => {
            clearInterval(intervalRef.current);
        };
    }, [calculateTimeFraction, getRemainingPathColor]);

    // Effect to stop the interval on unmount
    useEffect(() => {
        return () => {
            clearInterval(intervalRef.current);
        };
    }, []);

    // Effect to initialize from local storage on mount
    useEffect(() => {
        const savedTime =
            parseInt(localStorage.getItem("remainingTime"), 10) || TIME_LIMIT;
        const savedColor =
            localStorage.getItem("remainingPathColor") ||
            COLOR_CODES.info.color;

        setTimeLeft(savedTime);
        setRemainingPathColor(savedColor);

        const circleDasharray = `${(
            calculateTimeFraction(savedTime) * FULL_DASH_ARRAY
        ).toFixed(0)} 283`;
        document
            .getElementById("base-timer-path-remaining")
            .setAttribute("stroke-dasharray", circleDasharray);
    }, []);

    useEffect(() => {
        const storedAnswers = decryptFromStore("answers") || [];
        // Restore selected answers and correctness feedback
        const currentQuestionId =
            Quize.quiz?.quiz_question[activeStartIndex - 1]?.question_id;
        const storedAnswerForCurrentQuestion = storedAnswers.find(
            (a) => a.question_id === currentQuestionId
        );

        if (storedAnswerForCurrentQuestion) {
            setSelectedAnswer(storedAnswerForCurrentQuestion);
            // Update any state or UI elements related to showing feedback
            // For example, using a state to track whether the selected answer was correct
            setIsTrue(storedAnswerForCurrentQuestion.is_correct);
        }
    }, [Quize.quiz?.quiz_question, activeStartIndex]);

    return (
        <>
            <Header />
            <section>
                    <PointsHeader to="/data-privacy/points" />
            </section>
            <section>
                <div className="featured-main">
                    <div className="container">
                        <div className="row g-4 g-md-3 g-lg-4 g-xl-5">
                            {loder ? (
                                <QuizCardSkeleton />
                            ) : (
                                <div
                                    className={`col-md-12 col-lg-${
                                        showAnswerPage ? 12 : 8
                                    } position-relative`}
                                >
                                    <h2 className="text-black fw-400 mb-4 mb-md-5 mb-lg-6">
                                        {Quize?.course_details?.name}
                                    </h2>
                                    {!showAnswerPage && (
                                        <div className="d-md-flex justify-content-between align-items-center mb-4">
                                            <div className="d-flex align-items-center gap-3 mb-3 mb-md-0">
                                                {/* <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={24}
                                                    height={24}
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                >
                                                    <mask
                                                        id="mask0_283_1291"
                                                        style={{
                                                            maskType: "alpha",
                                                        }}
                                                        maskUnits="userSpaceOnUse"
                                                        x={0}
                                                        y={0}
                                                        width={24}
                                                        height={24}
                                                    >
                                                        <rect
                                                            width={24}
                                                            height={24}
                                                            fill="#D9D9D9"
                                                        />
                                                    </mask>
                                                    <g mask="url(#mask0_283_1291)">
                                                        <path
                                                            d="M5 21V4H14L14.4 6H20V16H13L12.6 14H7V21H5ZM14.65 14H18V8H12.75L12.35 6H7V12H14.25L14.65 14Z"
                                                            fill="#1C1B1F"
                                                        />
                                                    </g>
                                                </svg> */}
                                                {/* <p className="text-black fw-400 fs-7 mb-0">Question {QuestionNo}</p> */}

                                                <div className="d-flex align-items-center gap-2 me-3">
                                                    <svg
                                                        width="14"
                                                        height="11"
                                                        viewBox="0 0 14 11"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M1 4.63636L5.23529 9L13 1"
                                                            stroke="#002FA7"
                                                            strokeWidth="2"
                                                        />
                                                    </svg>

                                                    <p className="fw-400 fs-7 text-black mb-0">
                                                        {ansCount.length
                                                            ? ansCount.filter(
                                                                  Boolean
                                                              )?.length
                                                            : 0}{" "}
                                                        correct
                                                    </p>
                                                </div>
                                                <div className="d-flex align-items-center gap-2">
                                                    <svg
                                                        width="13"
                                                        height="12"
                                                        viewBox="0 0 13 12"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M1.33337 1L11.3334 11"
                                                            stroke="#002FA7"
                                                            strokeWidth="2"
                                                        />
                                                        <path
                                                            d="M11 1L0.999999 11"
                                                            stroke="#002FA7"
                                                            strokeWidth="2"
                                                        />
                                                    </svg>
                                                    <p className="fw-400 fs-7 text-black mb-0">
                                                        {ansCount.length
                                                            ? ansCount.length -
                                                              (ansCount.filter(
                                                                  Boolean
                                                              )?.length
                                                                  ? ansCount.filter(
                                                                        Boolean
                                                                    )?.length
                                                                  : 0)
                                                            : 0}{" "}
                                                        wrong
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center gap-3">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={24}
                                                    height={24}
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                >
                                                    <mask
                                                        id="mask0_283_1279"
                                                        style={{
                                                            maskType: "alpha",
                                                        }}
                                                        maskUnits="userSpaceOnUse"
                                                        x={0}
                                                        y={0}
                                                        width={24}
                                                        height={24}
                                                    >
                                                        <rect
                                                            width={24}
                                                            height={24}
                                                            fill="#D9D9D9"
                                                        />
                                                    </mask>
                                                    <g mask="url(#mask0_283_1279)">
                                                        <path
                                                            d="M12.0001 21.9996C10.7501 21.9996 9.57927 21.7621 8.4876 21.2871C7.39593 20.8121 6.44593 20.1704 5.6376 19.3621C4.82926 18.5538 4.1876 17.6038 3.7126 16.5121C3.2376 15.4204 3.0001 14.2496 3.0001 12.9996C3.0001 11.7496 3.2376 10.5788 3.7126 9.48711C4.1876 8.39544 4.82926 7.44544 5.6376 6.63711C6.44593 5.82878 7.39593 5.18711 8.4876 4.71211C9.57927 4.23711 10.7501 3.99961 12.0001 3.99961C13.2501 3.99961 14.4209 4.23711 15.5126 4.71211C16.6043 5.18711 17.5543 5.82878 18.3626 6.63711C19.1709 7.44544 19.8126 8.39544 20.2876 9.48711C20.7626 10.5788 21.0001 11.7496 21.0001 12.9996C21.0001 14.2496 20.7626 15.4204 20.2876 16.5121C19.8126 17.6038 19.1709 18.5538 18.3626 19.3621C17.5543 20.1704 16.6043 20.8121 15.5126 21.2871C14.4209 21.7621 13.2501 21.9996 12.0001 21.9996ZM14.8001 17.1996L16.2001 15.7996L13.0001 12.5996V7.99961H11.0001V13.3996L14.8001 17.1996ZM5.6001 2.34961L7.0001 3.74961L2.7501 7.99961L1.3501 6.59961L5.6001 2.34961ZM18.4001 2.34961L22.6501 6.59961L21.2501 7.99961L17.0001 3.74961L18.4001 2.34961ZM12.0001 19.9996C13.9501 19.9996 15.6043 19.3204 16.9626 17.9621C18.3209 16.6038 19.0001 14.9496 19.0001 12.9996C19.0001 11.0496 18.3209 9.39544 16.9626 8.03711C15.6043 6.67878 13.9501 5.99961 12.0001 5.99961C10.0501 5.99961 8.39593 6.67878 7.0376 8.03711C5.67926 9.39544 5.0001 11.0496 5.0001 12.9996C5.0001 14.9496 5.67926 16.6038 7.0376 17.9621C8.39593 19.3204 10.0501 19.9996 12.0001 19.9996Z"
                                                            fill="#1C1B1F"
                                                        />
                                                    </g>
                                                </svg>
                                                <p className="text-black fw-400 fs-7 mb-0">
                                                    Time Elapsed
                                                </p>
                                                {/* <p className="text-primary fw-400 fs-18 mb-0">{formattedTime}</p> */}

                                                <div className="base-timer">
                                                    <svg
                                                        className="base-timer__svg"
                                                        viewBox="0 0 100 100"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <g className="base-timer__circle">
                                                            <circle
                                                                className="base-timer__path-elapsed"
                                                                cx="50"
                                                                cy="50"
                                                                r="45"
                                                            ></circle>
                                                            <path
                                                                id="base-timer-path-remaining"
                                                                strokeDasharray={`${
                                                                    calculateTimeFraction(
                                                                        timeLeft
                                                                    ) *
                                                                    FULL_DASH_ARRAY
                                                                } 283`}
                                                                className={`base-timer__path-remaining ${remainingPathColor}`}
                                                                d={`M 50,50 m -45,0 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90,0`}
                                                            ></path>
                                                        </g>
                                                    </svg>
                                                    <div
                                                        id="base-timer-label"
                                                        className="base-timer__label"
                                                    >
                                                        <div className="lh-base">
                                                            <span className="mb-0">
                                                                {formattedTime}
                                                            </span>
                                                            <p className="mb-0 fs-12 text-center fw-400">
                                                                min
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {!showAnswerPage ? (
                                        <>
                                            <div className="cardslide-main position-relative">
                                                <div className="position-absolute end-0 w-100">
                                                    <div className="position-relative w-100 slidelist-card">
                                                        {Quize?.quiz
                                                            ?.quiz_question &&
                                                            Quize?.quiz?.quiz_question.map(
                                                                (
                                                                    question,
                                                                    index
                                                                ) => (
                                                                    <div
                                                                        key={
                                                                            index
                                                                        }
                                                                        className={getCardClassName(
                                                                            index,
                                                                            Quize
                                                                                ?.quiz
                                                                                ?.quiz_question
                                                                                .length,
                                                                            activeStartIndex -
                                                                                1
                                                                        )}
                                                                    >
                                                                        {/* <div key={index} className={`card cardslide card-${index + 1} featured-card overflow-hidden border-0 mb-0 mb-md-4 mb-lg-5`}> */}
                                                                        <div className="card-body px-3 px-md-4 pt-3 pt-md-5 position-relative">
                                                                            <div className="mb-3 d-flex gap-3">
                                                                                <p className="mb-0 text-primary fw-400 fs-7">
                                                                                    Question{" "}
                                                                                    {index +
                                                                                        1}

                                                                                    /
                                                                                    {
                                                                                        Quize
                                                                                            ?.quiz
                                                                                            ?.quiz_question
                                                                                            ?.length
                                                                                    }
                                                                                </p>
                                                                                <ReportAnIssue
                                                                                    trailStep={
                                                                                        2
                                                                                    }
                                                                                    questionId={
                                                                                        index +
                                                                                        1
                                                                                    }
                                                                                    courseId= {
                                                                                        Quize.course_details.course_id
                                                                                    }
                                                                                />
                                                                            </div>

                                                                            <div className="progress-bar__wrapper mb-5 col-12 col-xl-7">
                                                                                <progress
                                                                                    id="progress-bar"
                                                                                    value={
                                                                                        activeStartIndex *
                                                                                        10
                                                                                    }
                                                                                    max="100"
                                                                                ></progress>
                                                                            </div>

                                                                            <h3 className="text-black fw-400 fs-18 mb-4">
                                                                                {
                                                                                    question?.question
                                                                                }
                                                                            </h3>
                                                                            <ul className="answer-list">
                                                                                {question?.quiz_answer?.map(
                                                                                    (
                                                                                        answer,
                                                                                        index
                                                                                    ) => (
                                                                                        <div
                                                                                            className="d-flex align-items-start gap-2 "
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                        >
                                                                                            <li
                                                                                                key={
                                                                                                    answer.id
                                                                                                }
                                                                                                className="fw-400 fs-18 col-12 col-xl-7 custom-radios"
                                                                                            >
                                                                                                <label
                                                                                                    htmlFor={`answer_${
                                                                                                        question.id +
                                                                                                        answer.id
                                                                                                    }`}
                                                                                                    className={`position-relative d-flex justify-content-between align-items-start w-100 px-3 py-2 label-border gap-3 ${
                                                                                                        isSubmit
                                                                                                            ? selectedAnswer?.answer_id ===
                                                                                                              answer.id
                                                                                                                ? answer.id ==
                                                                                                                  true_id
                                                                                                                    ? "selected-answer-green"
                                                                                                                    : "selected-answer-red"
                                                                                                                : answer.id ==
                                                                                                                  true_id
                                                                                                                ? "selected-answer-green"
                                                                                                                : ""
                                                                                                            : selectedAnswer?.answer_id ===
                                                                                                              answer.id
                                                                                                            ? "selected-answer"
                                                                                                            : "border-primary-1"
                                                                                                    }`}
                                                                                                >
                                                                                                    <div className="d-flex align-items-start text-wrap">
                                                                                                        <p className="mb-0">
                                                                                                            {String.fromCharCode(
                                                                                                                65 +
                                                                                                                    index
                                                                                                            )}
                                                                                                        </p>

                                                                                                        .{" "}
                                                                                                        {
                                                                                                            answer.option
                                                                                                        }
                                                                                                    </div>
                                                                                                    <input
                                                                                                        type="radio"
                                                                                                        id={`answer_${
                                                                                                            question.id +
                                                                                                            answer.id
                                                                                                        }`}
                                                                                                        name="quiz_answer"
                                                                                                        value={
                                                                                                            answer.id
                                                                                                        }
                                                                                                        onChange={() =>
                                                                                                            handleAnswerClick(
                                                                                                                answer,
                                                                                                                question.id
                                                                                                            )
                                                                                                        }
                                                                                                        checked={
                                                                                                            selectedAnswer?.answer_id ==
                                                                                                            answer.id
                                                                                                        }
                                                                                                        disabled={
                                                                                                            isSubmit
                                                                                                        } // Disable if question is submitted
                                                                                                    />
                                                                                                    <label
                                                                                                        htmlFor={`answer_${
                                                                                                            question.id +
                                                                                                            answer.id
                                                                                                        }`}
                                                                                                        className="radio-check-box"
                                                                                                    >
                                                                                                        <span>
                                                                                                            <svg
                                                                                                                style={{
                                                                                                                    opacity:
                                                                                                                        selectedAnswer?.answer_id &&
                                                                                                                        selectedAnswer?.answer_id ===
                                                                                                                            answer.id &&
                                                                                                                        1,
                                                                                                                }}
                                                                                                                className="check-icon"
                                                                                                                fill="#000000"
                                                                                                                version="1.1"
                                                                                                                id="Capa_1"
                                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                                                                                width="15px"
                                                                                                                height="40px"
                                                                                                                viewBox="0 0 78.369 78.369"
                                                                                                                xmlSpace="preserve"
                                                                                                            >
                                                                                                                <g
                                                                                                                    id="SVGRepo_bgCarrier"
                                                                                                                    strokeWidth={
                                                                                                                        0
                                                                                                                    }
                                                                                                                />
                                                                                                                <g
                                                                                                                    id="SVGRepo_tracerCarrier"
                                                                                                                    strokeLinecap="round"
                                                                                                                    strokeLinejoin="round"
                                                                                                                />
                                                                                                                <g id="SVGRepo_iconCarrier">
                                                                                                                    {" "}
                                                                                                                    <g>
                                                                                                                        {" "}
                                                                                                                        <path d="M78.049,19.015L29.458,67.606c-0.428,0.428-1.121,0.428-1.548,0L0.32,40.015c-0.427-0.426-0.427-1.119,0-1.547l6.704-6.704 c0.428-0.427,1.121-0.427,1.548,0l20.113,20.112l41.113-41.113c0.429-0.427,1.12-0.427,1.548,0l6.703,6.704 C78.477,17.894,78.477,18.586,78.049,19.015z" />{" "}
                                                                                                                    </g>{" "}
                                                                                                                </g>
                                                                                                            </svg>

                                                                                                            <svg
                                                                                                                style={{
                                                                                                                    opacity:
                                                                                                                        selectedAnswer?.answer_id &&
                                                                                                                        selectedAnswer?.answer_id ===
                                                                                                                            answer.id &&
                                                                                                                        1,
                                                                                                                }}
                                                                                                                className="close-icon"
                                                                                                                width="12"
                                                                                                                height="11"
                                                                                                                viewBox="0 0 12 11"
                                                                                                                fill="none"
                                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                            >
                                                                                                                <path
                                                                                                                    d="M1.33203 0.5L11.332 10.5"
                                                                                                                    stroke="#DB0544"
                                                                                                                />
                                                                                                                <path
                                                                                                                    d="M11 0.5L0.999999 10.5"
                                                                                                                    stroke="#DB0544"
                                                                                                                />
                                                                                                            </svg>
                                                                                                        </span>
                                                                                                    </label>
                                                                                                    {selectedAnswer?.answer_id ===
                                                                                                        answer.id &&
                                                                                                        showHorseColor ===
                                                                                                            "green" && (
                                                                                                            <div className="horse-img d-none righthorse">
                                                                                                                <img
                                                                                                                    src="/gif/1.gif"
                                                                                                                    alt=""
                                                                                                                />
                                                                                                            </div>
                                                                                                        )}
                                                                                                    {showHorseColor ===
                                                                                                        "red" && (
                                                                                                        <div className="horse-img d-none wronghorse">
                                                                                                            <img
                                                                                                                src="/gif/2.gif"
                                                                                                                alt=""
                                                                                                            />
                                                                                                        </div>
                                                                                                    )}
                                                                                                </label>
                                                                                            </li>
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row justify-content-between align-items-center mb-2 my-md-4 my-lg-5 g-2">
                                                <div className="col-6 col-md-4 col-lg-4 col-xl-3"></div>
                                                <div className="col-6 col-md-4 col-lg-4 col-xl-3">
                                                    {!isSubmit ? (
                                                        <Link
                                                            // to="/featured-quize-2"
                                                            type="button"
                                                            onClick={() =>
                                                                handleSubmitButtonClick()
                                                            }
                                                            className="btn-explore p-sm-15 d-flex justify-content-between align-items-center gap-3 rounded-2 w-100 mt-4"
                                                        >
                                                            Submit
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={22}
                                                                height={14}
                                                                viewBox="0 0 22 14"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M1 6.99854H21L16 1"
                                                                    stroke="white"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M21 6.99805L16 12.9995"
                                                                    stroke="white"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                />
                                                            </svg>
                                                        </Link>
                                                    ) : (
                                                        <Link
                                                            type="button"
                                                            onClick={
                                                                handleNextButtonClick
                                                            }
                                                            className="btn-explore p-sm-15 d-flex justify-content-between align-items-center gap-3 rounded-2 w-100 mt-4"
                                                        >
                                                            Next
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={22}
                                                                height={14}
                                                                viewBox="0 0 22 14"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M1 6.99854H21L16 1"
                                                                    stroke="white"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M21 6.99805L16 12.9995"
                                                                    stroke="white"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                />
                                                            </svg>
                                                        </Link>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="row g-4 g-md-3 g-lg-4 g-xl-5">
                                                <div className="col-md-12 col-lg-6">
                                                    <div className="card featured-card linear-gradient rounded-3 overflow-hidden border-0">
                                                        <div className="card-body px-4 pt-5">
                                                            <h2 className="ai-step-title">
                                                                What did you
                                                                learn?
                                                            </h2>
                                                            <div className="d-flex justify-content-center ai-step-imge">
                                                                <img
                                                                    src="/png/ai-step.png"
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-lg-6">
                                                    <h3 className="ai-step-title-2">
                                                        What did you learn about{" "}
                                                        {/* {QuizeData.name}? */}
                                                    </h3>
                                                    <p className="ai-step-description">
                                                        Please be aware that
                                                        your answer will be
                                                        graded in order to pass
                                                        this test. Please also
                                                        be aware that we are
                                                        using AI to deliver the
                                                        answer in a
                                                        sophisticated and fast
                                                        manner. If you do not
                                                        want that then you will
                                                        have to stop the test
                                                        here. We use an
                                                        interface with ChatGPT.
                                                        It is safe and no
                                                        personal data will be
                                                        processed.
                                                    </p>
                                                    <textarea
                                                        rows="15"
                                                        className="form-control pt-3 ai-textarea"
                                                        placeholder="Please provide your answer here."
                                                        id="floatingTextarea2"
                                                        onChange={(e) =>
                                                            setAiAnswer(
                                                                e.target.value
                                                            )
                                                        }
                                                        defaultValue={""}
                                                    />
                                                    <div className="row justify-content-end mt-5">
                                                        <div className="col-6 col-md-4 col-lg-4 col-xl-4">
                                                            <button
                                                                type="button"
                                                                onClick={
                                                                    handleStudentAnswerSubmit
                                                                }
                                                                className="btn-explore p-sm-15 d-flex justify-content-between align-items-center gap-3 rounded-2 w-100 mt-4"
                                                            >
                                                                Send Answer
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={22}
                                                                    height={14}
                                                                    viewBox="0 0 22 14"
                                                                    fill="none"
                                                                >
                                                                    <path
                                                                        d="M1 6.99854H21L16 1"
                                                                        stroke="white"
                                                                        strokeWidth="1.5"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                    <path
                                                                        d="M21 6.99805L16 12.9995"
                                                                        stroke="white"
                                                                        strokeWidth="1.5"
                                                                        strokeLinecap="round"
                                                                    />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default BusinessCourseQuiz;
