import React, { useEffect, useState } from 'react';

const ScoreProgressBar = ({ percentage, width, height, fontSize, size = 200, strokeWidth = 10, color, trailColor = "#C2D3FF" }) => {
    const [percentageVal, setPercentageVal] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setPercentageVal((prev) => (prev < percentage ? prev + 1 : percentage));
        }, 30);

        return () => clearInterval(interval);
    }, []);

    // Calculate the circumference of the circle
    const radius = (size - strokeWidth) / 2;
    const circumference = 2 * Math.PI * radius;

    // Calculate the offset to reverse the progress
    const offset = circumference - (percentageVal / 100) * circumference;

    return (
        <div className='progress_bar_main' style={{ position: 'relative', width: width, height: height }}>
            <svg
                width={width}
                height={height}
                viewBox={`0 0 ${size} ${size}`}
                style={{ transform: 'rotate(-90deg)' }} // Rotate the circle to start from top
            >
                {/* Background Circle */}
                <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    stroke={trailColor}
                    strokeWidth={strokeWidth}
                    fill="none"
                />
                {/* Progress Circle */}
                <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    stroke={color}
                    strokeWidth={strokeWidth}
                    fill="none"
                    strokeDasharray={circumference}
                    strokeDashoffset={offset}
                    style={{ transition: 'stroke-dashoffset 0.3s ease', strokeLinecap: 'round' }}
                />
            </svg>
            <div
                className='percentage_text'
                style={{
                    color: color,
                    fontSize: fontSize,
                }}
            >
                {percentageVal}%
            </div>
        </div>
    );
};

export default ScoreProgressBar;
