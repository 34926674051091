import React, { useContext } from 'react'
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import "../../styles/newbusiness.css"
import { Link, useNavigate } from 'react-router-dom';
import { CourseContext } from '../../context/CourseContext';

const BusinessLandingPage = () => {
    const { user } = useContext(CourseContext);
    const  navigate = useNavigate()
    const cards = [
        {
            id: 1,
            imageSrc: "/new-business/landing-1.webp",
            title: "Microlearning Modules",
            description: "Learn in 10-minute segments, perfect for busy schedules and fast-paced environments."
        },
        {
            id: 2,
            imageSrc: "/new-business/landing-2.webp",
            title: "Interactive Scenarios",
            description: "Experience real-life situations through engaging case studies and role-playing activities."
        },
        {
            id: 3,
            imageSrc: "/new-business/landing-3.webp",
            title: "Always Up-to-Date",
            description: "Content refreshed regularly to stay informed with constantly updated materials."
        },
        {
            id: 4,
            imageSrc: "/new-business/landing-4.webp",
            title: "Personalized Learning",
            description: "Learning paths tailored to job roles, with topics and tips suited to every learner’s needs."
        },
        {
            id: 5,
            imageSrc: "/new-business/landing-5.webp",
            title: "Mobile accessibility",
            description: "Learn on any device, anytime, anywhere."
        },
    ];


 const handleNavigate = () => {
    if(user?.role) {
        navigate("/data-privacy-step")
    } else {
        navigate("/sign-in")
    }
}


    return (
        <>
            <Header />
            <main>
                <section className='new_business_landing'>
                    <div className="team-pricing overflow-hidden d-flex justify-content-center align-items-center">
                        <div className="container">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-12 col-md-6 col-lg-6 order-2 order-md-1">
                                    { (user.main_role === 1 || user.role === 2)?
                                        <>
                                            <h2 className="display-5 text-start text-white fw-400 mb-3">
                                                Empower Yourself and Your Team with Engaging, Effective Learning Paths
                                            </h2>
                                            <p className="text-start text-white fs-6 fw-300 mb-4">
                                                Interactive training for data privacy and digital safety, designed for professionals.
                                            </p>
                                        </> :
                                        <>
                                            <h2 className="display-5 text-start text-white fw-400 mb-3">
                                                Empowering Kids to Navigate the Online World Safely
                                            </h2>
                                            <p className="text-start text-white fs-6 fw-300 mb-4">
                                                Engaging courses for kids and resources for parents.
                                            </p>
                                        </>
                                    }
                                    <button
                                        onClick={handleNavigate}
                                        className="btn btn-warning text-black d-inline-flex align-items-center gap-3 fw-600 fs-18 w-auto"
                                    >
                                        Start Free Trial
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={22}
                                            height={14}
                                            viewBox="0 0 22 14"
                                            fill="none"
                                        >
                                            <path
                                                d="M1 6.99854H21L16 1"
                                                stroke="#000C2A"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M21 6.99854L16 13"
                                                stroke="#000C2A"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 position-relative d-flex justify-content-end order-1 order-md-2">
                                    <div>
                                        <img
                                            src="../../individual-pricing/team-pricing-vector-3.png"
                                            className="team-pricing-vector-3"
                                            alt=""
                                        />
                                        <img
                                            src="../../individual-pricing/boy-and-girl.png"
                                            className="boy-and-girl position-relative z-1"
                                            alt=""
                                        />
                                        <img
                                            src="../../individual-pricing/team-pricing-vector-4.png"
                                            className="team-pricing-vector-4"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="unique-edge-main position-relative ">
                        <div className="container">
                            { (user.main_role === 1 || user.role === 2) ?
                                <div className="section-title">
                                    <span className="d-block fs-18">Unique Edge</span>
                                    <h2 className="mb-3 col-md-12 col-lg-6 m-auto mt-3">
                                        Unlock Your Learning Potential!
                                    </h2>
                                    <p className="text-center mb-0 col-md-12 col-lg-9 m-auto fs-19 sm-fs-14 text-muted">
                                        In our commitment to providing an exceptional eLearning experience, we take pride in the unique key features that set us apart from the rest. With a focus on creating an environment where learning becomes an exciting journey, we've carefully crafted each feature to cater to your needs and empower you to reach new heights in your knowledge and skills. Here's what makes our platform truly special:
                                    </p>
                                </div> :
                                  <div className="section-title">
                                  <span className="d-block fs-18">Unique Edge</span>
                                  <h2 className="mb-3 col-md-12 col-lg-6 m-auto mt-3">
                                      Online Safety for Kids
                                  </h2>
                              </div>
                            }

                            <div className="row row-cols-2 row-cols-md-2 row-cols-lg-3 new_business_unique_edge_card g-3 g-md-4">
                                {cards.map((card) => (
                                    <div className="col" key={card.id}>
                                        <div className="card h-100">
                                            <div className="card-body">
                                                <img src={card.imageSrc} alt={card.title} />
                                                <h3>{card.title}</h3>
                                                <p>{card.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>

                        <img
                            src="/unique-edge/yellow-vector.png"
                            className="new_business_unique_vector_yellow"
                            alt=""
                        />
                        <img
                            src="/unique-edge/pink-vector.png"
                            className="new_business_unique_vector_pink"
                            alt=""
                        />
                        <img
                            src="/unique-edge/light-green-vector.png"

                            className="new_business_unique_vector_green"
                            alt=""
                        />
                    </div>
                </section>
                <section className='new_business_account_quick text-center'>
                    <div className="container">
                        <span>Join the Learning Adventure</span>
                        <h3>Creating an account is quick, easy, and completely free!</h3>
                        <p>Don't miss out on the opportunity to dive into our diverse array of courses, engage with expert instructors, and connect with a vibrant community of learners. Whether you're a curious beginner or a seasoned learner, our platform offers something for everyone.</p>
                        <div className='d-flex justify-content-center'>
                            <button
                                type="button"
                                className="btn-explore p-sm-15 d-inline-flex align-items-center gap-3 rounded-2 mt-4"
                            >
                                Sign Up and Strart Free Trial
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={22}
                                    height={14}
                                    viewBox="0 0 22 14"
                                    fill="none"
                                >
                                    <path
                                        d="M1 6.99854H21L16 1"
                                        stroke="white"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M21 6.99805L16 12.9995"
                                        stroke="white"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                    />
                                </svg>
                            </button>
                        </div>

                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}

export default BusinessLandingPage
