import React, { useContext, useEffect, useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import "../../styles/newbusiness.css";
import PointsHeader from "../../components/NewBusiness/PointsHeader";
import DoughnutChart from "../../components/DoughnutChart";
import { Pagination, Form } from "react-bootstrap";
import DynamicPagination from "../../components/DynamicPagination";
import api from "../../helper/api";
import { getServerURL } from "../../helper/envConfig";
import { CourseContext } from "../../context/CourseContext";

const BusinessPoints = () => {
    const serverURL = getServerURL();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [goToPageNumber, setGoToPageNumber] = useState("");
    const [totalPages, setTotalPages] = useState(1); // Or dynamically calculate based on your data
    const [tableScoreData, setTableScoreData] = useState([]);
    const [totalData, setTotalData] = useState(0);
    const { pointsData, getTrailsTotalPoint } = useContext(CourseContext);

    const handleSelectChange = (event) => {
        setRowsPerPage(event.target.value);
    };

    const handleGoToPageChange = (event) => {
        setGoToPageNumber(event.target.value);
    };

    const handleGoToPage = (event) => {
        const pageNumber = parseInt(goToPageNumber);
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const isMobile = windowWidth <= 768; // Or whatever your mobile breakpoint is

    // Update windowWidth when the window is resized
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const getScoreData = async () => {
        try {
            const res = await api.getWithToken(
                serverURL +
                    `trails/point-history/?page=${currentPage}&page_size=${rowsPerPage}`
            );
            setTotalPages(res.data.total_pages);
            setTableScoreData(res.data.results);
            setTotalData(res.data.total);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getScoreData();
    }, [currentPage, rowsPerPage]);

    return (
        <>
            <Header />
            <main className="business_points">
                <section>
                    <PointsHeader to="/data-privacy/points" />
                    <div className="your_points text-center">
                        <div className="container">
                            <h6>Your Points</h6>
                            <h5 className="d-flex justify-content-center align-items-center gap-2">
                                <img
                                    src="/new-business/award-icons.webp"
                                    width={55}
                                    alt=""
                                />
                                {pointsData?.total_point_earned}
                            </h5>
                            <p>
                                <span>10 points</span>
                                {""} will be deducted if you do not log on once
                                per month
                            </p>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row points_history g-4">
                            <div className="col-12 col-lg-8 col-xl-5">
                                <div className="card">
                                    <div className="card-body">
                                        <h3>Categories</h3>

                                        <div className="d-md-flex d-xl-block d-xxl-flex align-items-center h-100">
                                            <div>
                                                <DoughnutChart
                                                    height={"200"}
                                                    width={200}
                                                    labels={[
                                                        "Data Privacy",
                                                        "Information Security",
                                                        "General Compliance",
                                                        "Other",
                                                    ]}
                                                    data={[
                                                        pointsData?.total_point_earned,
                                                        0,
                                                        0,
                                                        0,
                                                    ]}
                                                    centerFieldName={"Total"}
                                                    centerFieldValue={
                                                        pointsData?.total_point_earned
                                                    }
                                                />
                                            </div>
                                            <div className="chart-listing ps-md-3 mt-4 mt-xxl-0">
                                                <div className="d-flex align-items-center gap-2 mb-3">
                                                    <div className="dot-01 dots"></div>
                                                    <p>Data Privacy </p>
                                                    <span>
                                                        {
                                                            pointsData?.total_point_earned
                                                        }{" "}
                                                        Points
                                                    </span>
                                                </div>
                                                <div className="d-flex align-items-center gap-2 mb-3">
                                                    <div className="dot-02 dots"></div>
                                                    <p>Information Security </p>
                                                    <span>0 Points</span>
                                                </div>
                                                <div className="d-flex align-items-center gap-2 mb-3">
                                                    <div className="dot-03 dots"></div>
                                                    <p>General Compliance </p>
                                                    <span>0 Points</span>
                                                </div>
                                                <div className="d-flex align-items-center gap-2 mb-3">
                                                    <div className="dot-04 dots"></div>
                                                    <p>Other </p>
                                                    <span>0 Points</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-7">
                                <div className="card">
                                    <div className="card-body">
                                        <h3>Points History</h3>

                                        <div className="overflow-auto">
                                            <table className="table w-100">
                                                <thead>
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            className="text-nowrap"
                                                        >
                                                            Date
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            width={200}
                                                            className="text-nowrap"
                                                        >
                                                            Activity
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="text-nowrap"
                                                        >
                                                            Points Gained
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="text-nowrap"
                                                        >
                                                            Points Lost
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tableScoreData
                                                        ?.point_history
                                                        ?.length > 0 ? (
                                                        tableScoreData?.point_history?.map(
                                                            (item, index) => {
                                                                return (
                                                                    <>
                                                                        <tr
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <td className="text-nowrap">
                                                                                {
                                                                                    item.created_at
                                                                                }
                                                                            </td>
                                                                            <td
                                                                                width={
                                                                                    200
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.activity
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                +
                                                                                {
                                                                                    item.point_gained
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                -
                                                                                {item.point_lost !==
                                                                                "0"
                                                                                    ? ""
                                                                                    : item.point_lost}
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                );
                                                            }
                                                        )
                                                    ) : (
                                                        <>
                                                            <tr>
                                                                <td colSpan={5}>
                                                                    <div className="d-flex justify-content-center m-auto p-5">
                                                                        <img
                                                                            src="../svg/No-Points-History-Found.svg"
                                                                            // className="no-corce-found"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex justify-content-center mt-4">
                                            <Pagination className="table-pagination align-items-center">
                                                {!isMobile && (
                                                    <li className="page-item">
                                                        <a
                                                            className="page-link w-auto h-auto"
                                                            role="button"
                                                            tabIndex="0"
                                                        >
                                                            Rows per page
                                                            <Form.Select
                                                                aria-label="Rows per page"
                                                                className="select-number"
                                                                value={
                                                                    rowsPerPage
                                                                }
                                                                onChange={
                                                                    handleSelectChange
                                                                }
                                                            >
                                                                <option value="10">
                                                                    10
                                                                </option>
                                                                <option value="15">
                                                                    15
                                                                </option>
                                                                <option value="20">
                                                                    20
                                                                </option>
                                                                <option value="25">
                                                                    25
                                                                </option>
                                                            </Form.Select>
                                                        </a>
                                                    </li>
                                                )}
                                                {totalPages >= 2 && (
                                                    <>
                                                        <DynamicPagination
                                                            currentPage={
                                                                currentPage
                                                            }
                                                            totalPages={
                                                                totalPages
                                                            }
                                                            onPageChange={(
                                                                newPage
                                                            ) => {
                                                                setCurrentPage(
                                                                    newPage
                                                                );
                                                            }}
                                                        />
                                                        <div className="ms-0 ms-md-5 d-flex">
                                                            <a
                                                                className="page-link w-auto h-auto border-0 bg-transparent d-none d-lg-block"
                                                                role="button"
                                                                tabIndex="0"
                                                            >
                                                                <span
                                                                    aria-hidden="true"
                                                                    className="text-muted fw-400 fs-7 text-nowrap"
                                                                >
                                                                    Go to page
                                                                </span>
                                                            </a>
                                                            <input
                                                                className="form-control border-0 border-bottom rounded-0 w-50 d-none d-lg-block"
                                                                type="number"
                                                                min="1"
                                                                max={totalPages}
                                                                onChange={
                                                                    handleGoToPageChange
                                                                }
                                                                value={
                                                                    goToPageNumber
                                                                }
                                                                onKeyDown={(
                                                                    e
                                                                ) => {
                                                                    if (
                                                                        e.key ===
                                                                        "Enter"
                                                                    ) {
                                                                        handleGoToPage(
                                                                            e
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                            <div className="ms-3 cursor-pointer d-none d-lg-block">
                                                                <svg
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    onClick={
                                                                        handleGoToPage
                                                                    }
                                                                >
                                                                    <path
                                                                        d="M10.0037 6L8.59375 7.41L13.1737 12L8.59375 16.59L10.0037 18L16.0037 12L10.0037 6Z"
                                                                        fill="#242731"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </Pagination>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
};

export default BusinessPoints;
