import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Header from './../layout/Header';
import Footer from './../layout/Footer';
import { getServerURL } from '../helper/envConfig';
import api from '../helper/api';
import { decryptFromStore, encryptAndStore } from '../helper/sessionStore';

function UsersSignUp() {

    const navigate = useNavigate();
    const serverURL = getServerURL();
    const [subRoles, setSubRoles] = useState([]);
    const [role, setRole] = useState();
    const location = useLocation()

    // Function to fetch quiz questions
    const getRoles = async () => {
        try {
            const response = await api.post(`${serverURL}users/sub-roles/`, { mian_role_id: role });
            if (response.data) {
                setSubRoles(response.data.data);
                // window.scrollTo(0, 0);
            }
        } catch (error) {
            console.error(error);
        };
    }

    // Effect for fetching quizzes
    useEffect(() => {
        const Role = decryptFromStore('role');
        setRole(Role)
        getRoles();
    }, [role]);

    const selectRole = (role) => {
        encryptAndStore('sub-role', role);
        setTimeout(() => {
            navigate("/user-details", {
                state: { is_come_from_free_trail: location?.state?.is_come_from_free_trail ?  location?.state?.is_come_from_free_trail: false }
            });
        }, 10); // Adjust the delay as needed
    }

    return (
        <>
            <Header />
            <section>
                <div className="container-fluid">
                    <div className="sign-up-main">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-12 col-md-6 position-relative bg-primary d-flex justify-content-center align-items-center sign-up-col">
                                <img
                                    src="./login/sing-vector-1.png"
                                    className="sing-vector-1"
                                    alt=""
                                />
                                <img
                                    src="./login/project-team.png"
                                    className="project-team"
                                    alt=""
                                />
                                <img
                                    src="./login/sing-vector-2.png"
                                    className="sing-vector-2"
                                    alt=""
                                />
                            </div>
                            <div className="col-12 col-md-6 position-relative d-flex justify-content-center align-items-start align-items-md-center sign-up-col sign-up-col-sm">
                                <div className="col-12 col-md-9">
                                    {subRoles &&
                                        subRoles?.map((data) => (
                                            <Link
                                                key={data.role_id} // Assuming role_id is unique
                                                onClick={() => selectRole(data.role_id)}
                                                to="/manager-ceo"
                                                className='d-block'
                                                >
                                                <div className="card border-0 shadow-sm rounded-2 mb-3 w-auto d-inline-flex">
                                                    <div className="d-flex align-items-center gap-3 p-4">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={20}
                                                            height={20}
                                                            viewBox="0 0 20 20"
                                                            fill="none"
                                                        >
                                                            <circle cx={10} cy={10} r={10} fill="#DBE5FF" />
                                                        </svg>
                                                        <p className="text-black fw-400 fs-6 mb-0">
                                                        {data.role_name}
                                                        </p>
                                                    </div>
                                                </div>
                                            </Link>
                                        ))
                                    }

                                    {/* 
                                    <Link to="/compliance-officer" className="d-block">
                                        <div className="card border-0 shadow-sm rounded-2 mt-4 d-inline-block">
                                            <div className="d-flex align-items-center gap-3 p-4">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={20}
                                                    height={20}
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                >
                                                    <circle cx={10} cy={10} r={10} fill="#DBE5FF" />
                                                </svg>
                                                <p className="text-black fw-400 fs-6 mb-0">
                                                    Are you a Compliance Officer?
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/dpos" className="d-block">
                                        <div className="card border-0 shadow-sm rounded-2 mt-4 d-inline-block">
                                            <div className="d-flex align-items-center gap-3 p-4">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={20}
                                                    height={20}
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                >
                                                    <circle cx={10} cy={10} r={10} fill="#DBE5FF" />
                                                </svg>
                                                <p className="text-black fw-400 fs-6 mb-0">Are you a DPOS?</p>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/employe" className="d-block">
                                        <div className="card border-0 shadow-sm rounded-2 mt-4 d-inline-block">
                                            <div className="d-flex align-items-center gap-3 p-4">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={20}
                                                    height={20}
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                >
                                                    <circle cx={10} cy={10} r={10} fill="#DBE5FF" />
                                                </svg>
                                                <p className="text-black fw-400 fs-6 mb-0">
                                                    Are you an Employee?
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="" className="d-block">
                                        <div className="card border-0 shadow-sm rounded-2 mt-4 d-inline-block">
                                            <div className="d-flex align-items-center gap-3 p-4">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={20}
                                                    height={20}
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                >
                                                    <circle cx={10} cy={10} r={10} fill="#DBE5FF" />
                                                </svg>
                                                <p className="text-black fw-400 fs-6 mb-0">
                                                    Individual user?
                                                </p>
                                            </div>
                                        </div>
                                    </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default UsersSignUp