/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AdminLayout from "../../components/Admin/AdminLayout";
import DatePicker from "../../components/DatePicker";
import { Button, Form, Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import SendCommunication from "../../components/Admin/UserManagement/SendCommunication";
import SelectUserStatusModal from "../../components/Admin/UserManagement/SelectUserStatusModal";
import SelectRoleModal from "../../components/Admin/UserManagement/SelectRoleModal";
import ExportDataModal from "../../components/Admin/UserManagement/ExportDataModal";
import UpdatePermissions from "../../components/Admin/UserManagement/UpdatePermissions";
import { getServerURL } from "../../helper/envConfig";
import api from "../../helper/api";
import moment from "moment/moment";
import { Link, useNavigate } from "react-router-dom";
import CommonLoader from "../../components/Admin/CommonLoader";
import SelectFlagReasonModal from "../../components/Admin/FlaggedQuestion/SelectFlagReasonModal";
import { Truncate } from "@patternfly/react-core";
import { toast } from "react-toastify";

function AdminFlaggedQuestions() {
    const navigate = useNavigate();
    const serverURL = getServerURL();
    const [showLoader, setShowLoader] = useState(true);
    const [questionList, setQuestionList] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalUsers, setTotalUsers] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [roles, setRoles] = useState([]);
    const [search, setSearch] = useState("");
    const [flagReasonFilter, setFlagReasonFilter] = useState("");
    const [lastActiveFilterDate, setLastActiveFilterDate] = useState();
    const [filteredDate, setFilteredDate] = useState();
    const [searchFilterText, setSearchFilterText] = useState("");
    const [filterRoles, setFilterRoles] = useState(null);
    const [shortBy, setShortBy] = useState("");
    const [showRegFilterModel, setShowRegFilterModel] = useState(false);
    const [showFlagReasonModel, setShowFlagReasonFilterModel] = useState(false);
    const [showUserRoleFilterModel, setShowUserRoleFilterModel] =
        useState(false);
    const [showLastActiveFilterModel, setShowLastActiveFilterModel] =
        useState(false);
    const [activeItems, setActiveItems] = useState({});
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [allSelected, setAllSelected] = useState(false);
    const [viewFlagQuestion, setViewFlagQuestion] = useState({});
    const [editFlagQuestion, setEditFlagQuestion] = useState({});
    const [question, setQuestion] = useState("");
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showResolve, setShowResolve] = useState(false);
    const [selectedOption, setSelectedOption] = useState(''); // Default selected option
    const [editing, setEditing] = useState(null); // Track which option is being edited

    const getUserList = async () => {
        try {
            const queryParams = new URLSearchParams({
                page,
                page_size: pageSize,
                ...(filteredDate
                    ? {
                        form_date: moment(filteredDate.startDate).format(
                            "YYYY-MM-DD"
                        ),
                        to_date: moment(filteredDate.endDate).format(
                            "YYYY-MM-DD"
                        ),
                    }
                    : {}),
                ...(lastActiveFilterDate
                    ? {
                        last_active_form_date: moment(
                            lastActiveFilterDate.startDate
                        ).format("YYYY-MM-DD"),
                        last_active_to_date: moment(
                            lastActiveFilterDate.endDate
                        ).format("YYYY-MM-DD"),
                    }
                    : {}),
                ...(searchFilterText ? { search: searchFilterText } : {}),
                ...(shortBy ? { ordering: shortBy } : {}),
                ...(flagReasonFilter ? { issue_type: flagReasonFilter } : {}),
            }).toString();
            const response = await api.getWithToken(
                `${serverURL}admin-panel/flagged-questions/list?${queryParams}`
            );
            setQuestionList(response.data.results.flagged_questions);
            setTotalUsers(response.data.total);
            setTotalPages(response.data.total_pages);
            setShowLoader(false);
        } catch (error) {
            setQuestionList([]);
            console.error(error);
        }
    };

    useEffect(() => {
        getUserList();
    }, [page]);

    useEffect(() => {
        if (page !== 1) {
            setPage(1);
        } else {
            getUserList();
        }
    }, [
        filteredDate?.startDate,
        filteredDate?.endDate,
        lastActiveFilterDate?.startDate,
        lastActiveFilterDate?.endDate,
        searchFilterText,
        shortBy,
        filterRoles,
        flagReasonFilter,
    ]);

    const getUserRoles = async () => {
        try {
            const response = await api.getWithToken(
                `${serverURL}admin-panel/user-management/roles-list`
            );
            setRoles(response.data.data);
        } catch (error) {
            setRoles([]);
            console.error(error);
        }
    };

    useEffect(() => {
        getUserRoles();
    }, []);

    const handleToggle = (key) => {
        if (key === "registration") {
            setShowRegFilterModel(!showRegFilterModel);
            setShowLastActiveFilterModel(false);
            setShowFlagReasonFilterModel(false);
            setShowUserRoleFilterModel(false);

            setActiveItems({
                registration: true,
            });
        } else if (key === "flagReason") {
            setShowFlagReasonFilterModel(!showFlagReasonModel);
            setShowRegFilterModel(false);
            setShowUserRoleFilterModel(false);
            setShowLastActiveFilterModel(false);
            setActiveItems({
                flagReason: true,
            });
        } else if (key === "userRole") {
            setShowUserRoleFilterModel(!showUserRoleFilterModel);
            setShowRegFilterModel(false);
            setShowFlagReasonFilterModel(false);
            setShowLastActiveFilterModel(false);
            setActiveItems({
                userRole: true,
            });
        } else if (key === "LastActive") {
            setShowLastActiveFilterModel(!showLastActiveFilterModel);
            setShowFlagReasonFilterModel(false);
            setShowRegFilterModel(false);
            setShowUserRoleFilterModel(false);
            setActiveItems({
                LastActive: true,
            });
        }
    };

    // Handle checkbox change
    const handleCheckboxChange = (id) => {
        setSelectedUsers((prevCheckedRows) => {
            if (prevCheckedRows.includes(id)) {
                // If already checked, remove it from the list
                return prevCheckedRows.filter((rowId) => rowId !== id);
            } else {
                // If not checked, add it to the list
                return [...prevCheckedRows, id];
            }
        });
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedUsers(questionList.map((user) => user.user_id));
            setAllSelected(true);
        } else {
            setSelectedUsers([]);
            setAllSelected(false);
        }
    };

    const handleSearchText = (e) => {
        setSearch(e.target.value);
    };

    const onPressEnterKey = (e) => {
        if (e.key === "Enter") {
            setSearchFilterText(search.trim());
        }
    };

    const handleNextPage = () => {
        if (totalPages > page) {
            setPage(page + 1);
        }
    };

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleShortBy = (field) => {
        if (field === shortBy) {
            setShortBy("-" + field);
        } else {
            setShortBy(field);
        }
    };

    const handleResetFilter = () => {
        setPage(1);
        setFilteredDate(null);
        setLastActiveFilterDate(null);
        setFlagReasonFilter(null);
        setSearch("");
        setSearchFilterText("");
        setShortBy("");
        setFilterRoles(null);
        setSelectedUsers([]);
        setAllSelected(false);
        setActiveItems({
            LastActive: false,
            userRole: false,
            flagReason: false,
            registration: false,
        });
    };

    const handleRedirectUserProfile = (id) => {
        navigate(`/user-profile-management`, { state: { user_id: id } });
    };

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    
    const handleCloseResolve = () => setShowResolve(false);
    const handleShowResolve = (item) => {
        setViewFlagQuestion(item)
        setShowResolve(true);
    };

    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleEditClick = (id) => {
        setEditing(id); // Set the option to be edited
    };

    const handleDoneClick = (id) => {
        setEditing(null); // Done editing
    };

    const handleInputChange = (id, event, index) => {
        const newState = {...editFlagQuestion}
        newState.options[index].answer = event.target.value;
        setEditFlagQuestion(newState);
    };
    const handleInputChangeForTwo = (option, event) => {
        const newState = {...editFlagQuestion}
        newState.options[option] = event.target.value;
        setEditFlagQuestion(newState);
    };

    const handleQuestionChange = (e) => {
        setQuestion(e.target.value);
    }

    const getQuestionDetails = async (id) => {
        try {
            const response = await api.getWithToken(
                `${serverURL}admin-panel/flagged-questions/details/${id}/`
            );
            setEditFlagQuestion(response.data.data);
            setSelectedOption(response.data.data.correct_answer)
            setShowEdit(true);
        } catch (error) {
            console.log(error)
        }
    }
    const handleSubmitEdit = async () => {
        try {
            let validate = true
            if([1,3,4].includes(editFlagQuestion.trail_steps)) {
                for(const ques of editFlagQuestion.options) {
                    if(!ques.answer || ques.answer.trim() === '') {
                        validate = false
                        break
                    }
                }
            }

            if(editFlagQuestion.trail_steps === 2) {
                for (const key in editFlagQuestion.options) {
                    if (editFlagQuestion.options[key].trim() === '') {
                        validate = false;
                        break;
                    }
                }
            }

            if(!validate) {
                toast.error("All answers are require")
                return
            }

            const response = await api.putWithToken(
                `${serverURL}admin-panel/flagged-questions/update/${editFlagQuestion.report_id}/`,{
                    question: question.trim() ||  editFlagQuestion.question,
                    options: editFlagQuestion.trail_steps === 3 ? editFlagQuestion.options[0] : editFlagQuestion.options,
                    correct_answer: editFlagQuestion.trail_steps === 2 ? selectedOption : Number(selectedOption)
                }
            );
            if(response.data.success) {
                toast.success(response.data.message)
                setShowEdit(false);
                getUserList();
                setEditFlagQuestion({})
                setQuestion("")
                setSelectedOption("")
                setEditing(null)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleCloseEdit = () => {
        setShowEdit(false);
        setEditFlagQuestion({})
        setQuestion("")
        setSelectedOption("")
        setEditing(null)
    }


    return (
        <AdminLayout>
            {showLoader ? (
                <CommonLoader />
            ) : (
                <>
                    <h2 className="page-title">Flagged Questions</h2>
                    <div className="row mt-5">
                        <div className="col-12 col-md-6">
                            <div className="table-search-input d-flex align-items-center gap-1">
                                <img src="./admin/search-dark.png" alt="" />
                                <input
                                    type="text"
                                    className="form-control border-0"
                                    placeholder="Search by name, or others..."
                                    onChange={handleSearchText}
                                    onKeyDown={onPressEnterKey}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="overflow-auto overflow-y-hidden">
                        <div className="user-management-scroll">
                            <div className="table-top-filter-box mt-4 d-inline-flex">
                                <div className="p-3 border-right-200 d-flex justify-content-center align-items-center">
                                    <img
                                        src="./admin/filter.png"
                                        className="filter-image"
                                        width={29}
                                        height={29}
                                        alt=""
                                    />
                                </div>
                                <div className="p-3 border-right-200 d-flex justify-content-center align-items-center">
                                    <p className="mb-0 fs-14 fw-500">
                                        Filter By
                                    </p>
                                </div>

                                <div className="p-3 border-right-200 d-flex justify-content-center align-items-center filter-items">
                                    <p
                                        className={
                                            activeItems.registration
                                                ? "mb-0 fs-14 fw-500 active text-light-200  d-flex align-items-center gap-3 cursor-pointer"
                                                : "mb-0 fs-14 fw-500  d-flex align-items-center gap-3 cursor-pointer"
                                        }
                                        onClick={() =>
                                            handleToggle("registration")
                                        }
                                    >
                                        Date Range
                                        <img
                                            className={
                                                activeItems.registration
                                                    ? "d-none"
                                                    : "d-block"
                                            }
                                            src="./admin/bottom-arrow.png"
                                            alt=""
                                        />
                                        <img
                                            className={
                                                activeItems.registration
                                                    ? "d-block"
                                                    : "d-none"
                                            }
                                            src="./admin/bottom-arrow-dark.png"
                                            alt=""
                                        />
                                    </p>
                                    {showRegFilterModel && (
                                        <div
                                            className={
                                                activeItems.registration
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <DatePicker
                                                setFilteredDate={
                                                    setFilteredDate
                                                }
                                                type={"registration"}
                                                handleToggle={handleToggle}
                                            />
                                        </div>
                                    )}
                                </div>

                                <div className="p-3 border-right-200 d-flex justify-content-center align-items-center d-flex align-items-center gap-3 select-user-status-main position-relative">
                                    <p
                                        className={
                                            activeItems.flagReason
                                                ? "mb-0 fs-14 fw-500 active text-light-200  d-flex align-items-center gap-3 cursor-pointer"
                                                : "mb-0 fs-14 fw-500  d-flex align-items-center gap-3 cursor-pointer"
                                        }
                                        onClick={() =>
                                            handleToggle("flagReason")
                                        }
                                    >
                                        Flag Reason
                                        <img
                                            className={
                                                activeItems.flagReason
                                                    ? "d-none"
                                                    : "d-block"
                                            }
                                            src="./admin/bottom-arrow.png"
                                            alt=""
                                        />
                                        <img
                                            className={
                                                activeItems.flagReason
                                                    ? "d-block"
                                                    : "d-none"
                                            }
                                            src="./admin/bottom-arrow-dark.png"
                                            alt=""
                                        />
                                    </p>
                                    {showFlagReasonModel && (
                                        <div
                                            className={
                                                activeItems.flagReason
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <SelectFlagReasonModal
                                                setFlagReasonFilter={
                                                    setFlagReasonFilter
                                                }
                                                handleToggle={handleToggle}
                                            />
                                        </div>
                                    )}
                                </div>

                                <div
                                    className="p-4 d-flex align-items-center gap-3 cursor-pointer"
                                    onClick={handleResetFilter}
                                >
                                    <img
                                        src="./admin/reset-filter.png"
                                        alt=""
                                    />
                                    <p className="mb-0 fs-14 fw-500 reset-filter">
                                        Reset Filter
                                    </p>
                                </div>
                            </div>
                            <div className="user-management-table mt-4 overflow-auto">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className="d-flex align-items-center gap-2">
                                                    {/* <div className="manage-family-checkbox">
                                                        <div className="form-check mb-2">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={
                                                                    allSelected
                                                                }
                                                                onChange={
                                                                    handleSelectAll
                                                                }
                                                            />
                                                        </div>
                                                    </div> */}
                                                    <p
                                                        className="mb-0 d-flex align-items-center gap-2 cursor-pointer"
                                                        onClick={() =>
                                                            handleShortBy(
                                                                "report_id"
                                                            )
                                                        }
                                                    >
                                                        ID
                                                        <img
                                                            src="./admin/arrows-down-up.png"
                                                            alt=""
                                                        />
                                                    </p>
                                                </div>
                                            </th>
                                            <th>
                                                <div
                                                    className="d-flex align-items-center gap-2 pb-1 cursor-pointer"
                                                    onClick={() =>
                                                        handleShortBy(
                                                            "created_at"
                                                        )
                                                    }
                                                >
                                                    <p>Date Flagged</p>{" "}
                                                    <img
                                                        src="./admin/arrows-down-up.png"
                                                        alt=""
                                                    />
                                                </div>
                                            </th>
                                            <th>
                                                <div
                                                    className="d-flex align-items-center gap-2 pb-1 cursor-pointer"
                                                    onClick={() =>
                                                        handleShortBy("user")
                                                    }
                                                >
                                                    <p>User Name</p>{" "}
                                                    <img
                                                        src="./admin/arrows-down-up.png"
                                                        alt=""
                                                    />
                                                </div>
                                            </th>
                                            <th>
                                                <div
                                                    className="d-flex align-items-center gap-2 pb-1 cursor-pointer"
                                                    // onClick={() =>
                                                    //     handleShortBy("role")
                                                    // }
                                                >
                                                    <p>Question  </p>{" "}
                                                    {/* <img
                                                        src="./admin/arrows-down-up.png"
                                                        alt=""
                                                    /> */}
                                                </div>
                                            </th>
                                            <th>
                                                <div
                                                    className="d-flex align-items-center gap-2 pb-1 cursor-pointer"
                                                    onClick={() =>
                                                        handleShortBy("issue_type")
                                                    }
                                                >
                                                    <p>Flag Reason  </p>{" "}
                                                    <img
                                                        src="./admin/arrows-down-up.png"
                                                        alt=""
                                                    />
                                                </div>
                                            </th>
                                            <th>
                                                <div
                                                    className="d-flex align-items-center gap-2 pb-1 cursor-pointer"
                                                    onClick={() =>
                                                        handleShortBy("comment")
                                                    }
                                                >
                                                    <p>Comments  </p>{" "}
                                                    <img
                                                        src="./admin/arrows-down-up.png"
                                                        alt=""
                                                    />
                                                </div>
                                            </th>
                                            <th>
                                                <div
                                                    className="d-flex align-items-center gap-2 pb-1 cursor-pointer"
                                                    // onClick={() =>
                                                    //     handleShortBy(
                                                    //         "is_active"
                                                    //     )
                                                    // }
                                                >
                                                    <p>Actions</p>{" "}
                                                    {/* <img
                                                        src="./admin/arrows-down-up.png"
                                                        alt=""
                                                    /> */}
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {questionList &&
                                            questionList.length > 0 &&
                                            questionList?.map((item, index) => (
                                                <tr
                                                    key={index}
                                                    className={
                                                        selectedUsers.includes(
                                                            item.user_id
                                                        )
                                                            ? "active cursor-pointer"
                                                            : "cursor-pointer"
                                                    }
                                                // onClick={() => {
                                                //     handleRedirectUserProfile(
                                                //         item.user_id
                                                //     );
                                                // }}
                                                >
                                                    <td>
                                                        <div className="d-flex align-items-center gap-2">
                                                            {/* <div className="manage-family-checkbox">
                                                                <div className="form-check mb-2">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        defaultValue=""
                                                                        checked={selectedUsers.includes(
                                                                            item.user_id
                                                                        )}
                                                                        onClick={(
                                                                            e
                                                                        ) =>
                                                                            e.stopPropagation()
                                                                        }
                                                                        onChange={() => {
                                                                            handleCheckboxChange(
                                                                                item.user_id
                                                                            );
                                                                        }}
                                                                        id={`checkbox-${item.user_id}`}
                                                                    />
                                                                </div>
                                                            </div> */}
                                                            {item.report_id}
                                                        </div>
                                                    </td>
                                                    <td>{item.date}</td>
                                                    <td className="px-2">
                                                        {item.user_name}
                                                    </td>
                                                    <td className="px-2">
                                                        {item.question}
                                                    </td>
                                                    <td className="px-2">
                                                        {item.issue_type}
                                                    </td>
                                                    <td width={250}>
                                                        {item.comment}
                                                    </td>
                                                    <td className="px-2">
                                                        <div className="d-flex align-items-center gap-3">

                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Review
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <img src="./admin/review.png" onClick={() => { getQuestionDetails(item.report_id); }} alt="" />
                                                            </OverlayTrigger>


                                                            {/* <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Disapprove
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <img src="./admin/disapprove.png"
                                                                    onClick={() => { handleShow(); }} alt="" />
                                                            </OverlayTrigger> */}
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="tooltip-top">
                                                                        Resolve
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <img src="./admin/resolve.png" onClick={() => { handleShowResolve(item); }} alt="" />
                                                            </OverlayTrigger>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </Table>
                            </div>
                            <div className="admin-table-pagination">
                                <p>
                                    Showing {(page - 1) * pageSize + 1}-
                                    {Math.min(page * pageSize, totalUsers)} of{" "}
                                    {totalUsers}
                                </p>
                                <div className="admin-table-pagination-arrows">
                                    <div
                                        className="pagination-arrows-box"
                                        onClick={handlePrevPage}
                                    >
                                        <img
                                            src="./admin/pagination-left-arrow.png"
                                            alt=""
                                        />
                                    </div>
                                    <div
                                        className="pagination-arrows-box"
                                        onClick={handleNextPage}
                                    >
                                        <img
                                            src="./admin/pagination-right-arrow.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {selectedUsers && selectedUsers.length > 0 && (
                        <div className="table-bottom-bottons mt-3">
                            {/* <div key={item.id} className={selectedUsers.includes(item.id) ? 'd-block' : 'd-none'}> */}
                            <div className="d-flex align-items-center gap-3">
                                <SendCommunication user_ids={selectedUsers} />
                                <ExportDataModal user_ids={selectedUsers} />
                                <UpdatePermissions
                                    user_ids={selectedUsers}
                                    roles={roles}
                                    getUserList={getUserList}
                                />
                            </div>
                            {/* </div> */}
                        </div>
                    )}
                </>
            )}



            <Modal
                show={show}
                centered
                size="md"
                className="m-dark-delete-box-main"
                onHide={handleClose}
            >
                <Modal.Header
                    closeButton
                    className="border-0 pb-0"
                ></Modal.Header>
                <Modal.Body>
                    <div className="d-flex align-items-start gap-3">
                        <div className="m-dark-delete-box d-flex justify-content-center align-items-center">
                            <img
                                src="./admin/cross-mark.png"
                                width={25}
                                height={25}
                                alt=""
                            />
                        </div>
                        <div>
                            <h4>
                                Are you sure you want to disapprove?
                            </h4>
                            <p>This Feedback will be disapproved.</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-0 pt-0 d-block">
                    <div className="d-flex align-items-center gap-3">
                        <Button
                            variant="secondary">
                            No, cancel
                        </Button>
                        <Button variant="primary">
                            Yes, confirm
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showEdit}
                centered
                size="lg"
                className="m-dark-delete-box-main"
                onHide={handleCloseEdit}
            >
                <Modal.Header
                    closeButton
                    className="border-0 pb-0"
                ></Modal.Header>
                <Modal.Body className="edit_question_modal">
                    <h4>Edit Question</h4>
                    <h5>Question:</h5>
                    <p className="question text-start mb-3">{editFlagQuestion.question}</p>
                    <h5>Flag Reason:</h5>
                    <p className="question textdark text-start mb-3">{editFlagQuestion.flag_reason}</p>
                    <h5>User’s Comment:</h5>
                    <p className="question textdark text-start mb-3">{editFlagQuestion.user_comment}</p>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <p className="question new_question_lable text-start mb-2">Write New Question</p>
                        <Form.Control as="textarea" placeholder="Write question here..." rows={3}  value={question} onChange={handleQuestionChange}/>
                    </Form.Group>

                    <h4 className="mt-4">Edit Answers</h4>
                    <p className="correct_answer">Select correct answer</p>
                    { [1,3,4].includes(editFlagQuestion.trail_steps) &&
                        <div className="report-radio-group edit_answers d-block my-3 my-md-3">
                            {editFlagQuestion?.options?.map((option, index) => (
                                <div key={index}>
                                    <label className="p-0 w-100 d-flex align-items-center">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="reportOption"
                                            value={option.answer_id}
                                            checked={Number(selectedOption) === option.answer_id}
                                            onChange={handleRadioChange}
                                        />
                                        {option.answer_id}.
                                        <div className="d-flex align-items-center gap-2 w-100">
                                            <div className={`w-100 ${editing === option.answer_id ? '' : 'border-0 w-auto'}`}>
                                                {editing === option.answer_id ? (
                                                    <input
                                                        type="text"
                                                        value={option.answer}
                                                        onChange={(e) => handleInputChange(option.answer_id, e, index)}
                                                        autoFocus
                                                        className="edit_active w-100"
                                                    />
                                                ) : (
                                                    <span>{option.answer}</span>
                                                )}
                                            </div>

                                            {editing === option.answer_id ? (
                                                <img
                                                    src="./admin/input_edit.png"
                                                    width={25}
                                                    alt="Done"
                                                    onClick={() => handleDoneClick(option.answer_id)}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            ) : (
                                                <img
                                                    src="./admin/edit.png"
                                                    width={25}
                                                    alt="Edit"
                                                    onClick={() => handleEditClick(option.answer_id)}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            )}
                                        </div>
                                    </label>
                                </div>
                            ))}
                        </div>
                    }
                    { editFlagQuestion.trail_steps === 2 &&
                        <div className="report-radio-group edit_answers d-block my-3 my-md-3">
                            {Object.keys(editFlagQuestion?.options)?.map((option, index) => (
                                <div key={index}>
                                    <label className="p-0 w-100 d-flex align-items-center">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="reportOption"
                                            value={option}
                                            checked={selectedOption === option}
                                            onChange={handleRadioChange}
                                        />
                                        {option}.
                                        <div className="d-flex align-items-center gap-2 w-100">
                                            <div className={`w-100 ${editing === option ? '' : 'border-0 w-auto'}`}>
                                                {editing === option ? (
                                                    <input
                                                        type="text"
                                                        value={editFlagQuestion?.options[option]}
                                                        onChange={(e) => handleInputChangeForTwo(option, e)}
                                                        autoFocus
                                                        className="edit_active w-100"
                                                    />
                                                ) : (
                                                    <span>{editFlagQuestion?.options[option]}</span>
                                                )}
                                            </div>

                                            {editing === option ? (
                                                <img
                                                    src="./admin/input_edit.png"
                                                    width={25}
                                                    alt="Done"
                                                    onClick={() => handleDoneClick(option)}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            ) : (
                                                <img
                                                    src="./admin/edit.png"
                                                    width={25}
                                                    alt="Edit"
                                                    onClick={() => handleEditClick(option)}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            )}
                                        </div>
                                    </label>
                                </div>
                            ))}
                        </div>
                    }

                </Modal.Body>
                <Modal.Footer className="border-0 pt-0 d-block">
                    <div className="d-flex justify-content-end align-items-center gap-3">
                        <Button
                            onClick={handleCloseEdit}
                            className="w-auto"
                            variant="secondary">
                            Cancel
                        </Button>
                        <Button
                            onClick={handleSubmitEdit}
                            className="w-auto"
                            variant="primary">
                            Resolve
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showResolve}
                centered
                size="lg"
                className="m-dark-delete-box-main"
                onHide={handleCloseResolve}
            >
                <Modal.Header
                    closeButton
                    className="border-0 pb-0"
                ></Modal.Header>
                <Modal.Body className="edit_question_modal">
                    <h4 className="mb-3">Review Question</h4>
                    <h5>Question:</h5>
                    <p className="question text-start mb-3">{viewFlagQuestion.question}</p>
                    <h5>Date:</h5>
                    <p className="question textdark text-start mb-3">{viewFlagQuestion.date}</p>
                    <h5>Flag Reason:</h5>
                    <p className="question textdark text-start mb-3">{viewFlagQuestion.issue_type}</p>
                    <h5>User’s Comment:</h5>
                    <p className="question textdark text-start mb-3">{viewFlagQuestion.comment}</p>
                </Modal.Body>
                {/* <Modal.Footer className="border-0 pt-0 d-block">
                    <div className="d-flex justify-content-end align-items-center gap-3">
                        <Button
                            className="w-auto"
                            variant="secondary">
                            Cancel
                        </Button>
                        <Button
                            className="w-auto"
                            variant="primary">
                            Resolve
                        </Button>
                    </div>
                </Modal.Footer> */}
            </Modal>

        </AdminLayout>

    );
}

export default AdminFlaggedQuestions;
