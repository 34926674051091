import React, { useState } from 'react'
import { Tabs, Tab, Row, Col } from 'react-bootstrap';
import AdminLayout from '../../components/Admin/AdminLayout'
import { Form } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../helper/api';
import { getServerURL } from '../../helper/envConfig';

function Adminsettings() {
     const serverURL = getServerURL()

    // State to track the active tab
    const [activeKey, setActiveKey] = useState('home');
    const [userEmailData, setUserEmailData] = useState({
        email: "",
        name: ""
    })
    const handleSelect = (key) => {
        setActiveKey(key);
    };

    // State to track the dark mode status
    const [isDarkMode, setIsDarkMode] = useState(false);

    // Function to handle the toggle switch
    const handleToggle = () => {
        setIsDarkMode(!isDarkMode);
    };

    const handleUserEmailDataChange = (e) => {
        setUserEmailData({...userEmailData, [e.target.name]: e.target.value });
    }

    const handleSubmitEmailData = async (e) => {
        e.preventDefault();
        if(!userEmailData.name) {
            toast.error('Name field is required')
            return
        }

        if(!userEmailData.email) {
            toast.error('Email field is required')
            return
        }

        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        if (!emailRegex.test(userEmailData.email)) {
            toast.error('Invalid email format')
            return
        }
        try {
            const response = await api.postWithToken(`${serverURL}admin-panel/send-trail-invitation`, userEmailData);
            if(response.data.success) {
                toast.success(response.data.message)
            } else {
                toast.error(response.data.message)
            }
            setUserEmailData({
                email: "",
                name: ""
            })
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <AdminLayout>
            <h2 className="page-title">Settings</h2>
            <div className="row pt-4">
                <div className="col-12">
                    <div className="card border-0 rounded-3">
                        <div className="card-body p-4">
                            <div className='teblas-tabbar setting-tabbar'>
                                <Tabs
                                    id="myTab"
                                    activeKey={activeKey} // Sets the current active tab
                                    onSelect={handleSelect} // Event handler for tab selection
                                    className="mb-3"
                                    variant="pills" // Using 'pills' and custom CSS to underline
                                >
                                    <Tab eventKey="home" title="Pricing Management">
                                        <div id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex={0}>
                                            <div className='pricing-management pt-3'>
                                                <p>Update B2B and B2C pricing</p>

                                                <form action="">
                                                    <div className="row g-4">
                                                        <div className="col-12 col-lg-6">
                                                            <label htmlFor="" className='mb-2'>B2B Pricing</label>
                                                            <input type="text" className='form-control' placeholder='Enter new price' name="" id="" />
                                                            <p>Current price: €3/month</p>
                                                        </div>
                                                        <div className="col-12 col-lg-6">
                                                            <label htmlFor="" className='mb-2'>B2C Pricing</label>
                                                            <input type="text" className='form-control' placeholder='Enter new price' name="" id="" />
                                                            <p>Current price: €3/month</p>
                                                        </div>
                                                    </div>

                                                    <div className='d-flex justify-content-end gap-3 pricing-management-buttons pt-5'>
                                                        <button>Cancel</button>
                                                        <button>Save</button>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </Tab>



                                    <Tab eventKey="profile" title="Maintenance">
                                        <div id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex={0}>
                                            <div className='pricing-management pt-3'>
                                                <p>Configure maintenance windows and notify users</p>

                                                <form action="">
                                                    <div className="row g-4">
                                                        <div className="col-12 col-lg-4">
                                                            <label htmlFor="" className='mb-2'>Start Time</label>
                                                            <input type="date" className='form-control' placeholder='Enter new price' name="" id="" />
                                                            <div className='time-boxs mt-3 d-flex align-items-center gap-3'>
                                                                <select className="form-select" aria-label="Default select example">
                                                                    <option selected>00</option>
                                                                    <option value="1">One</option>
                                                                    <option value="2">Two</option>
                                                                    <option value="3">Three</option>
                                                                </select>
                                                                :
                                                                <select className="form-select" aria-label="Default select example">
                                                                    <option selected>00</option>
                                                                    <option value="1">One</option>
                                                                    <option value="2">Two</option>
                                                                    <option value="3">Three</option>
                                                                </select>
                                                                :
                                                                <select className="form-select" aria-label="Default select example">
                                                                    <option selected>Am</option>
                                                                    <option value="1">PM</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-lg-4">
                                                            <label htmlFor="" className='mb-2'>End Time</label>
                                                            <input type="date" className='form-control' placeholder='Enter new price' name="" id="" />
                                                            <div className='time-boxs mt-3 d-flex align-items-center gap-3'>
                                                                <select className="form-select" aria-label="Default select example">
                                                                    <option selected>00</option>
                                                                    <option value="1">One</option>
                                                                    <option value="2">Two</option>
                                                                    <option value="3">Three</option>
                                                                </select>
                                                                :
                                                                <select className="form-select" aria-label="Default select example">
                                                                    <option selected>00</option>
                                                                    <option value="1">One</option>
                                                                    <option value="2">Two</option>
                                                                    <option value="3">Three</option>
                                                                </select>
                                                                :
                                                                <select className="form-select" aria-label="Default select example">
                                                                    <option selected>Am</option>
                                                                    <option value="1">PM</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className='d-flex align-items-center gap-3 mt-5'>
                                                                <div className={`cont ${isDarkMode ? 'dark-mode' : ''}`}>
                                                                    <div className="toggle">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="mode-toggle"
                                                                            className="toggle__input"
                                                                            checked={isDarkMode}
                                                                            onChange={handleToggle}
                                                                        />
                                                                        <label htmlFor="mode-toggle" className="toggle__label"></label>
                                                                    </div>
                                                                </div>
                                                                <p className='my-0 fw-400 fs-6 pb-2'>Notify users about upcoming maintenance </p>
                                                            </div>
                                                            <div className="col-12 col-lg-6 mt-5">
                                                                <label htmlFor="" className='mb-2'>Notification Message</label>
                                                                <textarea name="" className='form-control rounded-4' id=""></textarea>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className='d-flex justify-content-end gap-3 pricing-management-buttons pt-5'>
                                                        <button>Cancel</button>
                                                        <button>Save</button>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </Tab>

                                    <Tab eventKey="contact" title="Password Policies">
                                        <div id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabIndex={0}>
                                            <div className='pricing-management pt-3'>
                                                <p>Configure password requirements for users</p>
                                                <form action="">
                                                    <div className="row">
                                                        <div className="col-12 col-lg-6">
                                                            <label htmlFor="" className='mb-2'>Minimum Length</label>
                                                            <input type="text" className='form-control' placeholder='Enter length number' name="" id="" />
                                                        </div>
                                                        <div className="col-12">
                                                            <p className=' fw-400 fs-6 mt-4'>Complexity Requirements</p>

                                                            <div className="manage-family-checkbox password-policies">
                                                                <div class="form-check mb-2">
                                                                    <input
                                                                        class="form-check-input"
                                                                        type="checkbox"
                                                                        value=""
                                                                        id="flexCheckChecked1"
                                                                        name="email"
                                                                    />
                                                                    <label
                                                                        class="form-check-label"
                                                                        for="flexCheckChecked1"
                                                                    >
                                                                        Uppercase
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="manage-family-checkbox password-policies">
                                                                <div class="form-check mb-2">
                                                                    <input
                                                                        class="form-check-input"
                                                                        type="checkbox"
                                                                        value=""
                                                                        id="flexCheckChecked2"
                                                                        name="email"
                                                                    />
                                                                    <label
                                                                        class="form-check-label"
                                                                        for="flexCheckChecked2"
                                                                    >
                                                                        Numbers
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="manage-family-checkbox password-policies">
                                                                <div class="form-check mb-2">
                                                                    <input
                                                                        class="form-check-input"
                                                                        type="checkbox"
                                                                        value=""
                                                                        id="flexCheckChecked3"
                                                                        name="email"
                                                                    />
                                                                    <label
                                                                        class="form-check-label"
                                                                        for="flexCheckChecked3"
                                                                    >
                                                                        Special Characters
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-lg-6 mt-5">
                                                                <label htmlFor="" className='mb-2'>Expiration Period (days)</label>
                                                                <input type="text" className='form-control' placeholder='Enter number of days' name="" id="" />

                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className='d-flex justify-content-end gap-3 pricing-management-buttons pt-5'>
                                                        <button>Cancel</button>
                                                        <button>Save</button>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </Tab>

                                    <Tab eventKey="rebate" title="Rebate Codes">
                                        <div id="rebate-tab-pane" role="tabpanel" aria-labelledby="rebate-tab" tabIndex={0}>
                                            <div className='pricing-management pt-3'>
                                                <p>Manage rebate codes for promotions</p>
                                                <form action="">
                                                    <div className="row g-4">
                                                        <p className='text-bl fw-600 fs-17 mb-0 mt-4'>General Codes</p>
                                                        <div className="col-12 col-md-6">
                                                            <label htmlFor="" className='mb-2'>Code name</label>
                                                            <input type="text" className='form-control' placeholder='Code name' name="" id="" />
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-2">
                                                            <label htmlFor="" className='mb-2'>Discount (%)</label>
                                                            <input type="text" className='form-control' placeholder='Discount (%)' name="" id="" />
                                                        </div>
                                                    </div>
                                                    <div className="row g-4">
                                                        <p className='text-bl fw-600 fs-17 mb-0 mt-5'>Specific Codes</p>
                                                        <div className="col-12 col-md-6">
                                                            <label htmlFor="" className='mb-2'>Code name</label>
                                                            <input type="text" className='form-control' placeholder='Code name' name="" id="" />
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-2">
                                                            <label htmlFor="" className='mb-2'>Usage Limit</label>
                                                            <input type="text" className='form-control' placeholder='Usage Limit' name="" id="" />
                                                        </div>
                                                    </div>

                                                    <div className='d-flex justify-content-end gap-3 pricing-management-buttons pt-5'>
                                                        <button>Cancel</button>
                                                        <button>Save</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </Tab>

                                    <Tab eventKey="notifications" title="Notifications">
                                        <div id="notifications-tab-pane" role="tabpanel" aria-labelledby="notifications-tab" tabIndex={0}>
                                            <div className='pricing-management pt-3'>
                                                <p>Configure email notifications for users</p>
                                                <form action="">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <p className='text-bl fw-600 fs-17'>General Codes</p>

                                                            <div className="manage-family-checkbox password-policies mb-4">
                                                                <div class="form-check mb-2">
                                                                    <input
                                                                        class="form-check-input"
                                                                        type="checkbox"
                                                                        value=""
                                                                        id="flexCheckChecked4"
                                                                        name="email"
                                                                    />
                                                                    <label
                                                                        class="form-check-label"
                                                                        for="flexCheckChecked4"
                                                                    >
                                                                        Notification Message
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <p className='text-bl fw-600 fs-17'>Reminders after 1 year</p>
                                                            <div className="manage-family-checkbox password-policies">
                                                                <div class="form-check mb-2">
                                                                    <input
                                                                        class="form-check-input"
                                                                        type="checkbox"
                                                                        value=""
                                                                        id="flexCheckChecked5"
                                                                        name="email"
                                                                    />
                                                                    <label
                                                                        class="form-check-label"
                                                                        for="flexCheckChecked5"
                                                                    >
                                                                        Reminder Message
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='d-flex justify-content-end gap-3 pricing-management-buttons pt-5'>
                                                        <button>Cancel</button>
                                                        <button>Save</button>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </Tab>

                                    <Tab eventKey="Integrations" title="Integrations">
                                        <div id="integrations-tab-pane" role="tabpanel" aria-labelledby="integrations-tab" tabIndex={0}>
                                            <div className='pricing-management pt-3'>
                                                <p>Configure integrations with major HR systems</p>
                                                <form action="">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <p className='text-bl fw-600 fs-17'>HR Systems</p>

                                                            <div className="manage-family-checkbox password-policies">
                                                                <div class="form-check mb-2">
                                                                    <input
                                                                        class="form-check-input"
                                                                        type="checkbox"
                                                                        value=""
                                                                        id="flexCheckChecked4"
                                                                        name="email"
                                                                    />
                                                                    <label
                                                                        class="form-check-label"
                                                                        for="flexCheckChecked4"
                                                                    >
                                                                        Personio
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="manage-family-checkbox password-policies">
                                                                <div class="form-check mb-2">
                                                                    <input
                                                                        class="form-check-input"
                                                                        type="checkbox"
                                                                        value=""
                                                                        id="flexCheckChecked5"
                                                                        name="email"
                                                                    />
                                                                    <label
                                                                        class="form-check-label"
                                                                        for="flexCheckChecked5"
                                                                    >
                                                                        BambooHR
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="manage-family-checkbox password-policies">
                                                                <div class="form-check mb-2">
                                                                    <input
                                                                        class="form-check-input"
                                                                        type="checkbox"
                                                                        value=""
                                                                        id="flexCheckChecked6"
                                                                        name="email"
                                                                    />
                                                                    <label
                                                                        class="form-check-label"
                                                                        for="flexCheckChecked6"
                                                                    >
                                                                        Microsoft Active Directory
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='d-flex justify-content-end gap-3 pricing-management-buttons pt-5'>
                                                        <button>Cancel</button>
                                                        <button>Save</button>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="Email" title="Email">
                                        <div id="integrations-tab-pane" role="tabpanel" aria-labelledby="integrations-tab" tabIndex={0}>
                                            <div className='pricing-management'>
                                                <form action="">
                                                    <div className='row g-4'>
                                                        <div className="col-12 col-lg-6">
                                                            <label htmlFor="" className='mb-2'>Name</label>
                                                            <input
                                                                type="text"
                                                                className='form-control'
                                                                placeholder='Enter name'
                                                                name="name"
                                                                value={userEmailData.name}
                                                                onChange={handleUserEmailDataChange} />
                                                        </div>
                                                        <div className="col-12 col-lg-6">
                                                            <label htmlFor="" className='mb-2'>Email</label>
                                                            <input type="text"
                                                                className='form-control'
                                                                placeholder='Enter email'
                                                                name="email"
                                                                value={userEmailData.email}
                                                                onChange={handleUserEmailDataChange} />
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-end gap-3 pricing-management-buttons pt-5'>
                                                        <button>Clear</button>
                                                        <button onClick={handleSubmitEmailData}>Send</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}

export default Adminsettings 
