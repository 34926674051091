import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';

function AdminSidebar() {

    const location = useLocation()
    const navigate = useNavigate()
    // Step 1: Define your data
    const items = [
        { id: 1, src: './admin/feedback.png', text: 'Feedback', to: '/admin-feedback' },
        { id: 2, src: './admin/fluent_flag.png', text: 'Flagged Questions', to: '/admin-flagged-questions' },
        { id: 3, src: './admin/grades.png', text: 'Grades ', to: '/admin-grades' },
        { id: 4, src: './admin/performance.png', text: 'Performance Scale', to: '/admin-performance-scale' },
        { id: 5, src: './admin/empty.png', text: 'System Status Updates', to: '/admin-system' },
        { id: 6, src: './admin/support.png', text: 'Support', to: '' },
        { id: 7, src: './admin/documentation.png', text: 'Documentation', to: '/admin-documentation' },
        // Add more items as needed
    ];

    const [isSidebarActive, setIsSidebarActive] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarActive(prevState => !prevState);
    };

    useEffect(() => {
        const checkScreenSize = () => {
            if (window.innerWidth <= 768) {
                setIsSidebarActive(true);
            } else {
                setIsSidebarActive(false);
            }
        };

        // Add event listener for window resize
        window.addEventListener('resize', checkScreenSize);

        // Initial check
        checkScreenSize();

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', checkScreenSize);
        };
    }, []);



    const [activeId, setActiveId] = useState(location.pathname);

    const handleClick = (id) => {
        setActiveId(id);
    };

    const handleItemClick = (id) => {
        // Toggle the active class
        // setActiveItemId(id);
        navigate(id)
    };


    return (
        <>
            <div className={`adminsidebar ${isSidebarActive ? 'active' : ''}`}>
                <div className='d-flex justify-content-between align-items-center gap-3 mb-4 mb-md-5'>
                    <img src="./admin/logo.png" className='logo-xl' alt="" />
                    <img src="./admin/logo-sm.png" className='logo-sm d-none' alt="" />
                    <img
                        src="./admin/toggle.png"
                        alt="Toggle Sidebar"
                        onClick={toggleSidebar}
                        className='cursor-pointer side_toggel'
                    />
                </div>

                {items.map(item => (
                    <Link
                        to={item.to}
                        key={item.id}
                        className={`d-flex align-items-center gap-3 sidebar_list ${activeId === item.to ? 'active' : ''}`}
                        onClick={() => handleItemClick(item.to)}
                    >
                        <img src={item.src} alt={item.text} />
                        <p to={item.to}>{item.text}</p>
                    </Link>
                ))}
            </div>
        </>
    )
}

export default AdminSidebar