import React, { useContext, useEffect, useState } from 'react'
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import "../../styles/newbusiness.css"
import { Link, useNavigate } from 'react-router-dom';
import { Button, Modal, ProgressBar } from 'react-bootstrap';
import PointsHeader from '../../components/NewBusiness/PointsHeader';
import ScoreProgressBar from '../../components/NewBusiness/ScoreProgressBar';
import { CourseContext } from '../../context/CourseContext';
import { getServerURL } from '../../helper/envConfig';
import api from '../../helper/api';
import { toast } from 'react-toastify';


const BusinessDataPrivacyTrailResults = () => {
    const serverURL = getServerURL();
    const navigate = useNavigate()
    const { user, getTrailsTotalPoint, pointsData } = useContext(CourseContext);
    const [caseStudies, setCaseStudies] = useState([]);
    const [result, setResult] = useState();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [submitted, setSubmitted] = useState(false);
    const[steps, setSteps] = useState({})
    const [text, setText] = useState("")
    const color = ["#002FA7", "#FDC93A","#D2E23D", "#FD8AA8"]
    const paths = ["/data-privacy/case-study", "/data-privacy/course","/data-privacy/puzzle", "/data-privacy/gamified-quiz"]



    const getSteps = async () => {
        try {
            const response = await api.getWithToken(
                `${serverURL}trails/steps/`
            );
            setCaseStudies(response.data.data);
        } catch (error) {
            console.log(error);
        }
    };
    const getResults = async () => {
        try {
            const response = await api.getWithToken(
                `${serverURL}trails/step-progress/`
            );
            if(response.data.success) {
                setResult(response.data.data);
                setShow(!response.data.data.is_feedback_exist)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getProgressStatus = async () => {
            try {
                const response = await api.getWithToken(
                    `${serverURL}trails/step-status/`
                );
                setSteps(response.data.data);
            } catch (error) {
                console.log(error);
            }
        };

    useEffect(() => {
        getSteps();
        getResults();
        getTrailsTotalPoint()
        getProgressStatus()
        sessionStorage.clear()
    }, []);

    const handleSubmit = async () => {
        if(!text) {
            toast.error("Please enter feedback!")
            return;
        }
        try {
            const response = await api.postWithToken(
                `${serverURL}trails/feedback/`, {feedback: text}
            );
             if(response.data.success) {
                toast.success("Feedback submitted successfully!")
                // setShow(false)
                setText("")
                setSubmitted(true);
             }
        } catch (error) {
            console.log(error);
        }
    };

     const handleNavigate = (path) => {
        navigate(path)
     }

    return (
        <>
            <Header />
            <main className='business_data_privacy_trail'>
                <section>
                    <PointsHeader to="/data-privacy/points" />
                    <div className="trail_progress">
                        <div className="container">
                            <div className="row align-items-center g-4">
                                <div className="col-12 col-md-7 order-2 order-md-1">
                                    <span className='badge'>Hi {user?.username},</span>
                                    <p>Congratulations! You have successfully completed the Data Privacy trail</p>
                                    <h3>Data Privacy Trail - Interactive Learning</h3>
                                    <Link to="/data-privacy/case-study"
                                        className="btn btn-primary d-inline-flex align-items-center gap-3"
                                    >
                                        Try It Again
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={22}
                                            height={14}
                                            viewBox="0 0 22 14"
                                            fill="none"
                                        >
                                            <path
                                                d="M1 6.99854H21L16 1"
                                                stroke="#fff"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M21 6.99854L16 13"
                                                stroke="#fff"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                            />
                                        </svg>
                                    </Link>
                                    <div className='d-flex gap-3 gap-xxl-4 align-items-start mt-4 w-100 pt-0 pt-lg-5 score_progress_bar'>
                                        <div className='overall_score_progress'>
                                            <p className='pt-0'>Your overall score</p>
                                            <ScoreProgressBar key={result?.average_score || 0} fontSize="36px" color="#002FA7" percentage={result?.average_score ? result.average_score : 0} width={150} height={150} size={150} strokeWidth={15} />
                                        </div>
                                        <div className='w-100 ps-xxl-5'>
                                            <h4 className='pt-0'>Trail Progress</h4>
                                            <h5>{steps?.total_complete_step}/{steps?.total_step} Completed</h5>
                                            <ProgressBar now={
                                                (steps?.total_complete_step /
                                                    steps?.total_step) *
                                                100
                                            } />
                                            <h4 className='pt-0 my-4'>Points Earned:</h4>
                                            <div className='business_data_privacy_trail score_progress_bar'>
                                                <button className="award_btn fw-700 border-0 d-flex align-items-center gap-2">
                                                    <img src="/new-business/award-icons.webp" width="24" alt="" />{pointsData?.total_point_earned}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-5 d-flex justify-content-end order-1 order-md-2">
                                    <img src="/new-business/discussion-cuate.webp" className='pt-md-5' alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='data_privacy_overview py-5'>
                    <div className="container">
                        <div className='d-md-flex justify-content-between align-items-center pb-4 pb-lg-5'>
                            <h2>Data Privacy Trail Overview </h2>
                        </div>
                        <div className="row g-4">
                            {caseStudies.map((caseStudy, index) => (
                                <div className="col-12" key={index}>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12 col-md-7">
                                                    <div className="d-flex align-items-start align-content-md-center gap-4 flex-column flex-md-row">
                                                        <img src={caseStudy.icon} alt={caseStudy.title} />
                                                        <div>
                                                            <h4>{caseStudy.name}</h4>
                                                            <p className="mb-2">{caseStudy.description}</p>
                                                            <div className="d-flex align-items-center gap-3">
                                                                <p className="mb-0 text_light_200 fs-14 fw-600">
                                                                    Points Earned:
                                                                </p>
                                                                <div className="business_data_privacy_trail">
                                                                    {caseStudy.title !== 'Puzzle' && (
                                                                        <button className="award_btn fw-700 border-0 d-flex align-items-center gap-2">
                                                                            <img src="/new-business/award-icons.webp" width={24} alt="" />
                                                                            {result?.steps[index]?.points_earned}
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-5 d-md-flex justify-content-md-end">
                                                    <div className='d-flex justify-content-between justify-content-md-end align-items-center gap-5 mt-4 mt-md-0'>
                                                        {result?.steps?.length > 0 && <ScoreProgressBar key={index} fontSize="22px" color={color[index]} percentage={result?.steps[index]?.score} width={100} height={100} size={150} strokeWidth={15} />}
                                                        <button
                                                            className="btn btn-primary bg-secondary fw-600"
                                                             onClick={()=> handleNavigate(paths[index])}
                                                        >
                                                            Replay
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                {/* <section className='business_quiz_results'>
                    <div className="container">
                        <div className="row justify-content-center pb-5">
                            <div className="col-12">
                                <div className="card drag_drop_cards mt-0">
                                    <div className='certificate_text text-center py-5'>
                                        <p>Join the learning adventure</p>
                                        <h4>You want a certificate? It is free.
                                            You just need to sign up.</h4>
                                        <h5>Creating an account is quick, easy, and completely free!</h5>
                                        <h6>Do you want a certificate that you can show to your employer and show on LinkedIn for example?</h6>
                                        <Link to="" className='d-flex justify-content-between align-items-center' onClick={handleShow}>
                                            Sign Up
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={22}
                                                height={14}
                                                viewBox="0 0 22 14"
                                                fill="none"
                                            >
                                                <path
                                                    d="M1 6.99854H21L16 1"
                                                    stroke="#000C2A"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M21 6.99805L16 12.9995"
                                                    stroke="#000C2A"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                />
                                            </svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}


                <Modal centered size="md" className="feedback_modal" show={show} onHide={handleClose}>
                    <Modal.Header className="border-0 pb-0 adjust-modal" closeButton />
                    <Modal.Body className="pt-0">
                        {!submitted ? (
                            <div className="feedback-step-1">
                                <h3>Tell us how this session helped you or where we can improve</h3>
                                <h6>Write Your Comment</h6>
                                <textarea
                                    className="form-control pt-3 bg-secondary fw-400"
                                    rows={6}
                                    placeholder="Type your feedback here..."
                                    id="floatingTextarea2"
                                    value={text}
                                    onChange={(e) => setText(e.target.value)}
                                />
                            </div>
                        ) : (
                            <div className="feedback-step-2">
                                <div className="text-center">
                                    <img className="mx-auto d-flex" src="/new-business/feedback.webp" alt="" />
                                    <h4>Thank you for your feedback!</h4>
                                    <h5>Your insights help us create a better learning experience for you.</h5>
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer className="border-0">
                        {!submitted ? (
                            <Button variant="primary" onClick={handleSubmit}>
                                Submit Feedback
                            </Button>
                        ) : (
                            ""
                        )}
                    </Modal.Footer>
                </Modal>


            </main>
            <Footer />
        </>
    )
}

export default BusinessDataPrivacyTrailResults
