import React, { useEffect, useState } from "react";
import DoughnutChart from "../../components/DoughnutChart";
import AreaChart from "../../components/AreaChart";
import ColumnChart from "../../components/ColumnChart";
import LineChart from "../../components/LineChart";
import AdminLayout from "../../components/Admin/AdminLayout";
import api from "../../helper/api";
import { getServerURL } from "../../helper/envConfig";
import CommonLoader from "../../components/Admin/CommonLoader";
function AdminDashboard() {
    const serverURL = getServerURL();
    const [showLoader, setShowLoader] = useState(true);
    const [cardsData, setCardsData] = useState({
        total_active_courses: 0,
        total_recent_uplaod: 0,
        total_sign_up: 0,
        total_active_user: 0,
    });
    const [recentUploadData, setRecentUploadData] = useState([]);
    const [monthlyUserSignUpData, setMonthlyUserSignUpData] = useState({});
    const [courseCompletionRates, setCourseCompletionRates] = useState({
        months: [],
        compliance:[],
        skills: [],
        fun: []
    })
    const [label, setLabel] = useState([])

    const getDashboardData = async () => {
        try {
            const response = await api.getWithToken(
                `${serverURL}admin-panel/dashboard/`
            );
            setCardsData(response?.data?.data?.cards);
            setRecentUploadData(response?.data?.data?.recent_uploads);
            setMonthlyUserSignUpData(
                response.data.data.registered_users_month_wise
            );

             const obj = {
                months: [],
                compliance:[],
                skills: [],
                fun: []
             }
            for(const data of response?.data?.data?.course_completion_rates) {
                obj.months.push(data.month)
                obj.compliance.push(data.rate.Compliance)
                obj.skills.push(data.rate.Skills)
                obj.fun.push(data.rate["Fun/General Knowledge/Miscellaneous"])
            }
            setCourseCompletionRates(obj)
            setLabel(response?.data?.data?.main_categories)
            setShowLoader(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getDashboardData();
    }, []);

    const coursesData = [
        {
            id: 1,
            imgSrc: "./admin/dashboard-1.png",
            title: "Number of Active Courses",
            count: cardsData.total_active_courses,
        },
        {
            id: 2,
            imgSrc: "./admin/dashboard-2.png",
            title: "Recent Uploads",
            count: cardsData.total_recent_uplaod,
        },
        {
            id: 3,
            imgSrc: "./admin/dashboard-3.png",
            title: "Total Sign Up",
            count: cardsData.total_sign_up,
        },
        {
            id: 4,
            imgSrc: "./admin/dashboard-3.png",
            title: "Active User Counts",
            count: cardsData.total_active_user,
        },
        // Add more items as needed
    ];

    const getPercentage = (value, totalValue) => {
        return Math.round((value / totalValue) * 100);
    };


    return (
        <AdminLayout>
            {showLoader ? (
                <CommonLoader />
            ) : (
                <>
                    <h2 className="page-title">Dashboard</h2>
                    <div className="row dashboard-cards pt-4 g-3 g-xl-4">
                        {coursesData.map((course) => (
                            <div
                                className="col-12 col-md-6 col-lg-3"
                                key={course.id}
                            >
                                <div className="card h-100">
                                    <div className="card-body pb-0">
                                        <img
                                            src={course.imgSrc}
                                            width={30}
                                            height={30}
                                            alt=""
                                        />
                                        <p>{course.title}</p>
                                    </div>
                                    <div className="card-footer bg-transparent border-0">
                                        <h2>{course.count}</h2>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="row align-items-start g-4 mt-4">
                        <div className="col-12 col-lg-12 col-xl-7">
                            <div className="card border-0">
                                <div className="card-body p-4">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <p className="text-black fw-600 fs-18 mb-4">
                                            Course Completion Rates
                                        </p>
                                    </div>
                                    <ColumnChart data={courseCompletionRates} label={label} />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-5">
                            <div className="card border-0">
                                <div className="card-body p-4">
                                    <div className="d-md-flex justify-content-between align-items-start">
                                        <div>
                                            <p className="text-black fw-600 fs-18 mb-4">
                                                Recent Uploads
                                            </p>
                                            <div className="d-flex align-items-start gap-2">
                                                <h4 className="text-black fw-400 mb-0">
                                                    {
                                                        cardsData?.total_recent_uplaod
                                                    }
                                                </h4>
                                                <span className="text-black fs-6 fw-400 mb-0">
                                                    courses
                                                </span>
                                            </div>
                                            {/* <p className="text-muted fs-7 fw-400">of 20,050</p> */}
                                        </div>
                                        <div className="chart-demo d-flex d-md-block justify-content-center">
                                            <DoughnutChart
                                                height={250}
                                                width={250}
                                                labels={[
                                                    recentUploadData[1]?.name,
                                                    recentUploadData[0]?.name,
                                                    recentUploadData[2]?.name,
                                                ]}
                                                data={[
                                                    recentUploadData[1]
                                                        ?.recent_courses_count,
                                                    recentUploadData[0]
                                                        ?.recent_courses_count,
                                                    recentUploadData[2]
                                                        ?.recent_courses_count,
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    <div className="progress-main-2 recent-uploads-cart mt-4">
                                        {recentUploadData.map((item, index) => (
                                            <div key={index}>
                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <p className="text-muted fw-400 fs-7 mb-0">
                                                        {item.name}
                                                    </p>
                                                    <p className="text-muted fw-400 fs-7 mb-0">
                                                        {
                                                            item.recent_courses_count
                                                        }{" "}
                                                        {item.recent_courses_count >
                                                        1
                                                            ? "courses"
                                                            : "course"}
                                                    </p>
                                                </div>
                                                <div
                                                    className="progress progress-height01 mb-4"
                                                    role="progressbar"
                                                    aria-label="Basic example"
                                                    aria-valuenow={0}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                >
                                                    <div
                                                        className={`progress-bar progress_${index}`}
                                                        style={{
                                                            width: `${getPercentage(
                                                                item.recent_courses_count,
                                                                cardsData?.total_recent_uplaod
                                                            )}%`,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-5">
                            <div className="card border-0 mt-xl-4">
                                <div className="card-body p-4">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <p className="text-black fw-600 fs-18 mb-4">
                                            Monthly Sign Ups
                                        </p>
                                    </div>
                                    <div className="overflow-auto">
                                        <div className="areachart">
                                            <AreaChart
                                                labels={
                                                    Object.keys(
                                                        monthlyUserSignUpData
                                                    ).length > 0
                                                        ? Object.keys(
                                                              monthlyUserSignUpData
                                                          )
                                                        : []
                                                }
                                                values={
                                                    Object.values(
                                                        monthlyUserSignUpData
                                                    ).length > 0
                                                        ? Object.values(
                                                              monthlyUserSignUpData
                                                          )
                                                        : []
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-12 col-xl-7">
                            <div className="card border-0 mt-xl-4">
                                <div className="card-body p-4">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <p className="text-black fw-600 fs-18 mb-4">
                                            Total Revenue
                                        </p>
                                    </div>
                                    <LineChart />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </AdminLayout>
    );
}

export default AdminDashboard;
