import React, { useRef, useState, useEffect, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { CourseContext } from '../context/CourseContext';
import { useNavigate } from 'react-router-dom';
import { decryptFromStoreLoc, encryptAndStoreLoc } from '../helper/localStore';
import SignIn from './../components/login/SignIn';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Accordion } from 'react-bootstrap';
import api from '../helper/api';
import { getImageBaseUrl, getServerURL } from '../helper/envConfig';
import { debounce } from 'lodash'; // Ensure lodash is installed
import { DropdownSkeleton } from '../components/SkeletonLayouts';

const Header = () => {

    const { paymentStatus, loder, setLoder, getCategorys, categories, token, setToken, user, setUser, search, setSearch, getCourses, searchex, setSearchex, setIs_search } = useContext(CourseContext);

    const history = useNavigate()
    const [show, setShow] = useState(false);
    // const [loder, setLoder] = useState(false);

    //  console.log("paymentStatus",paymentStatus)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showDropdown, setShowDropdown] = useState(false);
    // const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState({});
    // const [loading, setLoading] = useState({});
    const serverURL = getServerURL();

    const location = useLocation();
    const isHome = location.pathname === '/';

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        setSearchex("")
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevents the default action of the enter key in a form
            getCourses(); // Call the function you want to execute on Enter key press
            setIs_search(true)
            history("/courses-explore")
            window.scrollTo(0, 500);
            handleClose()
        }
    };

    const handlePress = (e) => {
        e.preventDefault(); // Prevents the default action of the enter key in a form
        getCourses(); // Call the function you want to execute on Enter key press
        setIs_search(true)
        history("/courses-explore")
        window.scrollTo(0, 500);
        handleClose()
    };

    // useEffect(() => {
    //     const decryptedInfoResult = decryptFromStoreLoc('user');
    //     setUser(decryptedInfoResult)
    //     const mytoken = localStorage.getItem('token');
    //     setToken(mytoken)
    // }, []);

    const getInitials = (username) => {
        let initials = username?.split(' ')?.map(word => word[0])?.join('');
        return initials?.toUpperCase();
    };

    const toggleDropdown = () => {
        // if (user.main_role == 1) {
        //     history("/business-home")
        // } else if (user.main_role == 2) {
        //     history("/customer-home")
        // }

        // check payment
        if(user?.role === 12) {
             if(!paymentStatus) {
                history("/business-free-environment")
                return;
            }
        }
        if(user?.role === 13) {
            if(!paymentStatus) {
                history("/family-user-plan")
                return;
            }
        }
        if(user?.role === 2) {
            if(!paymentStatus) {
                history("/subscription-plans")
                return;
            }
        }

        if(user?.role === 14) {
            history("/admin-dashboard")
        }

        if (user?.is_main_role && user?.main_role && user?.main_role !== undefined && user?.is_main_role) {
            if (user?.main_role === 1) {
                history("/business-home")
            } else if (user?.main_role === 2) {
                history("/customer-home")
            }else if (user?.main_role == 7) {
                history("/business-family")
            }

        } else {
            if (user?.role == 1 || user?.role == 12 || user?.role == 10 || user?.role == 8 || user?.role == 9 ) {
                history("/business-home")
            } else if (user?.role == 2) {
                history("/customer-home")
            } else if (user?.role == 6 || user?.role == 11 || user?.role == 13 ) {
                history("/business-family")
            }

        }

    };

    const handleLogout = () => {
        // Your logout logic here
        sessionStorage.clear();
        localStorage.clear();
        history("/")
    };



    useEffect(() => {
        getCategorys()
    }, []);


    return (
        <div>
            <header>
                <div className={`header_main ${isHome ? '' : 'header-bg'}`}>
                    <div className="container">
                        <nav className="navbar navbar-expand-xl">
                            <div className="d-flex justify-content-between align-items-center w-100">
                                <div className="d-flex align-items-center">
                                    <Link to="/" className="navbar-brand header-logo home-logo"
                                    >
                                        <img src="../logo/logo.jpg" alt="" />
                                    </Link>
                                    <Link to="/" className="navbar-brand header-logo white-logo d-none"
                                    >
                                        <img src="../logo/white-logo.svg" alt="" />
                                    </Link>
                                    <div className="collapse navbar-collapse">
                                        <ul className="navbar-nav gap-3">
                                            <li className="nav-item">
                                                <Link className={`nav-link ${location.pathname === '/courses' ? 'active' : ''}`} to="/courses">
                                                    Courses
                                                </Link>
                                            </li>
                                            {/* <li className="nav-item">
                                                <Link className={`nav-link ${location.pathname === '/category' ? 'active' : ''}`} to="/category">
                                                    Categories
                                                </Link>
                                            </li> */}

                                            <NavDropdown
                                                // onClick={getCategorys} 
                                                title="Categories" className={`nav-link category-drop-main py-0  ${location.pathname.startsWith('/courses-category') ? 'active' : ''}`} to="/category">

                                                {/* 
                                            <li className="nav-item">
                                                <Link className={`nav-link ${location.pathname === '/category' ? 'active' : ''}`} to="/category">
                                                    Categories
                                                </Link>
                                            </li> */}

                                                {
                                                    loder ? (
                                                        <DropdownSkeleton /> // Use the DropdownSkeleton when data is loading
                                                    ) : (

                                                        categories?.length && categories.map((category, index) => (
                                                            <NavDropdown.Item key={index} to="" className='p-0 position-relative'>
                                                                <div className='row align-items-center p-0 m-0'>
                                                                    <div className="col-10 text-wrap">
                                                                        {category?.name}
                                                                    </div>
                                                                    <div className="col-2">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                            <path d="M8.21499 17.9039C8.14631 17.966 8.09263 18.0389 8.05699 18.1186C8.02135 18.1984 8.00447 18.2833 8.00729 18.3686C8.01012 18.4539 8.03261 18.5379 8.07347 18.6158C8.11433 18.6936 8.17276 18.7639 8.24543 18.8225C8.31811 18.8811 8.40359 18.9269 8.49701 18.9573C8.59043 18.9877 8.68996 19.0022 8.78991 18.9997C8.88985 18.9973 8.98826 18.9781 9.07952 18.9433C9.17078 18.9084 9.25309 18.8585 9.32177 18.7965L15.7919 12.9499C15.9256 12.8293 16 12.6696 16 12.5037C16 12.3377 15.9256 12.178 15.7919 12.0574L9.32177 6.21014C9.25355 6.14677 9.17125 6.09559 9.07966 6.05958C8.98806 6.02358 8.889 6.00347 8.78822 6.00041C8.68745 5.99735 8.58696 6.01142 8.49261 6.04178C8.39825 6.07215 8.31191 6.11821 8.23859 6.1773C8.16527 6.23639 8.10644 6.30732 8.06551 6.38597C8.02458 6.46462 8.00238 6.54943 8.00018 6.63547C7.99799 6.72151 8.01584 6.80707 8.05272 6.88717C8.08959 6.96727 8.14475 7.04032 8.21499 7.10207L14.1919 12.5037L8.21499 17.9039Z" fill="#000C2A" />
                                                                        </svg>
                                                                    </div>
                                                                    <ul className='sub-category-list d-none '>
                                                                        {
                                                                            category.subcategories?.length && category?.subcategories.map((subcategory, index) => (
                                                                                <li key={index}>
                                                                                    <Link
                                                                                        // onClick={() => storeSubCategorys(subcategory.slug)}

                                                                                        to={`/courses-category/${subcategory.slug}`} className='text-black '>
                                                                                        <div className='row align-items-center p-0 m-0 '>
                                                                                            <div className="col-10 ">
                                                                                                {subcategory.name}
                                                                                            </div>
                                                                                            {/* <div className="col-2">
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                                    <path d="M8.21499 17.9039C8.14631 17.966 8.09263 18.0389 8.05699 18.1186C8.02135 18.1984 8.00447 18.2833 8.00729 18.3686C8.01012 18.4539 8.03261 18.5379 8.07347 18.6158C8.11433 18.6936 8.17276 18.7639 8.24543 18.8225C8.31811 18.8811 8.40359 18.9269 8.49701 18.9573C8.59043 18.9877 8.68996 19.0022 8.78991 18.9997C8.88985 18.9973 8.98826 18.9781 9.07952 18.9433C9.17078 18.9084 9.25309 18.8585 9.32177 18.7965L15.7919 12.9499C15.9256 12.8293 16 12.6696 16 12.5037C16 12.3377 15.9256 12.178 15.7919 12.0574L9.32177 6.21014C9.25355 6.14677 9.17125 6.09559 9.07966 6.05958C8.98806 6.02358 8.889 6.00347 8.78822 6.00041C8.68745 5.99735 8.58696 6.01142 8.49261 6.04178C8.39825 6.07215 8.31191 6.11821 8.23859 6.1773C8.16527 6.23639 8.10644 6.30732 8.06551 6.38597C8.02458 6.46462 8.00238 6.54943 8.00018 6.63547C7.99799 6.72151 8.01584 6.80707 8.05272 6.88717C8.08959 6.96727 8.14475 7.04032 8.21499 7.10207L14.1919 12.5037L8.21499 17.9039Z" fill="#000C2A" />
                                                                                                </svg>
                                                                                            </div> */}
                                                                                        </div>
                                                                                    </Link>
                                                                                </li>
                                                                            ))}
                                                                        {/* <li>
                                                                    <Link to="/courses-category" className='text-black'>
                                                                        <div className='row align-items-center p-0 m-0'>
                                                                            <div className="col-10">
                                                                                Data Protection Compliance
                                                                            </div>
                                                                            <div className="col-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                    <path d="M8.21499 17.9039C8.14631 17.966 8.09263 18.0389 8.05699 18.1186C8.02135 18.1984 8.00447 18.2833 8.00729 18.3686C8.01012 18.4539 8.03261 18.5379 8.07347 18.6158C8.11433 18.6936 8.17276 18.7639 8.24543 18.8225C8.31811 18.8811 8.40359 18.9269 8.49701 18.9573C8.59043 18.9877 8.68996 19.0022 8.78991 18.9997C8.88985 18.9973 8.98826 18.9781 9.07952 18.9433C9.17078 18.9084 9.25309 18.8585 9.32177 18.7965L15.7919 12.9499C15.9256 12.8293 16 12.6696 16 12.5037C16 12.3377 15.9256 12.178 15.7919 12.0574L9.32177 6.21014C9.25355 6.14677 9.17125 6.09559 9.07966 6.05958C8.98806 6.02358 8.889 6.00347 8.78822 6.00041C8.68745 5.99735 8.58696 6.01142 8.49261 6.04178C8.39825 6.07215 8.31191 6.11821 8.23859 6.1773C8.16527 6.23639 8.10644 6.30732 8.06551 6.38597C8.02458 6.46462 8.00238 6.54943 8.00018 6.63547C7.99799 6.72151 8.01584 6.80707 8.05272 6.88717C8.08959 6.96727 8.14475 7.04032 8.21499 7.10207L14.1919 12.5037L8.21499 17.9039Z" fill="#000C2A" />
                                                                                </svg>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/courses-category" className='text-black'>

                                                                        <div className='row align-items-center p-0 m-0'>
                                                                            <div className="col-10">
                                                                                Other Compliance and Law
                                                                            </div>
                                                                            <div className="col-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                    <path d="M8.21499 17.9039C8.14631 17.966 8.09263 18.0389 8.05699 18.1186C8.02135 18.1984 8.00447 18.2833 8.00729 18.3686C8.01012 18.4539 8.03261 18.5379 8.07347 18.6158C8.11433 18.6936 8.17276 18.7639 8.24543 18.8225C8.31811 18.8811 8.40359 18.9269 8.49701 18.9573C8.59043 18.9877 8.68996 19.0022 8.78991 18.9997C8.88985 18.9973 8.98826 18.9781 9.07952 18.9433C9.17078 18.9084 9.25309 18.8585 9.32177 18.7965L15.7919 12.9499C15.9256 12.8293 16 12.6696 16 12.5037C16 12.3377 15.9256 12.178 15.7919 12.0574L9.32177 6.21014C9.25355 6.14677 9.17125 6.09559 9.07966 6.05958C8.98806 6.02358 8.889 6.00347 8.78822 6.00041C8.68745 5.99735 8.58696 6.01142 8.49261 6.04178C8.39825 6.07215 8.31191 6.11821 8.23859 6.1773C8.16527 6.23639 8.10644 6.30732 8.06551 6.38597C8.02458 6.46462 8.00238 6.54943 8.00018 6.63547C7.99799 6.72151 8.01584 6.80707 8.05272 6.88717C8.08959 6.96727 8.14475 7.04032 8.21499 7.10207L14.1919 12.5037L8.21499 17.9039Z" fill="#000C2A" />
                                                                                </svg>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </li> */}
                                                                    </ul>
                                                                </div>
                                                            </NavDropdown.Item>
                                                        ))
                                                    )}


                                                {/* <NavDropdown.Item href="#action/3.2" className='p-0 position-relative'>
                                                    <div className='row align-items-center p-0 m-0'>
                                                        <div className="col-10">
                                                            Skills
                                                        </div>
                                                        <div className="col-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path d="M8.21499 17.9039C8.14631 17.966 8.09263 18.0389 8.05699 18.1186C8.02135 18.1984 8.00447 18.2833 8.00729 18.3686C8.01012 18.4539 8.03261 18.5379 8.07347 18.6158C8.11433 18.6936 8.17276 18.7639 8.24543 18.8225C8.31811 18.8811 8.40359 18.9269 8.49701 18.9573C8.59043 18.9877 8.68996 19.0022 8.78991 18.9997C8.88985 18.9973 8.98826 18.9781 9.07952 18.9433C9.17078 18.9084 9.25309 18.8585 9.32177 18.7965L15.7919 12.9499C15.9256 12.8293 16 12.6696 16 12.5037C16 12.3377 15.9256 12.178 15.7919 12.0574L9.32177 6.21014C9.25355 6.14677 9.17125 6.09559 9.07966 6.05958C8.98806 6.02358 8.889 6.00347 8.78822 6.00041C8.68745 5.99735 8.58696 6.01142 8.49261 6.04178C8.39825 6.07215 8.31191 6.11821 8.23859 6.1773C8.16527 6.23639 8.10644 6.30732 8.06551 6.38597C8.02458 6.46462 8.00238 6.54943 8.00018 6.63547C7.99799 6.72151 8.01584 6.80707 8.05272 6.88717C8.08959 6.96727 8.14475 7.04032 8.21499 7.10207L14.1919 12.5037L8.21499 17.9039Z" fill="#000C2A" />
                                                            </svg>
                                                        </div>
                                                        <ul className='sub-category-list d-none'>
                                                            <li>
                                                                <Link to="/courses-category" className='text-black'>

                                                                    <div className='row align-items-center p-0 m-0'>
                                                                        <div className="col-10">
                                                                            Business Skills
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <path d="M8.21499 17.9039C8.14631 17.966 8.09263 18.0389 8.05699 18.1186C8.02135 18.1984 8.00447 18.2833 8.00729 18.3686C8.01012 18.4539 8.03261 18.5379 8.07347 18.6158C8.11433 18.6936 8.17276 18.7639 8.24543 18.8225C8.31811 18.8811 8.40359 18.9269 8.49701 18.9573C8.59043 18.9877 8.68996 19.0022 8.78991 18.9997C8.88985 18.9973 8.98826 18.9781 9.07952 18.9433C9.17078 18.9084 9.25309 18.8585 9.32177 18.7965L15.7919 12.9499C15.9256 12.8293 16 12.6696 16 12.5037C16 12.3377 15.9256 12.178 15.7919 12.0574L9.32177 6.21014C9.25355 6.14677 9.17125 6.09559 9.07966 6.05958C8.98806 6.02358 8.889 6.00347 8.78822 6.00041C8.68745 5.99735 8.58696 6.01142 8.49261 6.04178C8.39825 6.07215 8.31191 6.11821 8.23859 6.1773C8.16527 6.23639 8.10644 6.30732 8.06551 6.38597C8.02458 6.46462 8.00238 6.54943 8.00018 6.63547C7.99799 6.72151 8.01584 6.80707 8.05272 6.88717C8.08959 6.96727 8.14475 7.04032 8.21499 7.10207L14.1919 12.5037L8.21499 17.9039Z" fill="#000C2A" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/courses-category" className='text-black'>

                                                                    <div className='row align-items-center p-0 m-0'>
                                                                        <div className="col-10">
                                                                            Tech Skills
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <path d="M8.21499 17.9039C8.14631 17.966 8.09263 18.0389 8.05699 18.1186C8.02135 18.1984 8.00447 18.2833 8.00729 18.3686C8.01012 18.4539 8.03261 18.5379 8.07347 18.6158C8.11433 18.6936 8.17276 18.7639 8.24543 18.8225C8.31811 18.8811 8.40359 18.9269 8.49701 18.9573C8.59043 18.9877 8.68996 19.0022 8.78991 18.9997C8.88985 18.9973 8.98826 18.9781 9.07952 18.9433C9.17078 18.9084 9.25309 18.8585 9.32177 18.7965L15.7919 12.9499C15.9256 12.8293 16 12.6696 16 12.5037C16 12.3377 15.9256 12.178 15.7919 12.0574L9.32177 6.21014C9.25355 6.14677 9.17125 6.09559 9.07966 6.05958C8.98806 6.02358 8.889 6.00347 8.78822 6.00041C8.68745 5.99735 8.58696 6.01142 8.49261 6.04178C8.39825 6.07215 8.31191 6.11821 8.23859 6.1773C8.16527 6.23639 8.10644 6.30732 8.06551 6.38597C8.02458 6.46462 8.00238 6.54943 8.00018 6.63547C7.99799 6.72151 8.01584 6.80707 8.05272 6.88717C8.08959 6.96727 8.14475 7.04032 8.21499 7.10207L14.1919 12.5037L8.21499 17.9039Z" fill="#000C2A" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/courses-category" className='text-black'>

                                                                    <div className='row align-items-center p-0 m-0'>
                                                                        <div className="col-10">
                                                                            Personal Development Skills
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <path d="M8.21499 17.9039C8.14631 17.966 8.09263 18.0389 8.05699 18.1186C8.02135 18.1984 8.00447 18.2833 8.00729 18.3686C8.01012 18.4539 8.03261 18.5379 8.07347 18.6158C8.11433 18.6936 8.17276 18.7639 8.24543 18.8225C8.31811 18.8811 8.40359 18.9269 8.49701 18.9573C8.59043 18.9877 8.68996 19.0022 8.78991 18.9997C8.88985 18.9973 8.98826 18.9781 9.07952 18.9433C9.17078 18.9084 9.25309 18.8585 9.32177 18.7965L15.7919 12.9499C15.9256 12.8293 16 12.6696 16 12.5037C16 12.3377 15.9256 12.178 15.7919 12.0574L9.32177 6.21014C9.25355 6.14677 9.17125 6.09559 9.07966 6.05958C8.98806 6.02358 8.889 6.00347 8.78822 6.00041C8.68745 5.99735 8.58696 6.01142 8.49261 6.04178C8.39825 6.07215 8.31191 6.11821 8.23859 6.1773C8.16527 6.23639 8.10644 6.30732 8.06551 6.38597C8.02458 6.46462 8.00238 6.54943 8.00018 6.63547C7.99799 6.72151 8.01584 6.80707 8.05272 6.88717C8.08959 6.96727 8.14475 7.04032 8.21499 7.10207L14.1919 12.5037L8.21499 17.9039Z" fill="#000C2A" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/courses-category" className='text-black'>

                                                                    <div className='row align-items-center p-0 m-0'>
                                                                        <div className="col-10">
                                                                            Startup Skills
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <path d="M8.21499 17.9039C8.14631 17.966 8.09263 18.0389 8.05699 18.1186C8.02135 18.1984 8.00447 18.2833 8.00729 18.3686C8.01012 18.4539 8.03261 18.5379 8.07347 18.6158C8.11433 18.6936 8.17276 18.7639 8.24543 18.8225C8.31811 18.8811 8.40359 18.9269 8.49701 18.9573C8.59043 18.9877 8.68996 19.0022 8.78991 18.9997C8.88985 18.9973 8.98826 18.9781 9.07952 18.9433C9.17078 18.9084 9.25309 18.8585 9.32177 18.7965L15.7919 12.9499C15.9256 12.8293 16 12.6696 16 12.5037C16 12.3377 15.9256 12.178 15.7919 12.0574L9.32177 6.21014C9.25355 6.14677 9.17125 6.09559 9.07966 6.05958C8.98806 6.02358 8.889 6.00347 8.78822 6.00041C8.68745 5.99735 8.58696 6.01142 8.49261 6.04178C8.39825 6.07215 8.31191 6.11821 8.23859 6.1773C8.16527 6.23639 8.10644 6.30732 8.06551 6.38597C8.02458 6.46462 8.00238 6.54943 8.00018 6.63547C7.99799 6.72151 8.01584 6.80707 8.05272 6.88717C8.08959 6.96727 8.14475 7.04032 8.21499 7.10207L14.1919 12.5037L8.21499 17.9039Z" fill="#000C2A" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/courses-category" className='text-black'>

                                                                    <div className='row align-items-center p-0 m-0'>
                                                                        <div className="col-10">
                                                                            Leadership Skills
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <path d="M8.21499 17.9039C8.14631 17.966 8.09263 18.0389 8.05699 18.1186C8.02135 18.1984 8.00447 18.2833 8.00729 18.3686C8.01012 18.4539 8.03261 18.5379 8.07347 18.6158C8.11433 18.6936 8.17276 18.7639 8.24543 18.8225C8.31811 18.8811 8.40359 18.9269 8.49701 18.9573C8.59043 18.9877 8.68996 19.0022 8.78991 18.9997C8.88985 18.9973 8.98826 18.9781 9.07952 18.9433C9.17078 18.9084 9.25309 18.8585 9.32177 18.7965L15.7919 12.9499C15.9256 12.8293 16 12.6696 16 12.5037C16 12.3377 15.9256 12.178 15.7919 12.0574L9.32177 6.21014C9.25355 6.14677 9.17125 6.09559 9.07966 6.05958C8.98806 6.02358 8.889 6.00347 8.78822 6.00041C8.68745 5.99735 8.58696 6.01142 8.49261 6.04178C8.39825 6.07215 8.31191 6.11821 8.23859 6.1773C8.16527 6.23639 8.10644 6.30732 8.06551 6.38597C8.02458 6.46462 8.00238 6.54943 8.00018 6.63547C7.99799 6.72151 8.01584 6.80707 8.05272 6.88717C8.08959 6.96727 8.14475 7.04032 8.21499 7.10207L14.1919 12.5037L8.21499 17.9039Z" fill="#000C2A" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/courses-category" className='text-black'>

                                                                    <div className='row align-items-center p-0 m-0'>
                                                                        <div className="col-10">
                                                                            Other Skills
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <path d="M8.21499 17.9039C8.14631 17.966 8.09263 18.0389 8.05699 18.1186C8.02135 18.1984 8.00447 18.2833 8.00729 18.3686C8.01012 18.4539 8.03261 18.5379 8.07347 18.6158C8.11433 18.6936 8.17276 18.7639 8.24543 18.8225C8.31811 18.8811 8.40359 18.9269 8.49701 18.9573C8.59043 18.9877 8.68996 19.0022 8.78991 18.9997C8.88985 18.9973 8.98826 18.9781 9.07952 18.9433C9.17078 18.9084 9.25309 18.8585 9.32177 18.7965L15.7919 12.9499C15.9256 12.8293 16 12.6696 16 12.5037C16 12.3377 15.9256 12.178 15.7919 12.0574L9.32177 6.21014C9.25355 6.14677 9.17125 6.09559 9.07966 6.05958C8.98806 6.02358 8.889 6.00347 8.78822 6.00041C8.68745 5.99735 8.58696 6.01142 8.49261 6.04178C8.39825 6.07215 8.31191 6.11821 8.23859 6.1773C8.16527 6.23639 8.10644 6.30732 8.06551 6.38597C8.02458 6.46462 8.00238 6.54943 8.00018 6.63547C7.99799 6.72151 8.01584 6.80707 8.05272 6.88717C8.08959 6.96727 8.14475 7.04032 8.21499 7.10207L14.1919 12.5037L8.21499 17.9039Z" fill="#000C2A" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </NavDropdown.Item>
                                                <NavDropdown.Item href="#action/3.3" className='p-0 position-relative'>
                                                    <div className='row align-items-center p-0 m-0'>
                                                        <div className="col-10 text-wrap">
                                                            Fun/General Knowledge/Miscellaneous
                                                        </div>
                                                        <div className="col-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path d="M8.21499 17.9039C8.14631 17.966 8.09263 18.0389 8.05699 18.1186C8.02135 18.1984 8.00447 18.2833 8.00729 18.3686C8.01012 18.4539 8.03261 18.5379 8.07347 18.6158C8.11433 18.6936 8.17276 18.7639 8.24543 18.8225C8.31811 18.8811 8.40359 18.9269 8.49701 18.9573C8.59043 18.9877 8.68996 19.0022 8.78991 18.9997C8.88985 18.9973 8.98826 18.9781 9.07952 18.9433C9.17078 18.9084 9.25309 18.8585 9.32177 18.7965L15.7919 12.9499C15.9256 12.8293 16 12.6696 16 12.5037C16 12.3377 15.9256 12.178 15.7919 12.0574L9.32177 6.21014C9.25355 6.14677 9.17125 6.09559 9.07966 6.05958C8.98806 6.02358 8.889 6.00347 8.78822 6.00041C8.68745 5.99735 8.58696 6.01142 8.49261 6.04178C8.39825 6.07215 8.31191 6.11821 8.23859 6.1773C8.16527 6.23639 8.10644 6.30732 8.06551 6.38597C8.02458 6.46462 8.00238 6.54943 8.00018 6.63547C7.99799 6.72151 8.01584 6.80707 8.05272 6.88717C8.08959 6.96727 8.14475 7.04032 8.21499 7.10207L14.1919 12.5037L8.21499 17.9039Z" fill="#000C2A" />
                                                            </svg>
                                                        </div>
                                                        <ul className='sub-category-list d-none'>
                                                            <li>
                                                                <Link to="/courses-category" className='text-black'>

                                                                    <div className='row align-items-center p-0 m-0'>
                                                                        <div className="col-10">
                                                                            General Knowledge
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <path d="M8.21499 17.9039C8.14631 17.966 8.09263 18.0389 8.05699 18.1186C8.02135 18.1984 8.00447 18.2833 8.00729 18.3686C8.01012 18.4539 8.03261 18.5379 8.07347 18.6158C8.11433 18.6936 8.17276 18.7639 8.24543 18.8225C8.31811 18.8811 8.40359 18.9269 8.49701 18.9573C8.59043 18.9877 8.68996 19.0022 8.78991 18.9997C8.88985 18.9973 8.98826 18.9781 9.07952 18.9433C9.17078 18.9084 9.25309 18.8585 9.32177 18.7965L15.7919 12.9499C15.9256 12.8293 16 12.6696 16 12.5037C16 12.3377 15.9256 12.178 15.7919 12.0574L9.32177 6.21014C9.25355 6.14677 9.17125 6.09559 9.07966 6.05958C8.98806 6.02358 8.889 6.00347 8.78822 6.00041C8.68745 5.99735 8.58696 6.01142 8.49261 6.04178C8.39825 6.07215 8.31191 6.11821 8.23859 6.1773C8.16527 6.23639 8.10644 6.30732 8.06551 6.38597C8.02458 6.46462 8.00238 6.54943 8.00018 6.63547C7.99799 6.72151 8.01584 6.80707 8.05272 6.88717C8.08959 6.96727 8.14475 7.04032 8.21499 7.10207L14.1919 12.5037L8.21499 17.9039Z" fill="#000C2A" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/courses-category" className='text-black'>

                                                                    <div className='row align-items-center p-0 m-0'>
                                                                        <div className="col-10">
                                                                            World Knowledge
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <path d="M8.21499 17.9039C8.14631 17.966 8.09263 18.0389 8.05699 18.1186C8.02135 18.1984 8.00447 18.2833 8.00729 18.3686C8.01012 18.4539 8.03261 18.5379 8.07347 18.6158C8.11433 18.6936 8.17276 18.7639 8.24543 18.8225C8.31811 18.8811 8.40359 18.9269 8.49701 18.9573C8.59043 18.9877 8.68996 19.0022 8.78991 18.9997C8.88985 18.9973 8.98826 18.9781 9.07952 18.9433C9.17078 18.9084 9.25309 18.8585 9.32177 18.7965L15.7919 12.9499C15.9256 12.8293 16 12.6696 16 12.5037C16 12.3377 15.9256 12.178 15.7919 12.0574L9.32177 6.21014C9.25355 6.14677 9.17125 6.09559 9.07966 6.05958C8.98806 6.02358 8.889 6.00347 8.78822 6.00041C8.68745 5.99735 8.58696 6.01142 8.49261 6.04178C8.39825 6.07215 8.31191 6.11821 8.23859 6.1773C8.16527 6.23639 8.10644 6.30732 8.06551 6.38597C8.02458 6.46462 8.00238 6.54943 8.00018 6.63547C7.99799 6.72151 8.01584 6.80707 8.05272 6.88717C8.08959 6.96727 8.14475 7.04032 8.21499 7.10207L14.1919 12.5037L8.21499 17.9039Z" fill="#000C2A" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/courses-category" className='text-black'>

                                                                    <div className='row align-items-center p-0 m-0'>
                                                                        <div className="col-10">
                                                                            History Knowledge
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <path d="M8.21499 17.9039C8.14631 17.966 8.09263 18.0389 8.05699 18.1186C8.02135 18.1984 8.00447 18.2833 8.00729 18.3686C8.01012 18.4539 8.03261 18.5379 8.07347 18.6158C8.11433 18.6936 8.17276 18.7639 8.24543 18.8225C8.31811 18.8811 8.40359 18.9269 8.49701 18.9573C8.59043 18.9877 8.68996 19.0022 8.78991 18.9997C8.88985 18.9973 8.98826 18.9781 9.07952 18.9433C9.17078 18.9084 9.25309 18.8585 9.32177 18.7965L15.7919 12.9499C15.9256 12.8293 16 12.6696 16 12.5037C16 12.3377 15.9256 12.178 15.7919 12.0574L9.32177 6.21014C9.25355 6.14677 9.17125 6.09559 9.07966 6.05958C8.98806 6.02358 8.889 6.00347 8.78822 6.00041C8.68745 5.99735 8.58696 6.01142 8.49261 6.04178C8.39825 6.07215 8.31191 6.11821 8.23859 6.1773C8.16527 6.23639 8.10644 6.30732 8.06551 6.38597C8.02458 6.46462 8.00238 6.54943 8.00018 6.63547C7.99799 6.72151 8.01584 6.80707 8.05272 6.88717C8.08959 6.96727 8.14475 7.04032 8.21499 7.10207L14.1919 12.5037L8.21499 17.9039Z" fill="#000C2A" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/courses-category" className='text-black'>

                                                                    <div className='row align-items-center p-0 m-0'>
                                                                        <div className="col-10">
                                                                            Courses for Kids
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <path d="M8.21499 17.9039C8.14631 17.966 8.09263 18.0389 8.05699 18.1186C8.02135 18.1984 8.00447 18.2833 8.00729 18.3686C8.01012 18.4539 8.03261 18.5379 8.07347 18.6158C8.11433 18.6936 8.17276 18.7639 8.24543 18.8225C8.31811 18.8811 8.40359 18.9269 8.49701 18.9573C8.59043 18.9877 8.68996 19.0022 8.78991 18.9997C8.88985 18.9973 8.98826 18.9781 9.07952 18.9433C9.17078 18.9084 9.25309 18.8585 9.32177 18.7965L15.7919 12.9499C15.9256 12.8293 16 12.6696 16 12.5037C16 12.3377 15.9256 12.178 15.7919 12.0574L9.32177 6.21014C9.25355 6.14677 9.17125 6.09559 9.07966 6.05958C8.98806 6.02358 8.889 6.00347 8.78822 6.00041C8.68745 5.99735 8.58696 6.01142 8.49261 6.04178C8.39825 6.07215 8.31191 6.11821 8.23859 6.1773C8.16527 6.23639 8.10644 6.30732 8.06551 6.38597C8.02458 6.46462 8.00238 6.54943 8.00018 6.63547C7.99799 6.72151 8.01584 6.80707 8.05272 6.88717C8.08959 6.96727 8.14475 7.04032 8.21499 7.10207L14.1919 12.5037L8.21499 17.9039Z" fill="#000C2A" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/courses-category" className='text-black'>

                                                                    <div className='row align-items-center p-0 m-0'>
                                                                        <div className="col-10">
                                                                            Courses for Parents
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <path d="M8.21499 17.9039C8.14631 17.966 8.09263 18.0389 8.05699 18.1186C8.02135 18.1984 8.00447 18.2833 8.00729 18.3686C8.01012 18.4539 8.03261 18.5379 8.07347 18.6158C8.11433 18.6936 8.17276 18.7639 8.24543 18.8225C8.31811 18.8811 8.40359 18.9269 8.49701 18.9573C8.59043 18.9877 8.68996 19.0022 8.78991 18.9997C8.88985 18.9973 8.98826 18.9781 9.07952 18.9433C9.17078 18.9084 9.25309 18.8585 9.32177 18.7965L15.7919 12.9499C15.9256 12.8293 16 12.6696 16 12.5037C16 12.3377 15.9256 12.178 15.7919 12.0574L9.32177 6.21014C9.25355 6.14677 9.17125 6.09559 9.07966 6.05958C8.98806 6.02358 8.889 6.00347 8.78822 6.00041C8.68745 5.99735 8.58696 6.01142 8.49261 6.04178C8.39825 6.07215 8.31191 6.11821 8.23859 6.1773C8.16527 6.23639 8.10644 6.30732 8.06551 6.38597C8.02458 6.46462 8.00238 6.54943 8.00018 6.63547C7.99799 6.72151 8.01584 6.80707 8.05272 6.88717C8.08959 6.96727 8.14475 7.04032 8.21499 7.10207L14.1919 12.5037L8.21499 17.9039Z" fill="#000C2A" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/courses-category" className='text-black'>

                                                                    <div className='row align-items-center p-0 m-0'>
                                                                        <div className="col-10">
                                                                            Courses for Teachers
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <path d="M8.21499 17.9039C8.14631 17.966 8.09263 18.0389 8.05699 18.1186C8.02135 18.1984 8.00447 18.2833 8.00729 18.3686C8.01012 18.4539 8.03261 18.5379 8.07347 18.6158C8.11433 18.6936 8.17276 18.7639 8.24543 18.8225C8.31811 18.8811 8.40359 18.9269 8.49701 18.9573C8.59043 18.9877 8.68996 19.0022 8.78991 18.9997C8.88985 18.9973 8.98826 18.9781 9.07952 18.9433C9.17078 18.9084 9.25309 18.8585 9.32177 18.7965L15.7919 12.9499C15.9256 12.8293 16 12.6696 16 12.5037C16 12.3377 15.9256 12.178 15.7919 12.0574L9.32177 6.21014C9.25355 6.14677 9.17125 6.09559 9.07966 6.05958C8.98806 6.02358 8.889 6.00347 8.78822 6.00041C8.68745 5.99735 8.58696 6.01142 8.49261 6.04178C8.39825 6.07215 8.31191 6.11821 8.23859 6.1773C8.16527 6.23639 8.10644 6.30732 8.06551 6.38597C8.02458 6.46462 8.00238 6.54943 8.00018 6.63547C7.99799 6.72151 8.01584 6.80707 8.05272 6.88717C8.08959 6.96727 8.14475 7.04032 8.21499 7.10207L14.1919 12.5037L8.21499 17.9039Z" fill="#000C2A" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/courses-category" className='text-black'>

                                                                    <div className='row align-items-center p-0 m-0'>
                                                                        <div className="col-10">
                                                                            Animals
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <path d="M8.21499 17.9039C8.14631 17.966 8.09263 18.0389 8.05699 18.1186C8.02135 18.1984 8.00447 18.2833 8.00729 18.3686C8.01012 18.4539 8.03261 18.5379 8.07347 18.6158C8.11433 18.6936 8.17276 18.7639 8.24543 18.8225C8.31811 18.8811 8.40359 18.9269 8.49701 18.9573C8.59043 18.9877 8.68996 19.0022 8.78991 18.9997C8.88985 18.9973 8.98826 18.9781 9.07952 18.9433C9.17078 18.9084 9.25309 18.8585 9.32177 18.7965L15.7919 12.9499C15.9256 12.8293 16 12.6696 16 12.5037C16 12.3377 15.9256 12.178 15.7919 12.0574L9.32177 6.21014C9.25355 6.14677 9.17125 6.09559 9.07966 6.05958C8.98806 6.02358 8.889 6.00347 8.78822 6.00041C8.68745 5.99735 8.58696 6.01142 8.49261 6.04178C8.39825 6.07215 8.31191 6.11821 8.23859 6.1773C8.16527 6.23639 8.10644 6.30732 8.06551 6.38597C8.02458 6.46462 8.00238 6.54943 8.00018 6.63547C7.99799 6.72151 8.01584 6.80707 8.05272 6.88717C8.08959 6.96727 8.14475 7.04032 8.21499 7.10207L14.1919 12.5037L8.21499 17.9039Z" fill="#000C2A" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/courses-category" className='text-black'>

                                                                    <div className='row align-items-center p-0 m-0'>
                                                                        <div className="col-10">
                                                                            Art
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <path d="M8.21499 17.9039C8.14631 17.966 8.09263 18.0389 8.05699 18.1186C8.02135 18.1984 8.00447 18.2833 8.00729 18.3686C8.01012 18.4539 8.03261 18.5379 8.07347 18.6158C8.11433 18.6936 8.17276 18.7639 8.24543 18.8225C8.31811 18.8811 8.40359 18.9269 8.49701 18.9573C8.59043 18.9877 8.68996 19.0022 8.78991 18.9997C8.88985 18.9973 8.98826 18.9781 9.07952 18.9433C9.17078 18.9084 9.25309 18.8585 9.32177 18.7965L15.7919 12.9499C15.9256 12.8293 16 12.6696 16 12.5037C16 12.3377 15.9256 12.178 15.7919 12.0574L9.32177 6.21014C9.25355 6.14677 9.17125 6.09559 9.07966 6.05958C8.98806 6.02358 8.889 6.00347 8.78822 6.00041C8.68745 5.99735 8.58696 6.01142 8.49261 6.04178C8.39825 6.07215 8.31191 6.11821 8.23859 6.1773C8.16527 6.23639 8.10644 6.30732 8.06551 6.38597C8.02458 6.46462 8.00238 6.54943 8.00018 6.63547C7.99799 6.72151 8.01584 6.80707 8.05272 6.88717C8.08959 6.96727 8.14475 7.04032 8.21499 7.10207L14.1919 12.5037L8.21499 17.9039Z" fill="#000C2A" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/courses-category" className='text-black'>

                                                                    <div className='row align-items-center p-0 m-0'>
                                                                        <div className="col-10">
                                                                            Entertainment
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <path d="M8.21499 17.9039C8.14631 17.966 8.09263 18.0389 8.05699 18.1186C8.02135 18.1984 8.00447 18.2833 8.00729 18.3686C8.01012 18.4539 8.03261 18.5379 8.07347 18.6158C8.11433 18.6936 8.17276 18.7639 8.24543 18.8225C8.31811 18.8811 8.40359 18.9269 8.49701 18.9573C8.59043 18.9877 8.68996 19.0022 8.78991 18.9997C8.88985 18.9973 8.98826 18.9781 9.07952 18.9433C9.17078 18.9084 9.25309 18.8585 9.32177 18.7965L15.7919 12.9499C15.9256 12.8293 16 12.6696 16 12.5037C16 12.3377 15.9256 12.178 15.7919 12.0574L9.32177 6.21014C9.25355 6.14677 9.17125 6.09559 9.07966 6.05958C8.98806 6.02358 8.889 6.00347 8.78822 6.00041C8.68745 5.99735 8.58696 6.01142 8.49261 6.04178C8.39825 6.07215 8.31191 6.11821 8.23859 6.1773C8.16527 6.23639 8.10644 6.30732 8.06551 6.38597C8.02458 6.46462 8.00238 6.54943 8.00018 6.63547C7.99799 6.72151 8.01584 6.80707 8.05272 6.88717C8.08959 6.96727 8.14475 7.04032 8.21499 7.10207L14.1919 12.5037L8.21499 17.9039Z" fill="#000C2A" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/courses-category" className='text-black'>

                                                                    <div className='row align-items-center p-0 m-0'>
                                                                        <div className="col-10">
                                                                            Sports
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <path d="M8.21499 17.9039C8.14631 17.966 8.09263 18.0389 8.05699 18.1186C8.02135 18.1984 8.00447 18.2833 8.00729 18.3686C8.01012 18.4539 8.03261 18.5379 8.07347 18.6158C8.11433 18.6936 8.17276 18.7639 8.24543 18.8225C8.31811 18.8811 8.40359 18.9269 8.49701 18.9573C8.59043 18.9877 8.68996 19.0022 8.78991 18.9997C8.88985 18.9973 8.98826 18.9781 9.07952 18.9433C9.17078 18.9084 9.25309 18.8585 9.32177 18.7965L15.7919 12.9499C15.9256 12.8293 16 12.6696 16 12.5037C16 12.3377 15.9256 12.178 15.7919 12.0574L9.32177 6.21014C9.25355 6.14677 9.17125 6.09559 9.07966 6.05958C8.98806 6.02358 8.889 6.00347 8.78822 6.00041C8.68745 5.99735 8.58696 6.01142 8.49261 6.04178C8.39825 6.07215 8.31191 6.11821 8.23859 6.1773C8.16527 6.23639 8.10644 6.30732 8.06551 6.38597C8.02458 6.46462 8.00238 6.54943 8.00018 6.63547C7.99799 6.72151 8.01584 6.80707 8.05272 6.88717C8.08959 6.96727 8.14475 7.04032 8.21499 7.10207L14.1919 12.5037L8.21499 17.9039Z" fill="#000C2A" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/courses-category" className='text-black'>

                                                                    <div className='row align-items-center p-0 m-0'>
                                                                        <div className="col-10">
                                                                            Other
                                                                        </div>
                                                                        <div className="col-2">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <path d="M8.21499 17.9039C8.14631 17.966 8.09263 18.0389 8.05699 18.1186C8.02135 18.1984 8.00447 18.2833 8.00729 18.3686C8.01012 18.4539 8.03261 18.5379 8.07347 18.6158C8.11433 18.6936 8.17276 18.7639 8.24543 18.8225C8.31811 18.8811 8.40359 18.9269 8.49701 18.9573C8.59043 18.9877 8.68996 19.0022 8.78991 18.9997C8.88985 18.9973 8.98826 18.9781 9.07952 18.9433C9.17078 18.9084 9.25309 18.8585 9.32177 18.7965L15.7919 12.9499C15.9256 12.8293 16 12.6696 16 12.5037C16 12.3377 15.9256 12.178 15.7919 12.0574L9.32177 6.21014C9.25355 6.14677 9.17125 6.09559 9.07966 6.05958C8.98806 6.02358 8.889 6.00347 8.78822 6.00041C8.68745 5.99735 8.58696 6.01142 8.49261 6.04178C8.39825 6.07215 8.31191 6.11821 8.23859 6.1773C8.16527 6.23639 8.10644 6.30732 8.06551 6.38597C8.02458 6.46462 8.00238 6.54943 8.00018 6.63547C7.99799 6.72151 8.01584 6.80707 8.05272 6.88717C8.08959 6.96727 8.14475 7.04032 8.21499 7.10207L14.1919 12.5037L8.21499 17.9039Z" fill="#000C2A" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </NavDropdown.Item> */}
                                            </NavDropdown>

                                            <li className="nav-item">
                                                <Link className={`nav-link ${location.pathname === '/individuals' ? 'active' : ''}`} to="/individuals">
                                                    For Individuals
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className={`nav-link ${location.pathname === '/teams' ? 'active' : ''}`} to="/teams">
                                                    For Teams
                                                </Link>
                                            </li>
                                            <li className={`nav-item ${location.pathname === '' ? 'active' : ''}`}>
                                                <Link to=""
                                                    className="nav-link translate d-flex gap-3"
                                                    id="Translate"
                                                >
                                                    <img src="../svg/translate.svg" className='translate-white-svg' alt=""></img>
                                                    <img src=" ../svg/translate-dark.svg" className='translate-dark-svg d-none' alt=""></img>
                                                    Translate
                                                </Link>

                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <form className="header-form">
                                    <div className="d-flex align-content-center gap-3">
                                        <div className="form-control sm-none d-flex justify-content-center align-items-center w-100 rounded-2 overflow-hidden py-0">
                                            <input
                                                className="form-control border-0 rounded-2 bg-transparent shadow-none"
                                                type="text"
                                                placeholder="Search the course"
                                                value={search}
                                                onChange={handleSearchChange}
                                                onKeyPress={handleKeyPress}
                                            />
                                            <Link to=""
                                                className="search-icon ps-2">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={24}
                                                    height={24}
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                                                        stroke="#000C2A"
                                                        strokeWidth={2}
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M20.9999 21L16.6499 16.65"
                                                        stroke="#000C2A"
                                                        strokeWidth={2}
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </Link>
                                        </div>
                                        {user?.user_id && token ?
                                            <div className="user-profile" onClick={toggleDropdown}>
                                                { user?.user_profile ?
                                                    <div className="customer-image">
                                                        <img src={getImageBaseUrl() + user.user_profile} alt="" />
                                                    </div> :
                                                    <div className="profile-initials">
                                                        {user?.username?.charAt(0).toUpperCase()}
                                                    </div>
                                                }
                                            </div>
                                            :
                                            <>
                                                {/* Uncomment below if you plan to use the Start Free Trial link in the future */}
                                                {/* <Link
                    to="/sign-up"
                    className="w-75 rounded-2 d-none d-md-flex justify-content-center align-items-center start-btn">
                    Start Free Trial
                </Link> */}
                                                <Link to="/sign-in" className="rounded-2 d-none d-md-flex justify-content-center align-items-center start-btn">Login</Link>
                                                <Link to="/sign-up" className="rounded-2 d-none d-md-flex justify-content-center align-items-center start-btn sign-up-btn text-nowrap">Sign Up</Link>
                                            </>
                                        }

                                        {/* <span>{user?.username}</span> */}

                                        {/* {showDropdown &&
                                            <div style={{ marginTop: "55px" }} className="dropdown-menu-home ">
                                                <button onClick={handleLogout}>Logout</button>
                                            </div>
                                        } */}
                                        <a variant="primary" onClick={handleShow} className="bar text-white fs-3 mt-1 ms-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" fill='#fff' y="0px" width="35" height="35" viewBox="0 0 50 50">
                                                <path d="M 5 8 A 2.0002 2.0002 0 1 0 5 12 L 45 12 A 2.0002 2.0002 0 1 0 45 8 L 5 8 z M 5 23 A 2.0002 2.0002 0 1 0 5 27 L 45 27 A 2.0002 2.0002 0 1 0 45 23 L 5 23 z M 5 38 A 2.0002 2.0002 0 1 0 5 42 L 45 42 A 2.0002 2.0002 0 1 0 45 38 L 5 38 z"></path>
                                            </svg>
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </nav>
                    </div>
                </div>
                <Offcanvas show={show} onHide={handleClose} placement="end">
                    <Offcanvas.Header className='border-bottom' closeButton>
                        <Link className="navbar-brand header-logo" to="/">
                            <img src="../logo/white-logo.svg" alt="" />
                        </Link>
                    </Offcanvas.Header>

                    <Offcanvas.Body>
                        <form className="header-form d-md-none px-3 py-3 border-bottom">
                            <div>
                                <div className="form-control bg-light d-flex justify-content-center align-items-center rounded-2 overflow-hidden py-0">
                                    <input
                                        className="form-control border-0 bg-light rounded-2"
                                        type="text"
                                        placeholder="Search the course"
                                        value={search}
                                        onChange={handleSearchChange}
                                        onKeyPress={handleKeyPress} // Added key press event handler

                                    />
                                    <Link to="" onClick={handlePress} className="search-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z" stroke="#000C2A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M20.9999 21L16.6499 16.65" stroke="#000C2A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                    </Link>
                                </div>
                            </div>
                        </form>
                        <ul className="navbar-nav gap-4">
                            <li className="nav-item border-bottom px-3">
                                <Link
                                    className="nav-link fw-500 fs-19"
                                    aria-current="page"
                                    to="/courses"
                                >
                                    Courses
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="nav-link fw-500 fs-19 py-0"
                                    aria-current="page"
                                >
                                    {/* Categories */}
                                    <Accordion defaultActiveKey="01">
                                        <Accordion.Item eventKey="02" className='border-0 border-bottom rounded-0 py-0'>
                                            <Accordion.Header
                                                // onClick={getCategorys} 
                                                className='sidebar-menu-sm'>Categories</Accordion.Header>
                                            <Accordion.Body className='px-2 pb-0'>
                                                <Accordion className='sidebar-category-main' defaultActiveKey="0">
                                                    {
                                                        loder ? (
                                                            <DropdownSkeleton /> // Use the DropdownSkeleton when data is loading
                                                        ) : (
                                                            categories?.length > 0 && categories.map((category, index) => (
                                                                <Accordion.Item key={index} eventKey={index.toString()} className='border-0'>
                                                                    <Accordion.Header>{category?.name}</Accordion.Header>
                                                                    <Accordion.Body className='p-0'>
                                                                        {category.subcategories?.length > 0 && category.subcategories.map((subcategory, subIndex) => (
                                                                            <Link to={`/courses-category/${subcategory.slug}`} key={subIndex} onClick={() => (handleClose()
                                                                                //  storeSubCategorys(subcategory.slug)
                                                                            )
                                                                            } className='d-block p-3 mb-0 text-black'>
                                                                                {subcategory.name}
                                                                            </Link>
                                                                        ))}
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            ))
                                                        )}
                                                </Accordion>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Link>
                            </li>
                            <li className="nav-item border-bottom px-3">
                                <Link
                                    className="nav-link fw-500 fs-19"
                                    aria-current="page"
                                    to="/individuals"
                                >
                                    For Individuals
                                </Link>
                            </li>
                            <li className="nav-item border-bottom px-3">
                                <Link
                                    className="nav-link fw-500 fs-19"
                                    aria-current="page"
                                    to="/teams"
                                >
                                    For Teams
                                </Link>
                            </li>
                            <li className="nav-item border-bottom px-3">
                                <Link
                                    className="nav-link fw-500 fs-19 translate d-flex gap-2"
                                    aria-current="page"
                                    to="/"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <mask
                                            id="mask0_1026_578"
                                            style={{ maskType: "alpha" }}
                                            maskUnits="userSpaceOnUse"
                                            x={0}
                                            y={0}
                                            width={24}
                                            height={24}
                                        >
                                            <rect width={24} height={24} fill="#D9D9D9" />
                                        </mask>
                                        <g mask="url(#mask0_1026_578)">
                                            <path
                                                d="M11.8995 22L16.4495 10H18.5495L23.0995 22H20.9995L19.9495 18.95H15.0995L13.9995 22H11.8995ZM15.6995 17.2H19.2995L17.5495 12.25H17.4495L15.6995 17.2ZM3.99951 19L2.59951 17.6L7.64951 12.55C7.01618 11.85 6.46201 11.125 5.98701 10.375C5.51201 9.625 5.09951 8.83333 4.74951 8H6.84951C7.14951 8.6 7.47035 9.14167 7.81201 9.625C8.15368 10.1083 8.56618 10.6167 9.04951 11.15C9.78285 10.35 10.3912 9.52917 10.8745 8.6875C11.3578 7.84583 11.7662 6.95 12.0995 6H0.999512V4H7.99951V2H9.99951V4H16.9995V6H14.0995C13.7495 7.18333 13.2745 8.33333 12.6745 9.45C12.0745 10.5667 11.3328 11.6167 10.4495 12.6L12.8495 15.05L12.0995 17.1L8.99951 14L3.99951 19Z"
                                                fill="#002FA7"
                                            />
                                        </g>
                                    </svg>
                                    Translate
                                </Link>
                            </li>
                        </ul>
                    </Offcanvas.Body>
                    {user?.user_id && token ?

                        <div className="user-profile" onClick={toggleDropdown}>
                            <div className="profile-initials">
                                {user?.username?.charAt(0)}
                            </div>
                            <span>{user?.username}</span>
                            {/* {showDropdown &&
                                <div className="dropdown-menu">
                                    <button onClick={handleLogout}>Logout</button>
                                </div>
                            } */}
                        </div>
                        :
                        <div className="offcanvas-footer mx-3 mb-4 d-md-none">
                            {/* <Link
                                to="/sign-up"
                                className="w-100 rounded-2  d-flex justify-content-center align-items-center start-btn p-3 w-100"
                            >
                                Start Free Trial
                            </Link> */}
                            <div className='d-flex gap-3'>
                                <Link to="/sign-in" className="w-100 rounded-2 d-none d-md-flex justify-content-center align-items-center login-btn-sm">Login</Link>
                                <Link to="/sign-up" className="w-100 rounded-2 d-none d-md-flex justify-content-center align-items-center sign-up-btn-sm">Sign Up</Link>
                            </div>
                        </div>
                    }

                </Offcanvas>
            </header>
        </div>
    );
}

export default Header;
