import React, { useState } from 'react'

function SelectFlagReasonModal({ setFlagReasonFilter, handleToggle }) {
    // State to keep track of selected statuses
    const [selectedFlagReason, setSelectedFlagReason] = useState("");

    // Function to handle button clicks
    const handleClick = (status) => {
        if (selectedFlagReason === status) {
            setSelectedFlagReason("");
        } else  {
            setSelectedFlagReason(status)
        }
    };

    const onApplyClick = () => {
        setFlagReasonFilter(selectedFlagReason);
        handleToggle("flagReason")
    }

    return (
        <>
            <div className="select-user-status-modal">
                <div className='p-4'>
                    <h2>Select Flag Reason</h2>

                    <div className='d-flex justify-content-between align-items-center user-status-buttons gap-3 mt-4'>
                        <button
                            className={selectedFlagReason === "wrong" ? 'active w-100' : 'w-100'}
                            onClick={() => handleClick('wrong')}
                        >
                            Wrong
                        </button>
                        <button
                            className={selectedFlagReason === "confusing" ? 'active w-100' : 'w-100'}
                            onClick={() => handleClick('confusing')}
                        >
                            Confusing
                        </button>
                        <button
                            className={selectedFlagReason === "inadequate" ? 'active w-100' : 'w-100'}
                            onClick={() => handleClick('inadequate')}
                        >
                           Inadequate
                        </button>
                        <button
                            className={selectedFlagReason === "other" ? 'active w-100' : 'w-100'}
                            onClick={() => handleClick('other')}
                        >
                           Other
                        </button>
                    </div>
                </div>
                <div className="p-4 d-flex justify-content-between align-items-start border-dark-top-100">
                    <p className='pb-0 text-light-300 fw-400 fs-7'>*You can choose single User Status</p>
                    <button className='apply-btn' onClick={onApplyClick}>
                        Apply
                    </button>
                </div>
            </div>
        </>
    )
}

export default SelectFlagReasonModal
