import React, { useState, useRef, useContext, useEffect } from 'react';
import { Nav, Tab, Row, Col } from 'react-bootstrap';
import AdminHeader from '../layout/AdminHeader';
import BusinessSidebar from '../components/BusinessSidebar';
import Footer from '../layout/Footer';
import { Link } from 'react-router-dom';
import ComplianceScoreChart from '../components/ComplianceScoreChart';
import DoughnutChart2 from '../components/DoughnutChart2';
import { getImageBaseUrl, getServerURL } from '../helper/envConfig';
import { CourseContext } from '../context/CourseContext';
import api from '../helper/api';
import { toast } from 'react-toastify';
import { decryptFromStoreLoc, encryptAndStoreLoc } from '../helper/localStore';
import CustomerSidbar from '../components/CustomerSidbar';

function EditProfile() {
    
    const { user, setUser, token, loder, setLoder, getCourses, setIs_search, setSearch } = useContext(CourseContext);
    const [activeKey, setActiveKey] = useState('1');
    const serverURL = getServerURL();

    const handleSelect = (key) => {
        setActiveKey(key);
    };

    const [isCopied, setIsCopied] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [userData, setUserData] = useState({});
    const [departmentsuserData, setDepartments] = useState([]);
    const fileInputRef = useRef(null);

    // Function to handle file selection
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    // Function to handle click on "Change Photo" button
    const handleClick = () => {
        fileInputRef.current.click();
    };


    useEffect(() => {
        const decryptedInfoResult = decryptFromStoreLoc('user');
        setUser(decryptedInfoResult)
    }, []);

    const getProfile = async () => {
        try {
            setLoder(true);
            // First API call to get user profile
            const profileResponse = await api.getWithToken(`${serverURL}users/profile/`);
            setUserData(profileResponse.data.data);
            // Step 2: Decrypt the data using the encryption key
            const decryptedUserData = decryptFromStoreLoc('user');

            // Step 3: Update the necessary fields in the decrypted user object
            if (decryptedUserData) {
                decryptedUserData.user_profile = profileResponse.data.data.avatar;
                setUser({
                    ...user,
                    user_profile: profileResponse.data.data.avatar
                })
                encryptAndStoreLoc('user', decryptedUserData);
            }

            // Step 4: Encrypt the updated user object

            // Second API call to get employee departments
            const departmentsResponse = await api.getWithToken(`${serverURL}users/employee/departments/`);
            setDepartments(departmentsResponse.data.data); // Assuming you have a state setter like this

            setLoder(false);
        } catch (error) {
            console.log(error);
            setLoder(false);
        }
    };

    useEffect(() => {
        getProfile();
    }, []); // Add serverURL to dependency array if it's dynamic and might change


    // Function to copy code to clipboard
    const handleCopyCode = async () => {
        if (userData && userData?.associated_code) {
            try {
                await navigator.clipboard.writeText(userData?.associated_code);
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 3000); // Reset copied status after 3 seconds
                toast.success("Associated code is copied");
            } catch (error) {
                console.error('Failed to copy text:', error);
                toast.error(`Failed to copy code: ${error.message}`);
            }
        } else {
            console.log('No code to copy');
        }
    };


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setUserData(prevState => {
            const newState = { ...prevState, [name]: value };
            console.log(newState); // Check what the new state will be
            return newState;
        });
    };


    const saveProfileChanges = async () => {
        setLoder(true);
        try {
            const { avatar, ...userDataWithoutAvatar } = userData;

            const updateResponse = await api.patchWithToken(`${serverURL}users/profile/`, { associated_code: userDataWithoutAvatar.associated_code, first_name: userDataWithoutAvatar.first_name, last_name: userDataWithoutAvatar.last_name, department: userDataWithoutAvatar.department });
            toast.success(updateResponse.data.message);
            setUser(updateResponse.data.data); // Update the context or local storage if needed
            window.location.reload()
        } catch (error) {
            console.error('Failed to update profile:', error);
            toast.error('Failed to update profile');
        } finally {
            setLoder(false);
        }
    };


    const saveProfileImageChanges = async () => {
        const formData = new FormData();
        formData.append('image', selectedFile);

        try {
            const updateResponse = await api.postWithToken(`${serverURL}users/update-image/`, formData);
            console.log(updateResponse.data.message, "updateResponse");
            toast.success(updateResponse.data.message);
            getProfile()
            // window.location.reload()
            // setUserData({ ...userData, avatar: updateResponse.data.data.avatar });
            // setPreviewUrl(getImageBaseUrl() + updateResponse.data.data.avatar);
        } catch (error) {
            console.error('Failed to update profile:', error);
            toast.error('Failed to update profile');
        }
    };

    // console.log(user?.role_name, "role_name");
    //Business User  => // I’m a business owner // I’m a HR admin // I’m a compliance manager //I’m a line manager // I’m a employee
    //I’m individual user => //2  I’m individual user
    //Family Use  => // I'm a family member	// I’m a Kid	

    function determinePermissions(role) {
        const permissions = {
            canUpdateAssociatedCode: false,
            canUpdateDepartment: false
        };

        switch (role) {
            case "I’m a business owner":
                permissions.canUpdateAssociatedCode = false;
                permissions.canUpdateDepartment = false;
                break;
            case "I’m a compliance manager":
            case "I’m a line manager":
                permissions.canUpdateAssociatedCode = true;
                permissions.canUpdateDepartment = false;
                break;
            case "I’m a employee":
                permissions.canUpdateAssociatedCode = true;
                permissions.canUpdateDepartment = true;
                break;
            case "I'm a family member":
                permissions.canUpdateAssociatedCode = false;
                permissions.canUpdateDepartment = false;
                break;
            case "I’m a Kid":
                permissions.canUpdateAssociatedCode = true;
                permissions.canUpdateDepartment = false;
                break;
            case "I’m individual user":
                permissions.canUpdateAssociatedCode = false;
                permissions.canUpdateDepartment = false;
                break;

            case "I’m a HR admin":
                permissions.canUpdateAssociatedCode = true;
                permissions.canUpdateDepartment = false;
                break;


            default:
                // Assume no permissions if role not recognized
                break;
        }

        return permissions;
    }

    const permissions = determinePermissions(user?.role_name);

    return (
        <>
            <AdminHeader />
            <section>
                <div className="dashbaord-home-main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-3 col-md-4 col-lg-3 col-xxl-3 d-none d-xl-block px-4 bg-white sidebar-mian">
                            {user?.role_name === "I’m a business owner" || user?.role_name === "I’m a compliance manager" ||
                                user?.role_name === "I’m a line manager" || user?.role_name === "I’m a HR admin" ? <BusinessSidebar /> :
                                <CustomerSidbar /> }
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xxl-9 bg-body">
                                <div className="row ps-0 ps-lg-4 pe-lg-4 ps-xl-5 dashbaord-right-side justify-content-md-center justify-content-lg-between pt-5">
                                    <div className="col-12 col-md-11 col-lg-12 right-side">
                                        <div className="d-md-flex justify-content-between align-items-center gap-3 pt-5 pt-md-0 pb-0 pb-md-4">
                                            <h2 className="text-black fw-600">Edit Profile</h2>
                                        </div>
                                        <div className="row g-4 mt-3 mb-5 pb-0 pb-md-4">
                                            <div className='col-md-5 col-lg-4'>
                                                <div className="card border-0 rounded-3">
                                                    <div className="card-body p-5">
                                                        <h5 className='fs-20 fw-600 mb-5'>Profile Picture</h5>
                                                        <div className='d-flex justify-content-center align-items-center mb-4'>
                                                            <div className='profile-image border-0'>
                                                                {previewUrl ? (
                                                                    <img src={previewUrl} alt="Preview" style={{ maxWidth: "100px", maxHeight: "100px" }} />
                                                                ) : (
                                                                    userData.avatar ? (
                                                                        <img src={getImageBaseUrl() + userData.avatar} alt="User Profile" style={{ maxWidth: "100px", maxHeight: "100px" }} />
                                                                    ) : (
                                                                        <div className="profile-initials profile-image text-capitalize display-2">
                                                                            {user?.username?.charAt(0)}
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-center mt-5">
                                                            <div>
                                                                <input
                                                                    type="file"
                                                                    ref={fileInputRef}
                                                                    onChange={handleFileChange}
                                                                    style={{ display: 'none' }}
                                                                    name='image'
                                                                />
                                                                <button className='border-primary-btn' onClick={handleClick}>Select Photo</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-7 col-lg-8">
                                                <Row className='g-4'>
                                                    <Col className="col-12">
                                                        <div className="card border-0 rounded-3 h-100">
                                                            <div className="card-body p-4 p-lg-5">
                                                                <h3 className='fw-600 text-bl text-capitalize'>Profile Settings </h3>

                                                                <form className="row g-4 form-main mt-4">
                                                                    <div className="col-md-12 col-lg-12 col-xl-6">
                                                                        <label className="form-label">First Name</label>
                                                                        <input
                                                                            className="form-control"
                                                                            placeholder="Enter your first name"
                                                                            type='text'
                                                                            name='first_name'
                                                                            value={userData?.first_name}
                                                                            onChange={handleInputChange}
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-12 col-lg-12 col-xl-6">
                                                                        <label className="form-label">Last Name</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter your last name"
                                                                            name='last_name'
                                                                            value={userData?.last_name}
                                                                            onChange={handleInputChange}
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-12 col-lg-12 col-xl-12">
                                                                        <label className="form-label">Email</label>
                                                                        <input
                                                                            type="email"
                                                                            className="form-control"
                                                                            placeholder="Enter your Email"
                                                                            value={userData.email}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-12 col-lg-12 col-xl-12">
                                                                        {permissions.canUpdateDepartment && (
                                                                            <>
                                                                                <label className="form-label">Department</label>
                                                                                <select
                                                                                    name="department"
                                                                                    className="form-select employees-selection"
                                                                                    aria-label="Default select example"
                                                                                    value={userData.department}
                                                                                    onChange={handleInputChange}
                                                                                    disabled={!permissions.canUpdateDepartment}
                                                                                >
                                                                                    <option value="">Select department</option>
                                                                                    {departmentsuserData && departmentsuserData.map((data, index) => (
                                                                                        <option key={index} value={data.department_id}>{data.name}</option>
                                                                                    ))}
                                                                                </select>
                                                                            </>
                                                                        )}
                                                                    </div>

                                                                    {user?.role_name !== "Individual User" &&
                                                                        <div className="col-md-12 col-lg-12 col-xl-12">
                                                                            <label className="form-label">Associated Code</label>
                                                                            <div className="input-group">
                                                                                <input
                                                                                    type="text"
                                                                                    name="associated_code"
                                                                                    value={userData.associated_code || ''} // Guard against undefined
                                                                                    className="form-control"
                                                                                    placeholder="Enter your associated code"
                                                                                    disabled={!permissions.canUpdateAssociatedCode}
                                                                                    onChange={handleInputChange}
                                                                                />
                                                                                <button type='button' className="btn btn-secondary" onClick={handleCopyCode} disabled={!permissions.canUpdateAssociatedCode}>
                                                                                    {isCopied ? 'Copied!' : 'Copy'}
                                                                                </button>
                                                                            </div>
                                                                            {isCopied && <small className="text-success">Code copied to clipboard!</small>}
                                                                        </div>
                                                                    }

                                                                    <div className="d-flex flex-column flex-md-row justify-content-end gap-3 pt-md-4">
                                                                        {/* <button
                                                                            type='button'
                                                                            className="btn-primary rounded-2 fw-400 bg-transparent text-bl border-0">
                                                                            Cancel
                                                                        </button> */}
                                                                        <button className='btn-primary rounded-2' type='button' onClick={saveProfileImageChanges}>Upload Photo</button>

                                                                        <button
                                                                            type='button'
                                                                            onClick={saveProfileChanges}
                                                                            className="btn-primary rounded-2 fw-400">
                                                                            Save Changes
                                                                        </button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </>
    )
}

export default EditProfile
