import React, { useRef, createContext, useState, useEffect } from 'react';
import { getServerURL } from '../helper/envConfig';
import api from '../helper/api';
import { toast } from 'react-toastify';
import { Is_Login } from '../helper/IsLogin';
import { decryptFromStore } from '../helper/sessionStore';
import { decryptFromStoreLoc } from '../helper/localStore';


// Create the cart context
export const CourseContext = createContext();


export const CourseProvider = ({ children }) => {
    const isLoggedIn = Is_Login();
    const [listCourse, setListCourse] = useState({ results: [], page_size: 0 });
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [searchex, setSearchex] = useState("");
    const [mainLoder, setMainLoder] = useState(false);
    const [loder, setLoder] = useState(false);
    const [is_search, setIs_search] = useState(false);
    const [user, setUser] = useState();
    const [token, setToken] = useState();
    const [isWishlist, setIsWishlist] = useState(false);
    const [ShowSuccess, setShowSuccess] = useState(false);
    const [message, setMessage] = useState("");
    const [email, setEmail] =  useState("")


    const [pageSize, setPageSize] = useState(10);
    const serverURL = getServerURL();
    const [QuestionNo, setQuestionNo] = useState(1); // Store the remaining time in seconds
    const [categories, setCategories] = useState([]);
    const [topPerformers, setTopPerformers] = useState([]);

    const [categoriesIndex, setCategoriesIndex] = useState([]);
    const [paymentStatus, setPaymentStatus] =  useState(false)
    const [pointsData, setPointsData] = useState({
        total_point_earned :0
    })


    const getCourses = async () => {

        const pathname = window.location.pathname; // Get the current path from the window object

        const slug = pathname.split('/').pop(); // Extract the slug from the URL

        try {
            setLoder(true);
            let url = `${serverURL}courses/all-courses/?page=${currentPage}&page_size=${pageSize}&ordering=name`;

            // Check if there is a search query
            const isSearching = search || searchex;

            // Append the category slug only if there is no search query and the path is for a specific category
            if (!isSearching && pathname.includes('/courses-category/')) {
                url += `&category__slug=${slug}`;
            }

            // Include search query if applicable
            if (isSearching) {
                url += `&search=${search || searchex}`;
            }

            const response = await api.get(url);
            setListCourse(response.data);
            setLoder(false);
        } catch (error) {
            setLoder(false);
            setListCourse([]);
            console.error(error);
        }
    };


    const getCoursesEmployee = async () => {

        try {
            setLoder(true);
            const response = await api.postWithToken(`${serverURL}users/employee/courses/?page=${currentPage}&page_size=${pageSize}&ordering=name`);
            setListCourse(response.data);
            setLoder(false);
        } catch (error) {
            setLoder(false);
            setListCourse([]);
            console.error(error);
        }
    };

    const getCategorys = async () => {
        try {

            setSearchex()
            setSearch()
            setLoder(true);
            const response = await api.get(`${serverURL}courses/course-categories-list/`);
            setCategories(response.data.data);

        } catch (error) {
            console.log(error);
        } finally {
            setLoder(false);
        }
    };

    const manageBookMark = (course_id) => {
        if (isLoggedIn) setIsWishlist(!isWishlist)
        manageBookMarkApi(course_id)
    };

    const manageBookMarkApi = async (course_id) => {
        try {
            // setLoder(true);
            const response = await api.postWithToken(`${serverURL}courses/course-bookmark/`, {
                "course_id": course_id
            });
            // if(response.data.success){
            //     toast.success(response.data.message, { type: toast.TYPE.INFO });        
            // }else if(!response.data.success){
            //     toast.error(response.data.message, { type: toast.TYPE.INFO });        
            // }
        } catch (error) {
            console.log(error);
        } 
    };

    const geTopPerformers = async () => {
        try {

            setSearchex()
            setSearch()
            setLoder(true);
            const response = await api.getWithToken(`${serverURL}gamifications/leaderboard/`);
            setTopPerformers(response.data.data);

        } catch (error) {
            console.log(error);
        } finally {
            setLoder(false);
        }
    };

    const getCategorysIndex = async () => {
        try {

            setLoder(true);
            const response = await api.get(`${serverURL}courses/course-index-sub-categories-list/`);
            setCategoriesIndex(response.data.data);

        } catch (error) {
            console.log(error);
        } finally {
            setLoder(false);
        }
    };

    const getTrailsTotalPoint = async () => {
        try {

            setLoder(true);
            const response = await api.getWithToken(`${serverURL}trails/total-points/`);
            if( response.data.data.total_point_earned !== 0) {
                setPointsData(response.data.data);
            }

        } catch (error) {
            console.log(error);
        } finally {
            setLoder(false);
        }
    };

    useEffect(() => {
        const decryptedInfoResult = decryptFromStoreLoc('user');
        setUser(decryptedInfoResult)
        const mytoken = localStorage.getItem('token');
        setToken(mytoken)
        const paymentData = decryptFromStoreLoc("paymentData")
        if(paymentData?.status){
            setPaymentStatus(paymentData.status)
        } else {
            setPaymentStatus(false)
        }
    }, []);

    return (
        <CourseContext.Provider value={{
            listCourse, currentPage, setCurrentPage, pageSize, setPageSize, getCourses, getCoursesEmployee, search, setSearch, searchex, setSearchex, is_search, setIs_search,
            mainLoder, setMainLoder, loder, setLoder,
            QuestionNo, setQuestionNo,
            user, setUser, token, setToken,
            getCategorys, categories, setCategories,
            manageBookMark, setIsWishlist, isWishlist,
            manageBookMarkApi,
            geTopPerformers, topPerformers,
            getCategorysIndex, categoriesIndex, setCategoriesIndex,
            ShowSuccess, setShowSuccess,
            message, setMessage,
            email, setEmail,
            paymentStatus,
            getTrailsTotalPoint,
            pointsData
        }} >
            {children}
        </CourseContext.Provider>
    )

};